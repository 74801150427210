import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { environment } from '../../../env';
import { basicDetailsAction, educationalDetailsAction, experiencelDetailsAction, getAllEmployees } from '../../../Redux/actions';
import Address from './Address';
import BankDetails from './BankDetails';
import BasicDetails from './BasicDetails';
import Education from './Education';
import Experience from './Experience';
import FamilyDetails from './FamilyDetails';
import './basicdetails.css'
import { ReceiptEdit, SearchNormal } from 'iconsax-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./BasicDetailsAll.css"
import { Button } from 'reactstrap';
import jsPDF from 'jspdf';

interface BasicDetailsData {
  employee_id: any
}

function DetailsNavigation(props: any) {
  const [Details, setDetails] = useState(false)
  const dispatch = useDispatch();
  const [activatedTab, setActivatedTab] = useState("1");
  const allUsers = useSelector((state: any) => state?.root?.reducers?.allEmployees);
  const [basicdetails, setBasicDetails] = useState<BasicDetailsData>({
    employee_id: ''
  })
  const [experiencedetails, setExperienceDetails] = useState<BasicDetailsData>({
    employee_id: ''
  })
  const [educationdetails, setEducationDetails] = useState<BasicDetailsData>({
    employee_id: ''
  })

  const getAllUsers = async () => {
    try {
      const res = await axios.get(`${environment.baseUrl}all-users-details/`);
      if (res.data) {
        console.log("sdfsadfsadfsadf", res.data.length)
        dispatch(
          getAllEmployees(
            res.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
          )
        );
      }
    } catch (err) {
      console.log("err");
    }
  };

  const getBankDetails = async () => {
    try {
      const response = await axios.get(`${environment.baseUrl}get-bank-details`)
      console.log('response.data', response.data);
      return response.data
    } catch (error) {
      console.log('error', error)
    }
  };

  const generatePDF = async () => {
    const data = await getBankDetails();

    if (data) {
      const pdf = new jsPDF();
      const maxCharactersPerPage = 1000;

      let content = "";

      const processObject = (obj: any, indent = "") => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (typeof value === "object" && value !== null) {
              content += `${indent}${key}:\n`;
              processObject(value, `${indent}  `);
            } else {
              content += `${indent}${key}: ${value}\n`;
            }

            if (content.length >= maxCharactersPerPage) {
              pdf.text(content, 10, 10);
              pdf.addPage();
              content = "";
            }
          }
        }
      };

      processObject(data);

      pdf.text(content, 10, 10);

      pdf.save("BankDetails.pdf");
    }
  }

  const activateTab = (tabId: any) => {
    setActivatedTab(tabId)
  }
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [number, setNumber] = useState(0)
  const onSubmit = (data: any) => {
    console.log(data, "asdasdasdasd")
    axios(`${environment.baseUrl}get-all-employee-basic-details?employee_id=${data.employee_id}&firstname=${data.firstname}`).then((response) => {
      if (response?.data?.length > 0) {
        console.log('response?.data?.[0]', response?.data?.[0])
        setDetails(true)
        setBasicDetails(response?.data?.[0]);
        dispatch(basicDetailsAction(response?.data?.[0]));
        console.log('response?.data?.[0]', response?.data?.[0])
        setNumber(number + 1)
      } else {
        alert("data is not available for search")
      }
    });
    const secondUrl = `${environment.baseUrl}get-experience-by-id-filter?employee_id=${data.employee_id}`;

    const result1 = axios(secondUrl).then((secondUrlResponse) => {
      if (secondUrlResponse?.data) {
        setExperienceDetails(secondUrlResponse?.data);
        dispatch(experiencelDetailsAction(secondUrlResponse?.data));
      } else {
        alert("data is not available for search2")
      }
    });

    // let secondUrl

    const thirdUrl = `${environment.baseUrl}get-user-documents-filter?employee_id=${data.employee_id}&educational=true`;

    const result2 = axios(thirdUrl).then((thirdUrlResponse) => {
      if (thirdUrlResponse?.data) {
        setEducationDetails(thirdUrlResponse?.data.results);
        dispatch(educationalDetailsAction(thirdUrlResponse?.data));
        setNumber(number + 1)
      } else {
        alert("data is not available for search2")
      }
    });
  }

  useEffect(() => {
    console.log("allUsers[0]", allUsers[0])
    setBasicDetails(allUsers[0]),
      setDetails(true);
  }, [])

  return (
    <>
      <div className="dashboard_main px-4 mt-3">
        <nav>
          <div className=" d-flex align-items-center">
            <div className="col-12 d-flex align-items-center p-0">
              <div className='d-flex justify-content-around'>
                <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
                  <span className='base-text-color base-font-700 fs-4'><ReceiptEdit size="35" variant="Bulk" /></span>
                  <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                    Employee Details</h4>
                </div>
                <div className="mr-5">
                  <div className="d-flex justify-items-evenly gap-5">
                    <Button className="tb-btn w- base-font-muli fw-bold d-flex align-items-center " onClick={generatePDF} >
                      <span>Download Bank Details</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="px-3 py-2 col-6  bg-white rounded-3 shadow">
                <div className='d-flex align-items-center bg-transparent border-base-bottom-2'>
                  <SearchNormal size="25" className='text-base-color' />
                  <select className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                    style={{ fontSize: "1rem" }}
                    {...register("employee_id", { onChange: (e) => onSubmit({ "employee_id": e.target.value, "firstname": '' }) })}
                  >
                    <option value="" className='attoptions'>Employee Name...</option>
                    {allUsers.map((user: any, i: any) => {
                      return <option className='attoptions' value={user.employee_id} key={i}>{user.employee_id} - {user.firstname} {user.lastname}  </option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className=" pt-5 px-3">
        <Tabs className="">
          <div className="d-flex justify-content-center mb-4">
            <TabList className="d-flex p-0 sellGalaTabsMain gap-4  py-2 rounded-2">
              <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs">Basic Details</Tab>
              <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs ">Address</Tab>
              <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs ">Education</Tab>
              <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs ">Experience</Tab>
              <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs ">Bank Details</Tab>
              <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs ">Family Details</Tab>
            </TabList>
          </div>
          <TabPanel>
            <div className="bg-white  rounded-5 shadow">
              <div className=' pb-4'>
                {Details &&
                  <BasicDetails basicdetails={basicdetails} number={number} set={setNumber} emp={onSubmit} />
                }
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bg-white py-3 px-2  rounded-5 shadow">
              <div className=' pb-4'>
                {Details &&
                  <Address basicdetails={basicdetails} />
                }
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bg-white py-3 px-2  rounded-5 shadow">
              <div className=' pb-4'>
                {Details &&
                  <Education educationdetails={educationdetails} />
                }
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bg-white py-3 px-2  rounded-5 shadow">
              <div className=' pb-4'>
                {Details &&
                  <Experience experiencedetails={experiencedetails} />
                }
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bg-white py-3 px-2  rounded-5 shadow">
              <div className=' pb-4'>
                {Details &&
                  <BankDetails basicdetails={basicdetails} number={number} />
                }
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="bg-white py-3 px-2  rounded-5 shadow">
              <div className=' pb-4'>
                {Details &&
                  <FamilyDetails basicdetails={basicdetails} number={number} />
                }
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}

export default DetailsNavigation;
