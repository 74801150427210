import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { environment } from '../env';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getHolidaysData } from '../Redux/actions';

const HolidaysInfo = ({ modal, setModal, selectedinfo }: any) => {
    console.log('selectedinfo', selectedinfo);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch()
    const toggle = () => { setModal(!modal) };

    const Submit = async (data: any) => {
        const URL = `${environment.baseUrl}holiday/`
        const dataArray = {
            "date": selectedinfo?.dateStr,
            "name": data?.name,
            "day": selectedinfo?.dateStr,
        }

        const Holidays = () => {
            axios.get(`${environment.baseUrl}holiday/`)
                .then(res => {
                    dispatch(getHolidaysData(res.data));
                })
                .catch(res => console.log("error"));
        };

        try {
            const result = await axios.post(URL, dataArray).then(response => {
                if (response.status === 200) {
                    setModal(!modal);
                    Holidays()
                    reset()
                }
            })
        } catch (error) {
            console.log(error);

        }
    }
    const cancelBox = () => {
        setModal(!modal);
        reset()
    }
    useEffect(() => {
        reset({ "name": "" })
    }, [])


    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggle}
                size="md" centered
                className="mispunch-modal"
            >
                <ModalHeader
                    className="p-4"
                >
                    <span className='base-font-muli fs-4 text-base-color fw-bold '>Add Event</span></ModalHeader>
                <form onSubmit={handleSubmit(Submit)}>

                    <ModalBody
                        className='px-4'
                    >
                        <div className="InputFeild">
                            <label htmlFor="employee_id" className="text-black base-font-muli fw-bold fs-4 mb-0">
                                Select Day :- {moment(selectedinfo?.dateStr).format('DD/MM/YYYY')}
                            </label>
                            <textarea
                                placeholder='Enter Event Text...'
                                {...register("name")}
                                className=" input-border w-100 mt-4 text-black  base-font-600 bg-background-color   shadow-none  fs-5"
                            ></textarea>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0'>
                        <button type='submit' className="submit-btn">
                            Submit
                        </button>
                        <button onClick={cancelBox} type='button' className="close-btn">
                            Close
                        </button>
                    </ModalFooter>
                </form>

            </Modal>
        </>);
}

export default HolidaysInfo;