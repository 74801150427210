import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select'
import { environment } from '../../env';
import swal from "sweetalert";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getLeaves } from '../../Redux/actions';
import { useDispatch } from 'react-redux';
import { Edit2 } from 'iconsax-react';

const Lstatus = (props: any) => {
    var option = [
        { value: "Approved", label: "Approve" },
        { value: "Denied", label: "Deny" },
        { value: "Cancelled", label: "Cancel" }
    ]

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const [status, setStatus] = useState(option);
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal) };

    const dispatch = useDispatch();

    const handle = (e: any) => {
        setStatus(e.value)
    }

    useEffect(() => {
    }, [status])

    const submit = (e: any) => {
        e.preventDefault();
        const empleavedata = {
            "id": props.post.id,
            "employee_id": props.post.employee_id,
            "leave_type": props.post.leave_type,
            "leave_durations": props.post.leave_durations,
            "start_date": props.post.start_date,
            "end_date": props.post.end_date,
            "leave_status": status
        }
        axios.post(`${environment.baseUrl}update-leave-status`, empleavedata).then((EmpResponse) => {
            if (EmpResponse.data.status === 'success') {
                toggle()
                AllLeaves()
                swal({
                    title: "Success",
                    text: 'Leave Status Updated',
                    icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                })
                    .then(() => { props.update(true) })
            }
        })
            .catch(res => console.log("errorSubmit===================", status))
    }

    const CheckEmployee = (e: any) => {
        e.preventDefault();
        const empleavedata = {
            id: props.post.id,
            leave_type: `${status} ${props.post.leave_type}`,
            date: props.post.start_date.split("T")[0],
            leave_reason: props.post.leave_reason
        }
        axios.post(`${environment.baseUrl}update-leave-attendance/${props.post.employee_id}`,)
            .then((EmpResponse) => {
                if (EmpResponse.data.status === 'success') {
                    swal({
                        title: "Success",
                        text: 'Leave Status Updated',
                        icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                    }).then(() => { props.update(true) })

                }
            })
            .catch(res => console.log("errorCHeckUPdate===================", status))
    }

    const AllLeaves = async () => {
        axios.get(`${environment.baseUrl}get-all-leaves`)
            .then(res => {
                dispatch(getLeaves(res.data))
            })
            .catch(res => console.log("error"))
    }

    const two = (e: any) => {
        submit(e);
    }

    return (
        <>
            <Form encType="multipart/form-data">
                <div className='edit-attendance'>
                    <Link className='text-dark text-decoration-none' to='' onClick={() => { toggle() }}> <Edit2 size="32" className='text-base-color' /></Link>
                </div>
            </Form>
            <Modal isOpen={modal} toggle={toggle} centered
                size='md'
                className='mispunch-modal'>
                <ModalHeader className="py-3 px-4"><span className='base-font-muli fs-3 text-base-color fw-bold '>Edit Leaves</span></ModalHeader>
                <form onSubmit={(e) => two(e)}>
                    <ModalBody className='py-5 px-4'>
                        <Select onChange={handle} options={option} className='fs-5 text-black fw-bold' />
                    </ModalBody>
                    <ModalFooter className='border-0 justify-content-center'>
                        <button className="submit-btn" type='submit'>
                            Submit
                        </button>
                        <button type={'button'} onClick={() => { toggle() }} className="close-btn">
                            Cancel
                        </button>
                    </ModalFooter>
                </form>
            </Modal >
        </>
    )
}

export default Lstatus