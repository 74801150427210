import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import { useSelector } from "react-redux"
import Table from '../../../Table/Table';
import EmptyTable from '../../errorComponents/EmptyTable';

interface ExperienceData {
    employee_id: any,
    company_name: any,
    position: any,
    joining_date: any,
    leaving_date: any,
    file: any
}
interface DataRow {
    EmpId: any;
    Document: string;
    UploadDate: string;
    WordCount: string;
    Points: any;
    galaNo: any;
    contactNo: any;
    area: any;
    tenant: any;
}
const Experience = (props: any) => {
    const experiencedetails = useSelector((state: any) => state?.root?.reducers?.experienceDetails);

    const EmpId = localStorage.getItem('empid')

    useEffect(() => {
        props.experiencedetails;
    }, []);
    const ExamplePDFViewer = (file: any) => {


        window.open(`${file}`);
    };
    let id = 0
    const columns: TableColumn<ExperienceData>[] = [
        {
            name: 'ID',
            selector: row => id + 1,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Company Name',
            selector: row => row.company_name,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Position',
            selector: row => row.position,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Joining Date',
            selector: row => row.joining_date,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Leaving Date',
            selector: row => row.leaving_date,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Document',
            selector: row => <Link onClick={() => ExamplePDFViewer(row.file)} to=''>View</Link>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
    ];

    return (
        < >
            <div className="main-wrapper-section">
                <div className=' py-3 px-5 d-flex flex-column '>
                    <div className='d-flex align-items-center justify-content-between pb-4'>
                        <h4 className='fs-2 mb-0 text-base-color fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                            Employee Experience</h4>
                    </div>
                    {experiencedetails ? <Table columns={columns} data={experiencedetails} /> : <EmptyTable tabletext="Employees Experience" />}
                </div>
            </div>
        </>
    )
}

export default Experience