import axios from 'axios';
import moment from 'moment';
import userImg from '../image/user-demo.jpeg'
import React, { useEffect, useState } from 'react'
import { environment } from '../env';
import { useForm } from 'react-hook-form';
import { Refresh, SearchNormal } from 'iconsax-react';
import { Speedometer, Hospital, Calendar, Cup, SmsEdit, Timer1, Briefcase } from 'iconsax-react';
import EmptyDoc from './errorComponents/EmptyDoc';
import { useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import EmpDetailsModal from './AddEmployee/EmpDetailsModal';
const greetingTime = require("greeting-time");


function DashBoard() {
    const allUsers = useSelector((state: any) => state?.root?.reducers?.allEmployees);
    const [rotate, setRotate] = useState(false)
    const [posts, setBasicDetails] = useState({
        employee_id: allUsers[0].employee_id,
        firstname: allUsers[0].firstname,
        lastname: allUsers[0].lastname,
        rewards: allUsers[0].rewards,
        previlage_leave: allUsers[0].previlage_leave,
        sick_leave: allUsers[0].sick_leave,
        comp_off: allUsers[0].comp_off,
        email_id: allUsers[0].email_id,
        personal_email: allUsers[0].personal_email,
        late_marks: allUsers[0].late_marks,
        profile_pic: allUsers[0].profile_pic,
        over_time: allUsers[0].over_time,
        early_leave: allUsers[0].early_leave,
        designation: allUsers[0].designation
    })

    const LoggedUser = useSelector((state: any) => state?.root?.reducers?.loggeduser);

    const CronDuty = async () => {
        setRotate(true)
        await axios.get(`${environment.baseUrl}cron/`)
            .then(res => {
                if (res.status == 200) {
                    setRotate(false)
                    swal({
                        title: "Are you sure?",
                        icon: "warning",
                        dangerMode: true,
                        buttons: ["Cancel", "Yes"],
                    })
                        .then((willDelete: any) => {
                            if (willDelete) {
                                swal("Cron ran successfully", {
                                    icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                                });
                            }
                        });
                }

            })
    }
    useEffect(() => {
        setTimeout(() => {
            setRotate(false)
        }, 6000);
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const submit = (data: any) => {
        const SearchUrl = `${environment.baseUrl}get-all-employee-basic-details?employee_id=${data.employee_id}&firstname=${data.firstname}`
        const result = axios(SearchUrl).then((response) => {
            if (response?.data?.length > 1) {
                setBasicDetails({
                    employee_id: '',
                    firstname: '',
                    lastname: '',
                    rewards: 0,
                    previlage_leave: 0,
                    sick_leave: 0,
                    comp_off: 0,
                    email_id: '',
                    personal_email: "",
                    late_marks: 0,
                    profile_pic: "",
                    over_time: 0,
                    early_leave: 0,
                    designation: ""
                })
            } else {
                setBasicDetails(response?.data?.[0]);
            }
        })
    }

    const onSubmit = (data: any) => {
        debounce(data);
    };

    const debounce = useCallback(
        _.debounce((_searchVal) => {
            submit(_searchVal);
        }, 900),
        []
    );

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
      };

    return (
        <>
            <div className="dashboard_main px-4">
                <nav>
                    <div className=" row align-items-center">
                        <form  >
                            <div className="col-12 d-flex align-items-center p-0 gap-2">
                                <div className="dashboard-heading col-6 px-0">
                                    <span className='d-flex'><h1 className='fs-1 mb-0 text-black fw-bold base-font-inter text-capitalize'>{greetingTime(new Date())},<span className='base-text-color base-font-Dancing fw-normal  text-capitalize '>{LoggedUser[0]?.firstname}.</span></h1>
                                    </span>
                                    <h4 className='fs-4 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-1'>
                                        <span className='base-text-color base-font-700 fs-4'>{moment().format("dddd")},</span>{moment().format(" DD MMMM YYYY")}</h4>

                                </div>

                                <div className="px-3 py-2 col-5 bg-white rounded-3 shadow">
                                    <div className='d-flex align-items-center bg-transparent border-base-bottom-2'>
                                        <SearchNormal size="25" className='text-base-color' />
                                        <select className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                                            style={{ fontSize: "1rem" }}
                                            {...register("employee_id", { onChange: (e) => { onSubmit({ "employee_id": e.target.value, "firstname": '' }) } })}

                                        >
                                            <option value=" " className='attoptions'>Emp Name...</option>
                                            {allUsers.reverse().map((user: any, i: any) => {
                                                return <option className='attoptions' value={user.employee_id} key={i}>{user.firstname} {user.lastname}  </option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className=" py-2 col-1 bg-white rounded-3 shadow d-flex justify-content-center align-items-center" title='crown'>
                                    <div className={rotate ? "rotateBox p-1" : "p-1"} onClick={() => {  }} style={{ cursor: "pointer" }}>
                                        <Refresh size="30" onClick={getData} className='text-base-color' />
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </nav>
                <div className="pt-3">
                    {posts &&
                        posts?.employee_id !== "" ?
                        <main className="dashboard-main-box">
                            <div className="row m-0 border my-3   rounded-4 py-3 align-items-center">
                                <div className="col-6">
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <div className="user-images ">
                                            <div className="userBoxs">
                                                <img src={`${posts?.profile_pic ? posts?.profile_pic : 'https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/default.jpg'}`} alt="user-img" className='user-image-fits' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 flex-column d-flex gap-1 pt-4 pb-0 px-5">
                                        <label className='base-font-400 base-font-pop  text-nowrap text-capitalize fs-5 mb-0'>{posts.employee_id}</label>
                                        <h2 className='base-font-Dancing fw-bold text-nowrap text-capitalize fs-1 text-base-color'>{posts.firstname} {posts.lastname}</h2>
                                        <label className='base-font-500 base-font-pop  text-nowrap text-capitalize fs-5'>{posts.email_id}</label>
                                        <label className='base-font-500 base-font-pop  text-nowrap text-capitalize fs-5 mb-0'>fullstack Developer</label>
                                        <div className="buttons-tab mt-2">
                                            <EmpDetailsModal {...posts} buttons={'DashBoard'} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center  py-3 col-6 p-0">
                                    <div className="col-6 mb-3">
                                        <div className="dashboard-card py-3 shadow  px-2 border d-flex justify-content-center flex-column align-items-center gap-3 rounded-5 bg-white  border-left-0">
                                            <div className="iconBox  box-gray-bg-1">
                                                <SmsEdit size="35" className='box-gray' />
                                            </div>
                                            <div className="cardBody">
                                                <h4 className='fs-4  base-font-pop fw-normal'>Late Marks</h4>
                                                <span className='fs-2 text-base-black base-font-inter fw-bold'>{posts.late_marks}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-3 ">
                                        <div className="dashboard-card py-3 shadow  px-2  border d-flex justify-content-center flex-column align-items-center gap-3 rounded-5 bg-white border-right-0">
                                            <div className="iconBox  box-pink-bg-1">
                                                <Briefcase size="35" className='box-green' />
                                            </div>
                                            <div className="cardBody">
                                                <h4 className='fs-4  base-font-pop fw-normal'>Early Leave</h4>
                                                <span className='fs-2 text-base-black base-font-inter fw-bold'>{posts.early_leave}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 ">
                                        <div className="dashboard-card py-3 shadow  px-2  border d-flex justify-content-center flex-column align-items-center gap-3 rounded-5 
                                  bg-white border-bottom-left-0">
                                            <div className="iconBox  box-blue-bg-1">
                                                <Timer1 size="35" className='box-blue' />
                                            </div>
                                            <div className="cardBody">
                                                <h4 className='fs-4  base-font-pop fw-normal'>Over Time</h4>
                                                <span className='fs-2 text-base-black base-font-inter fw-bold'>{(posts.over_time / 60).toFixed(2)}  <span className='fs-5 pt-2'>Hrs</span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 ">
                                        <div className="dashboard-card py-3 shadow  px-2  border d-flex justify-content-center flex-column align-items-center gap-3 rounded-5 bg-white border-bottom-right-0">
                                            <div className="iconBox  box-green-bg-1">
                                                <Cup size="35" className='box-pink' />
                                            </div>
                                            <div className="cardBody">
                                                <h4 className='fs-4  base-font-pop fw-normal'>Rewards</h4>
                                                <span className='fs-2 text-base-black base-font-inter fw-bold'>{posts.rewards}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row px-3'>
                                <div className="light-blue-bg py-5 px-3 px-md-4  rounded-4 mb-4 mt-3">
                                    <div className="row gy-4 ">
                                        <div className="col-12 col-sm-12 col-md-3 ">
                                            <div className="card border-0 bg-white shadow-lg py-4 px-3 rounded-5">
                                                <div className="dashboardBox mb-3 bg-info">
                                                    <Calendar size="28" color="#ffffff" />
                                                </div>
                                                <div className="">
                                                    <h2 className='  fw-semibold   base-font-inter light-icon fs-2'>{posts.previlage_leave} / 12</h2>
                                                    <h4 className="fs-5 mb-0 fw-bold text-muted d-flex">Privilage <span className="d-block d-md-none d-lg-block d-xl-block ms-1">Leaves</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-3 ">
                                            <div className="card border-0 bg-white shadow-lg py-4 px-3 rounded-5">
                                                <div className="dashboardBox bg-warning mb-3">
                                                    <Speedometer size="30" color="#ffffff" />
                                                </div>
                                                <div className="">
                                                    <h2 className='  fw-semibold   base-font-inter light-icon fs-2'>{posts.comp_off}</h2>
                                                    <h4 className="fs-5 mb-0 fw-bold text-muted">Comp-Off</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-3 ">
                                            <div className="card border-0 bg-white shadow-lg py-4 px-3 rounded-5">
                                                <div className="dashboardBox bg-danger mb-3">
                                                    <Hospital size="28" color="#ffffff" />
                                                </div>
                                                <div className="">
                                                    <h2 className='  fw-semibold   base-font-inter light-icon fs-2'> {posts.sick_leave} / 5</h2>
                                                    <h4 className="fs-5 mb-0 fw-bold text-muted">Sick Leaves</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-3 ">
                                            <div className="card border-0 bg-white shadow-lg py-4 px-3 rounded-5">
                                                <div className="dashboardBox mb-3 bg-primary">
                                                    <Calendar size="28" color="#ffffff" />
                                                </div>
                                                <div className="">
                                                    <h2 className='  fw-semibold   base-font-inter light-icon fs-2'>12/12</h2>
                                                    <h4 className="fs-5 mb-0 fw-bold text-muted d-flex">Week<span className="d-block d-md-none d-lg-block d-xl-block ms-1">-Off</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        :
                        <EmptyDoc texts="Please Enter employee Name/Id" />
                    }

                </div>
            </div>
        </>
    )
}

export default DashBoard

