import { AttachCircle, Eye, FlashCircle, InfoCircle, Link, TickCircle } from "iconsax-react";
import userImages from "../../../image/user-demo.jpeg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import DocumentViewModal from "../../DocumentView/DocumentViewModal";
import { S3_BUCKET, environment, link, myBucket } from "../../../env";
import axios from "axios";
import swal from "sweetalert"
import { useForm } from "react-hook-form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useSelector } from "react-redux"
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TableColumn } from "react-data-table-component";
import Table from "../../../Table/Table";
import AddTaskModal from "../../Models/AddTaskModal";


interface DataRow {
  status: string;
  due_date: any;
  priority: any;
  assigned_by: any;
  type: any;
  assigned_to: any;
  task_name: string;
  id: any;
}


const TaskDetails = () => {
  const user = useSelector((state: any) => state?.root?.reducers?.loggeduser);
  const teamMembers = useSelector((state: any) => state?.root?.reducers?.teamMembers);
  const [toggle, setToggle] = useState(false)
  const [editBox, setEditBox] = useState(false)
  const [commentBox, setCommentBox] = useState(false)
  const [editcomment, setEditComment] = useState(false)
  const [id, setID] = useState(0)
  const [taskss, setTasks] = useState([])
  const [commentss, setComments] = useState([])

  let location = useLocation();
  const tl = location.state.tl
  const [data, setData] = useState(location.state.from)
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    watch: watch1,
    setValue: setValue1,
    formState: { errors: errors1 },
  } = useForm();

  const getTasksById = () => {
    axios.get(`${environment.baseUrl}edit-task/${data.id}`)
      .then(res => {
        setData(res.data)
      })
      .catch(res => console.log("error"))
  }

  const getComments = () => {
    axios.get(`${environment.baseUrl}get-task-comment/${data.id}`)
      .then(res => {
        if (res.data != 5000) {
          setComments(res.data)
        } else {
          setComments([])
        }
      })
      .catch(res => console.log("error"))
  }

  const Image = async (data: any) => {
    var date = Math.round(+new Date() / 1000);
    const params = {
      ACL: "public-read",
      Body: data,
      Bucket: S3_BUCKET,
      Key: `taskimages_${date}_${data.name}`,
    };
    myBucket.upload(params, function (err: any, uploadData: any) {
      if (uploadData) {
        data = uploadData.Location;
        return (data);
      } else {
        console.log("error", err);
      }
    });
  }

  const onSubmit = async (value: any) => {
    let files: string[] = []
    if (JSON.parse(data.images).length != 0) {
      JSON.parse(data.images).map((data: any) => files.push(data))
    }
    if (value?.attachments != undefined) {
      var date = Math.round(+new Date() / 1000);
      Array.from(value?.attachments).forEach(async (file: any) => {
        files.push(`${link}taskimages_${date}_${file.name}`)
        await Image(file)
      })
    }
    value.images = JSON.stringify(files)
    try {
      await axios
        .put(`${environment.baseUrl}edit-task/${data.id}`, value)
        .then(async (form) => {
          if (form?.data?.code === 2000) {
            swal({
              title: "Success",
              text: "Task Added Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            setToggle(false)
            setEditBox(false)
            setTimeout(() => { getTasksById() }, 1000)
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteComment = (data: any) => {
    swal({
      title: "Are You Sure?",
      text: "Your Comment will be Deleted",
      icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
      buttons: ["Cancle", "Yes"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios
            .delete(`${environment.baseUrl}edit-task-comment/${data.id}`)
            .then(async (form) => {
              if (form?.data?.code === 2000) {
                swal({
                  title: "Success",
                  text: "Comment Deleted Successfully",
                  icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                });
                getComments();

              }
            });
        } catch (err) {
          console.log(err);
        }
      }
    });
  }

  const FilterTask = () => {
    axios.get(`${environment.baseUrl}project-tasks/${data?.team?.project?.id}`)
      .then(res => {
        if (res.data.code == 5000) {
          setTasks([])
        } else {
          setTasks(res.data.filter((dataa: any) => dataa.parent != 0 && dataa.parent == data.id));
        }
      })
      .catch(res => console.log("error"))
  }

  const editStatus = async (data: any, ID: any) => {
    let a = { "status": data }
    try {
      await axios
        .put(`${environment.baseUrl}edit-task/${ID}`, a)
        .then(async (form) => {
          if (form?.data?.code === 2000) {
            swal({
              title: "Success",
              text: "Status Updated Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            FilterTask();
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  const editComment = async (data: any) => {
    try {
      await axios
        .put(`${environment.baseUrl}edit-task-comment/${id}`, data)
        .then(async (form) => {
          if (form?.data?.code === 2000) {
            swal({
              title: "Success",
              text: "Comment Updated Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            setEditComment(!editcomment);
            setID(0);
            getComments();
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  const Comment = async (comm: any) => {
    let member = teamMembers?.filter((member: any) => member?.team?.project?.id == data?.team?.project?.id && member?.team_member_name.firstname == user[0]?.firstname)
    comm.member = member[0]?.id
    comm.task = data.id
    try {
      await axios
        .post(`${environment.baseUrl}task-comment`, comm)
        .then(async (form) => {
          if (form?.data?.code === 2000) {
            swal({
              title: "Success",
              text: "Comment Added Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            getComments()
            setCommentBox(!commentBox)
            reset1()
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  const columns: TableColumn<DataRow>[] = [
    {
      name: "ID",
      selector: (row) => `#${row?.assigned_to?.team?.project?.project_key}-${row?.id}`,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Task Type",
      selector: (row) => <h5 className="base-font-inter mb-0 py-2 px-4 rounded-4  fw-bold fs-6 text-capitalize" title={row?.type}
      >{row?.type == "Task" ? <TickCircle variant="Bulk" className="text-info" size="32" /> : row?.type == "Bug" ? <InfoCircle variant="Bulk" className="text-danger" size="32" /> : row?.type == "Epic" && <FlashCircle variant="Bulk" className="status-color-purple" size="32" />}</h5>,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Task Name",
      selector: (row) => row?.task_name,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Assigned To",
      selector: (row) => row?.assigned_to?.team_member_name?.firstname,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Assigned By",
      selector: (row) => row?.assigned_by?.firstname,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Priority",
      selector: row =>
        <h5 className={`${row?.priority == "Low" ? "status-color-yellow status-bg-yellow " : row?.priority == "Normal" ? " status-color-green status-bg-green" : row?.priority == "High" && "status-bg-red status-color-red"} base-font-inter mb-0 py-2 px-4 rounded-4  fw-bold fs-6 text-capitalize`}
        >{row?.priority}</h5>
      ,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Due Date",
      selector: (row) => moment(row?.due_date.split('T')[0]).format('DD/MM/YYYY'),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Status",
      selector: row =>
        <select defaultValue={row?.status}
          className={`${row?.status == "inprogress" ? "status-color-blue status-bg-blue " : row?.status == "completed" ? " status-color-green status-bg-green" : row?.status == "pending" ? " status-color-yellow status-bg-yellow" : row?.status == "denied" && "status-bg-red status-color-red"} form-select border-0 rounded-2 text-capitalize base-font-500 custom-option`} aria-label="Default select example" onChange={(e: any) => { editStatus(e.target.value, row.id) }}>
          <option value="pending">Pending</option>
          <option value="inprogress">In Progress</option>
          <option value="completed">Completed</option>
          <option value="denied">Denied</option>
        </select>,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "View",
      selector: (row) => <NavLink to="/SubTaskDetails" state={{ from: row, datas: data }} className="">
        <div className="icon-bg-green border-0 px-2 py-2 rounded">
          <Eye size="28" className='icon-color-green ' />
        </div>
      </NavLink>,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  useMemo(() => {
  }, [data])
  const handleToggle = () => {
    addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.code == "KeyM") {
        e.preventDefault();
        setCommentBox(!commentBox)
      }
    }
    );
    addEventListener("dblclick", (e) => {
      e.preventDefault();
      setCommentBox(!commentBox)
    }
    );
  }
  useEffect(() => {
    handleToggle()
  }, [])
  const navigator = useNavigate();

  useEffect(() => { FilterTask(); getComments() }, [])

  return (
    <div className="main-wrapper-section px-4 p-5">
      <div className="d-flex align-items-center justify-content-between">
        <div className=" pb-4 gap-2  px-0 d-flex align-items-center">
          <NavLink
            to="/projects"
            className="base-font-pop text-base-color fs-4 mb-0 pb-0 hover-underline "
            style={{ fontWeight: "500" }}
          >
            Project /
          </NavLink>
          <a
            className="base-font-pop text-base-color fs-4 mb-0 pb-0 text-capitalize hover-underline"
            style={{ cursor: "pointer", fontWeight: "500" }}
            onClick={() => navigator(-1)}
          >
            {data?.assigned_to?.team?.project?.project_name}
          </a>
          <h4
            className="base-font-pop fs-4 mb-0 text-secondary"
            style={{ fontWeight: "500" }}
          >
            / #{data?.assigned_to?.team?.project?.project_key}-{data?.id}
          </h4>
        </div>
        <div className="d-flex gap-2 align-items-center pb-4 ps-5 ms-5">
          <div className="d-flex gap-2 align-items-center ">
            <label className="text-base-pop fw-semibold mb-0 text-black fs-5">Assigned Date :-</label>
            <h5 className="text-base-pop fw-semibold mb-0 pe-3 pt-1 text-base-color">
              {moment(data?.assigned_to?.created_at).format('DD/MM/YYYY')}
            </h5>
          </div>
          <div className="d-flex gap-2 align-items-center ">
            <label className="text-base-pop fw-semibold mb-0 text-black fs-5">Due Date :-</label>
            <h5 className="text-base-pop fw-semibold mb-0 pe-3 pt-1 text-base-color">
              {moment(data?.assigned_to?.due_date).format('DD/MM/YYYY')}
            </h5>
          </div>
        </div>
      </div>

      <div className="row m-0">
        <div
          className="col-9  d-flex flex-column  ps-0 overflow-scroll"
          style={{ height: "50rem" }}
        >
          <h2
            className="text-black fs-2 mb-4 base-font-pop text-wrap"
            style={{ fontWeight: "600" }}
          >
            {data?.task_name}
          </h2>
          <div className="d-flex gap-3">
            <button className="d-flex gap-2 align-items-center btn btn-light rounded-pill px-3 py-2 border-0 " onClick={() => setToggle(!toggle)}>
              <AttachCircle />
              <h5 className="fs-5 base-font-600 mb-0 text-black">Attach</h5>
            </button>
            <button className="d-flex gap-2 align-items-center btn btn-light rounded-pill px-3 py-2 border-0" onClick={() => setToggle(!toggle)}>
              <Link />
              <h5 className="fs-5 base-font-600 mb-0 text-black">Link Urls</h5>
            </button>
            {data.parent == 0 && <AddTaskModal projectid={data?.team?.project?.id} subtask={true} taskid={data?.id} team={data} />}
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="gap-2 w-100">
            {
              toggle && <div className="d-flex mt-3 py-2 rounded-2" style={{ fontWeight: "400", backgroundColor: "#f8f9fc" }}>
                <input
                  type="file"
                  multiple
                  {...register("attachments")}
                  className="d-flex gap-2 align-items-center btn btn-light rounded-pill px-3 py-1 border-0 " />
                <div className="w-50">
                  <input
                    type="url"
                    {...register("url_attachments")}
                    className="border-base-color-1 py-1 px-2 rounded-2 fs-5 base-font-pop fw-normal ms-2 w-100"
                    placeholder="https://www.example.com"
                  />
                </div>
                <button type="submit" className="btn bg-base-color text-white ms-3 fs-5">Submit</button>

              </div>
            }

            <div className="w-100  mb-3 mt-3">
              <label
                htmlFor="dis"
                className="fs-5 base-font-inter text-base-color"
                style={{ fontWeight: "600" }}
              >
                Description
              </label>
              {
                editBox ? <div className="pe-3 w-100"><CKEditor
                  editor={ClassicEditor}
                  data={data?.description}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue("description", data)
                  }}
                  onBlur={(event, editor) => {
                  }}
                  onFocus={(event, editor) => {
                  }} />
                  <div className="w-100 d-flex justify-content-end pt-2 gap-3">
                    <button onClick={() => setEditBox(!editBox)} className="close-btn">
                      Close
                    </button>
                    <button type="submit" className="submit-btn">
                      submit
                    </button>

                  </div>

                </div> : <div
                  className="fs-5 base-font-inter text-secondary border-0 rounded-2 cursor"
                  style={{ fontWeight: "400", resize: "none", backgroundColor: "#f8f9fc" }}
                  id="dis"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                  onClick={() => addEventListener("dblclick", () => setEditBox(!editBox))}
                ></div>

              }
            </div>
          </form>
          <div className="">
            <label
              className="fs-5 base-font-inter text-base-color m-0 p-0 "
              style={{ fontWeight: "600" }}
            >
              Attachments ({JSON.parse(data?.images)?.length ? JSON.parse(data?.images)?.length : 0})
            </label>
            {
              JSON.parse(data?.images)?.length > 0 ? <div className="scrollbarshow m-0 gy-3 ">
                {JSON.parse(data?.images).map((data: any, i: any) => {
                  return (
                    <div className="card col-4 m-2 mb-3 p-0 rounded-3" key={i} style={{ width: "15rem", height: "15rem" }}>
                      <DocumentViewModal data={data} />
                    </div>
                  )
                })
                }
              </div> :
                <div className="w-100 h-100 my-3  d-flex justify-content-center align-items-center rounded-2" style={{ backgroundColor: "#f8f9fc" }}>
                  <h4 className="text-base-color base-font-600">No Attachments !</h4>
                </div>
            }

          </div>
          {
            data?.url_attachments?.length > 0 && <div className="mt-5">
              <label
                htmlFor="dis"
                className="fs-5 base-font-inter text-base-color"
                style={{ fontWeight: "600" }}
              >
                Link Urls
              </label>
              <div className="">
                <a href={data?.url_attachments} target="_blank" rel="noopener noreferrer" className="fs-5 base-font-inter text-base-color hover-underline"> {data?.url_attachments}</a>
              </div>
            </div>
          }
          {
            taskss?.length !== 0 &&
            <div className="pt-3">
              <div className="d-flex justify-content-between align-items-center px-2">
                <label
                  className="fs-5 base-font-inter text-base-color m-0 p-0 "
                  style={{ fontWeight: "600" }}
                >
                  Sub Task List ({taskss?.length})
                </label>
              </div>
              <div className="mt-3">
                <div className="">
                  <div className="">
                    <div className="d-flex align-items-center justify-content-end pb-3">
                    </div>
                    {taskss?.length !== 0 && <Table columns={columns} data={taskss} />}
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="pt-5 pe-4">
            <label
              className="fs-5 base-font-inter text-base-color"
              style={{ fontWeight: "600" }}
            >
              Activity
            </label>
            <div className="">
              <Tabs className="">
                <div className="d-flex align-items-center gap-3">
                  <label
                    className="fs-5 mb-0 base-font-inter text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Show :
                  </label>
                  <TabList className="d-flex p-0 mb-0 sellGalaTabsMain gap-2  py-2 rounded-2">
                    <Tab className="shadow-none cursor base-white-yt fs-6 base-font-pop py-2 px-3 list-unstyled rounded-3" style={{ fontWeight: "600" }}>
                      Comments
                    </Tab>
                  </TabList>
                </div>
                <TabPanel>
                  <div className="py-3 pe-2">
                    <div className="">
                      {
                        commentBox ? <form onSubmit={handleSubmit1(Comment)}><div className="pe-3 w-100"><CKEditor
                          editor={ClassicEditor}
                          config={{ placeholder: "Write Your Comment Here ..." }}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setValue1("comment", data)
                          }}
                          onBlur={(event, editor) => {
                          }}
                          onFocus={(event, editor) => {
                          }} />
                          <div className="w-100 d-flex justify-content-end pt-2 gap-3">
                            <button onClick={() => setCommentBox(!commentBox)} className="close-btn">
                              Close
                            </button>
                            <button type="submit" className="submit-btn">
                              submit
                            </button>

                          </div>

                        </div></form> :
                          <div className="d-flex align-items-center gap-3 border shadow rounded-2 p-2">
                            <div className="wh-35 ">
                              <img
                                src={data?.assigned_by?.profile_pic != null && data?.assigned_by?.profile_pic != undefined && !data?.assigned_by?.profile_pic.includes("images/") ? data?.assigned_by?.profile_pic : userImages}
                                alt={data.assigned_by.firstname}
                                className="user-image-fit"
                              />
                            </div>
                            <div
                              className="fs-5 py-3 w-100 base-font-inter text-secondary border-0 rounded-2 cursor"
                              style={{ fontWeight: "400", resize: "none", backgroundColor: "#f8f9fc" }}
                              onClick={handleToggle}
                            ><p className="p-0 m-0 ml-3 pl-3 fs-6">Write Your Comment Here ...</p></div>
                          </div>
                      }
                      {!commentBox && <h5 className="fs-6 base-font-400 py-2 text-secondary d-flex align-items-center gap-1">
                        <i className="bx bx-info-circle  fs-3 mb-0 base-font-400 text-warning bx-tada"></i> Pro Tip : Press <span className="fs-6 base-font-400 px-2 text-secondary rounded-1"
                          style={{ fontWeight: "400", backgroundColor: "#ddd" }}>Ctrl</span> + <span className="fs-6 base-font-400 px-2 text-secondary rounded-1"
                            style={{ fontWeight: "400", backgroundColor: "#ddd" }}>M</span> to comments</h5>}
                    </div>
                    <div className="mt-3 d-flex flex-column gap-2">
                      {commentss?.length > 0 &&
                        <>
                          {
                            commentss.map((commentss: any) => {
                              return (
                                <>
                                  {
                                    editcomment && commentss?.id == id ? <>
                                      <form onSubmit={handleSubmit1(editComment)}><div className="pe-3 w-100"><CKEditor
                                        editor={ClassicEditor}
                                        config={{ placeholder: "Write Your Comment Here ..." }}
                                        data={commentss?.comment}
                                        onReady={(editor) => {
                                          // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setValue1("comment", data)
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                        }} />
                                        <div className="w-100 d-flex justify-content-end pt-2 gap-3">
                                          <button onClick={() => setEditComment(!editcomment)} className="close-btn">
                                            Close
                                          </button>
                                          <button type="submit" className="submit-btn">
                                            submit
                                          </button>

                                        </div>

                                      </div></form>
                                    </> : <>
                                      <div className="d-flex  gap-3 border p-2 rounded">
                                        <div className="wh-35 ">
                                          <img
                                            src={data?.assigned_by?.profile_pic != null && data?.assigned_by?.profile_pic != undefined && !data?.assigned_by?.profile_pic.includes("images/") ? data?.assigned_by?.profile_pic : userImages}
                                            alt={data.assigned_by.firstname}
                                            className="user-image-fit"
                                          />
                                        </div>
                                        <div
                                          className="w-100"
                                        >
                                          <div className=""><span className="fs-5 base-font-500 px-2 rounded-1"
                                            style={{ fontWeight: "500", backgroundColor: "#ddd" }}>{commentss?.member?.team_member_name?.firstname} {commentss?.member?.team_member_name?.lastname}</span> <span className="base-font-500">{moment(commentss?.updated_at).format("llll")}</span> </div>
                                          <h5 className="fs-6 base-font-400 my-1 w-100 py-2 px-1 rounded mb-2" dangerouslySetInnerHTML={{ __html: commentss?.comment }} style={{ resize: "none", backgroundColor: "#f8f9fc" }}></h5>
                                          {commentss?.member?.team_member_name?.id == user[0].id &&
                                            <>
                                              <div className="d-flex justify-content-end gap-2 align-items-center">
                                                <button className="rounded-pill btn btn-warning py-1" onClick={() => { setEditComment(!editcomment); setID(commentss.id) }}>Edit</button>
                                                <button className="rounded-pill btn btn-danger py-1"
                                                  style={{ fontWeight: "500" }} onClick={() => { deleteComment(commentss) }}>Delete</button>
                                              </div>
                                            </>}
                                        </div>
                                      </div>
                                    </>
                                  }
                                </>
                              )
                            })
                          }
                        </>
                      }
                    </div>

                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="col-3 rounded-3 pb-3 px-0 ">
          <div className="bg-white py-3 border mx-2 project_task  px-2  rounded-3 shadow align-items-center d-flex gap-3">
            <div className="wh-35 ">
              <img
                src={data?.assigned_by?.profile_pic != null && data?.assigned_by?.profile_pic != undefined && !data?.assigned_by?.profile_pic.includes("images/") ? data?.assigned_by?.profile_pic : userImages}
                alt={data.assigned_by.firstname}
                className="user-image-fit"
              />
            </div>
            <div className="">
              <h4 className="text-capitalize fs-5 mb-0 text-base-color base-font-600 text-nowrap">
                {data?.assigned_by?.firstname} {data?.assigned_by?.lastname.length > 7 ? `${data?.assigned_by?.lastname.slice(0, 7)}...` : data?.assigned_by?.lastname}
              </h4>
              <label
                className="base-font-pop text-capitalize mb-0 ps-1 fs-5 light-icon text-nowrap"
                style={{ fontWeight: "500" }}
              >
                {data?.assigned_to?.team?.project?.project_type.length > 15 ? `${data?.assigned_to?.team?.project?.project_type.slice(0, 15)}...` : data?.assigned_to?.team?.project?.project_type}
              </label>
              <h6 className="mb-0 ">( Assignee By)</h6>
            </div>
          </div>
          <ul className="list-group m-2 border-0  rounded-4 shadow">
            <li className="list-group-item bg-base-color text-white base-font-pop fs-5">
              Assignee Details
            </li>
            <li
              className="list-group-item  border-0 base-font-pop pb-0 fs-6"
              style={{ fontWeight: "700" }}
            >
              Assigned To
            </li>
            <li className="list-group-item border-top-0 border-start-0 pt-0 border-end-0 base-font-pop fs-6">
              {data?.assigned_to?.team_member_name?.firstname} {data?.assigned_to?.team_member_name?.lastname}
            </li>
            <li
              className="list-group-item  border-0 base-font-pop pb-0 fs-6"
              style={{ fontWeight: "700" }}
            >
              Reporter
            </li>
            <li className="list-group-item border-top-0 border-start-0 pt-0 border-end-0 base-font-pop fs-6">
              {data?.report_to}
            </li>
            <li
              className="list-group-item  border-0 base-font-pop pb-0 fs-6"
              style={{ fontWeight: "700" }}
            >
              Task Type
            </li>
            <li className="list-group-item border-top-0 border-start-0 pt-0 border-end-0 base-font-pop fs-6 " style={{ fontWeight: "600" }}>
              {data?.type == "Task" ? <TickCircle variant="Bulk" className="text-info" size="28" /> : data?.type == "Bug" ? <InfoCircle variant="Bulk" className="text-danger" size="28" /> : data?.type == "Epic" && <FlashCircle variant="Bulk" className="status-color-purple" size="28" />} {data?.type !== null ? data.type : "None"}
            </li>
            <li
              className="list-group-item  border-0 base-font-pop pb-0 fs-6"
              style={{ fontWeight: "700" }}
            >
              Priority
            </li>
            <li className="list-group-item border-0 base-font-pop fs-6">
              <h5 className={data?.priority == 'High' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-red' :
                data?.priority == 'Normal' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-green' :
                  data?.priority == 'Low' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-blue' : 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-blue'}>
                <span className={data?.priority == 'High' ? 'mb-0 fs-5 text-base-inter fw-semibold status-color-red' :
                  data?.priority == 'Normal' ? 'mb-0 fs-5 text-base-inter fw-semibold status-color-green' :
                    data?.priority == 'Low' ? 'mb-0 fs-5 text-base-inter fw-semibold status-color-blue' : 'mb-0 fs-5 text-base-inter fw-semibold status-color-blue'} >
                  {data?.priority}
                </span>
              </h5>
            </li>
          </ul>
        </div>
      </div>
    </div >
  );
};

export default TaskDetails;
