import React, { useRef } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useNavigate } from "react-router-dom";
import signIn from '../../image/favicon-insta-courier-png.png'
import { environment } from '../../env';
import { ArrowLeft, Key, SmsNotification } from 'iconsax-react';





const ResetPassword = () => {

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const navigate = useNavigate();

    const submitform = async (passworddata: any) => {
        await axios.post(`${environment.baseUrl}reset-password/`, passworddata)
            .then(res => {

                if (res.data.status === 'success') {
                    swal({
                        title: "Password Reset Successfully",
                        icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                    })
                        .then(() => {
                            navigate("/");
                            reset();
                        })
                }
            })
            .catch(res => console.log("error"))
    }
    return (
        <>
            <div className="signin-page">
                <div className="card border-0 login-card shadow-lg p-3 rounded-5">
                    <div className="row m-0 p-0 w-100 h-100">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-7 px-5 pb-md-5">
                            <div className="d-flex justify-content-center flex-column py-4">
                                <h1 className="base-font-pop fw-bold pt-2  text-base-color" style={{ fontSize: "3.2rem" }}>Reset Password</h1>
                            </div>
                            <div className="">
                                <form className="d-flex flex-column px-5" onSubmit={handleSubmit(submitform)}>
                                    <div className="d-flex  flex-column align-items-start w-100 margin-bottom-0">
                                        <label htmlFor="username" className="base-font-600 fs-5 text-start text-base-color">
                                            Username
                                        </label>
                                        <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                            <SmsNotification size="32" className='text-base-color' />
                                            <input
                                                className="border-0 rounded-pill  base-font-500 py-2 px-2 fs-4 w-100"
                                                type="email"
                                                placeholder="Email"
                                                {...register("email_id", { required: true })}
                                            />
                                        </div>

                                        {errors.email_id && <span className='fs-6 pt-2 text-danger base-font-600'>Email Id is required *</span>}
                                    </div>
                                    <div className="d-flex  flex-column align-items-start w-100 margin-bottom-0">
                                        <label htmlFor="examplePassword" className=" base-font-600 fs-5 text-start text-base-color">
                                            Password *
                                        </label>
                                        <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                            <Key size="32" className='text-base-color' />
                                            <input
                                                className="border-0 rounded-pill base-font-500 py-2 px-2 fs-4 w-100"
                                                type="password"
                                                {...register("password", { required: true })}
                                                placeholder="Password"
                                            />
                                        </div>
                                        {errors.password && <span className='fs-6 pt-2 text-danger base-font-600'>Password is required *</span>}
                                    </div>
                                    <div className="d-flex  flex-column align-items-start w-100 margin-bottom-0">
                                        <label htmlFor="examplePassword" className=" base-font-600 fs-5 text-start text-base-color">
                                            Confirm Password *
                                        </label>
                                        <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                            <Key size="32" className='text-base-color' />
                                            <input
                                                className="border-0 rounded-pill base-font-500 py-2 px-2 fs-4 w-100"
                                                type="password"
                                                {...register("confirm_password", {
                                                    required: true,
                                                    validate: value => value === password.current || "The password do not match"
                                                })}
                                                placeholder="Re-Type New Password "
                                            />
                                        </div>
                                        {errors.confirm_password && <span className='fs-6 pt-2 text-danger base-font-600'>Confirm Password is required *</span>}
                                    </div>
                                    <div className="mt-2">
                                        <input className="border-0 rounded-pill  base-font-pop py-2 px-2 fs-4 w-100 bg-base-color text-white" type="submit" defaultValue="Submit" />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                        <Link className='d-flex justify-content-center base-font-700 mt-3 text-decoration-underline' to='registration' onClick={() => navigate(-1)}><span className=""><ArrowLeft /> Go Back</span></Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col col-md-6 col-lg-6 col-xl-5 p-0 d-flex justify-content-center align-items-center border-left-side">
                            <div className="img-card p-2">
                                <img src={signIn} alt="" className="img-fit" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword
