import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddEmployee from "./components/AddEmployee/AddEmployee";
import ResetPassword from "./components/auth/ResetPassword";

import UserLogin from "./components/auth/UserLogin";
import UserRegistration from "./components/auth/UserRegistration";
import FilterDashboard from "./components/FilterDashboard";
import SideBar from "./components/Layout/sidebar/SideBar";
import LeavePage from "./components/Leave/ALeavePage";
import Mispunchdetails from "./components/mispunchDetails";
import MultiFilter from "./components/MultiFilter";
import Navigation from "./components/Navigation/Navigation";
import AdminDashboard from "./components/pages/AdminDashboard";
import AddBankDetails from "./components/pages/EmpBasic_Details/AddBankDetails";
import AddFamilyDetails from "./components/pages/EmpBasic_Details/AddFamilyDetails";
import DetailsNavigation from "./components/pages/EmpBasic_Details/DetailsNavigation";
import EditBasicDetails from "./components/pages/EmpBasic_Details/EditBasicDetails";
import JoiningLetter from "./components/pages/joiningletter";
import UserDocuments from "./components/pages/udocuments";
import SalarySlip from "./components/SalarySlip/salaryslips";

import { useSelector, useDispatch } from "react-redux";
import reducers from "./Redux/reducers/reducers";
import { loginAction } from "./Redux/actions";
import AddSalarySlipPage from "./components/SalarySlip/AddSalarySlipPage";
import DashBoard from "./components/Dashboard";
import Holidays from "./components/holidays";
import TaskTab from "./components/pages/TaskTab/TaskTab";
import Meeting from "./components/pages/Meeting/Meeting";
import AddTaskPage from "./components/pages/TaskTab/AddTaskPage";
import TaskDetails from "./components/pages/TaskTab/TaskDetails";
import ScrollToTop from "./helper/ScrollToTop";
import SubTaskDetails from "./components/pages/TaskTab/SubTaskDetails";
import UserBreakLog from "./components/BreakLog";
import RemovedEmployees from "./components/AddEmployee/RemovedEmployees";
function App() {
  const Logged = useSelector((state: any) => state?.root?.reducers?.loggedIn);
  const [lodingSpinner, setLodingSpinner] = useState(false);
  const spinner = document.getElementById("lodingSpinner");

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLodingSpinner(true);
    }, 3500);
  }
  return (
    <>
      {lodingSpinner && (
        <>
          <BrowserRouter>
            {Logged ? (
              <SideBar>
                <ScrollToTop>
                  <Routes>
                    <Route path="/" element={<DashBoard />} />
                    <Route path="admin-dashboard" element={<MultiFilter />} />
                    <Route path="leaves" element={<LeavePage />} />
                    <Route path="mispunch" element={<Mispunchdetails />} />
                    <Route path="addemployee" element={<AddEmployee />} />
                    <Route path="basicdetails" element={<DetailsNavigation />} />
                    <Route path="document" element={<UserDocuments />} />
                    <Route path="breaklog" element={<UserBreakLog />} />
                    <Route path="holidays" element={<Holidays />} />
                    <Route path="joining" element={<JoiningLetter />} />
                    <Route path="meeting" element={<Meeting />} />
                    <Route path="taskTab" element={<TaskTab />} />
                    <Route path="addTaskPage/:id" element={<AddTaskPage />} />
                    <Route path="taskDetails" element={<TaskDetails />} />
                    <Route path="subTaskDetails" element={<SubTaskDetails />} />
                    <Route path="salaryslip" element={<SalarySlip />} />
                    <Route path="removeemployee" element={<RemovedEmployees />} />
                    <Route
                      path="salaryslip/addsalaryslip"
                      element={<AddSalarySlipPage />}
                    />
                    <Route path="dashboard" element={<DashBoard />} />
                  </Routes>
                </ScrollToTop>
              </SideBar>
            ) : (
              <Routes>
                <Route path="/" element={<UserLogin />} />
                <Route path="/registration" element={<UserRegistration />} />
                <Route path="/forgot-password" element={<ResetPassword />} />
              </Routes>
            )}
          </BrowserRouter>
        </>
      )}
    </>
  );
}

export default App;
