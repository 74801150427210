import React, { useState } from "react";
import swal from "sweetalert";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Form,
} from "reactstrap";
import { useForm } from "react-hook-form";
import axios, { Axios } from "axios";
import { S3_BUCKET, environment, link, myBucket } from "../../env";
import { useSelector } from "react-redux";

interface UserData {
  firstname: any;
  lastname: any;
}

const AddJoining = (props: any) => {
  const allUsers = useSelector((state: any) => state?.root?.reducers?.allEmployees);

  const [modal, setModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const toggle = () => {
    setModal(!modal);
  };

  const AddDocument = async (data: any) => {
    var date = Math.round(+new Date() / 1000);
    const params = {
      ACL: "public-read",
      Body: data,
      Bucket: S3_BUCKET,
      Key: `joiningletter_${date}_${data.name}`,
    };
    myBucket.upload(params, function (err: any, uploadData: any) {
      if (uploadData) {
        data = uploadData.Location;
        console.log(data)
        return (data);
      } else {
        console.log("error", err);
      }
    });
  }

  const onSubmit = async (value: any) => {
    var date = Math.round(+new Date() / 1000);
    console.log(value)
    AddDocument(value.file[0])
    value.file = `${link}joiningletter_${date}_${value.file[0].name}`
    try {
      await axios
        .post(`${environment.baseUrl}upload-joining-letter`, value)
        .then(async (form) => {
          if (form.data.code === 2000) {
            swal({
              title: "Success",
              text: "Joining Letter Added Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            toggle();
            props.update(!props.upd)
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button className='tb-btn base-font-muli fw-bold py-3 px-3 ms-3' onClick={toggle}>
        Joining Letter
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="md" centered
        className="mispunch-modal"
      >
        <ModalHeader
          className="p-4"
        >
          <span className='base-font-muli fs-4 text-base-color fw-bold '>Add Joining Letter</span></ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody
            className='d-flex justify-content-center px-5 pb-0'
          >
            <div className="InputFeild">
              <Label htmlFor="employee_id" className="text-black base-font-muli fw-bold fs-5 mb-0">
                Emp ID :
              </Label>
              <select className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none form-control"
                style={{ fontSize: "1rem" }}
                {...register("employee_id")}
              >
                <option value="" className='attoptions'>Employee Name...</option>
                {allUsers.map((user: any, i: any) => {
                  return <option className='attoptions' value={user.employee_id} key={i}>{user.firstname} {user.lastname}  </option>;
                })}
              </select>
            </div>
            <br />
            <div className="InputFeild pt-3">
              <Label htmlFor="file" className="text-black base-font-muli fw-bold fs-5 ">
                Joining Letter :
              </Label>
              <input
                accept=".pdf"
                type="file"
                id="file"
                {...register("file")}
                className="base-font-pop pb-3"
              />
            </div>
          </ModalBody>
          <ModalFooter className='border-0'>
            <button type='submit' className="submit-btn">
              Submit
            </button>
            <button onClick={toggle} type='button' className="close-btn">
              Close
            </button>
          </ModalFooter>
        </Form>

      </Modal>
    </>
  );
};

export default AddJoining;
