import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
  ButtonToolbar,
} from "reactstrap";
import { environment } from "../../env";
import swal from "sweetalert";
import editicon from "../../image/edit-3.svg";
import moment from "moment";
import { getFullAttData, getRemarks } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const MailModal = (props: any) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const allUsers = useSelector(
    (state: any) => state?.root?.reducers?.allEmployees
  );

  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const onSubmit = async (profileData: any) => {
    let a: any[] = [];
    allUsers.map((data: any) => {
      a.push(data.email_id);
    });
    profileData.mail_to = a;
    try {
      await axios
        .post(`${environment.baseUrl}send_email`, profileData)
        .then(async (form) => {
          if (form.data.success === "True") {
            swal({
              title: "Success",
              text: "Mail sent to everyone",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            props.update(true);
          }
        });
      reset();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Form encType="multipart/form-data">
        <div className="edit-attendance">
          <Link
            className="submit-btn"
            to=""
            onClick={() => {
              toggle();
              setEdit(true);
            }}
          >
            Send Email
          </Link>
        </div>
      </Form>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className="modalstyle"
        size="lg"
      >
        <ModalHeader className="py-3 px-4">
          <span className="base-font-muli fs-4 text-base-color fw-bold ">
            Send Mail
          </span>
        </ModalHeader>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="inner-login"
          encType="multipart/form-data"
        >
          <ModalBody className="py-3 px-4">
            <div className="d-flex justify-content gap-1 flex-column">
              <div className="row ">
                <div className="col-12 pt-4">
                  <Label
                    for="subject"
                    className="text-black base-font-muli fw-bold fs-5"
                  >
                    Subject :
                  </Label>

                  <input
                    type="text"
                    className="py-1 px-1  base-font-pop w-100  input-textarea shadow-none border-primary rounded-2"
                    style={{ outline: "none" }}
                    id="subject"
                    placeholder="Subject"
                    {...register("subject")}
                  />
                </div>
                <div className="col-12 pt-4">
                  <Label
                    for="message"
                    className="text-black base-font-muli fw-bold fs-5"
                  >
                    Message :
                  </Label>

                  <CKEditor
                    editor={ClassicEditor}
                    data="<p>Hello from CKEditor 5!</p>"
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="border-0">
            <button className="submit-btn">Submit</button>
            <button
              type={"button"}
              onClick={() => {
                toggle();
              }}
              className="close-btn"
            >
              Cancel
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default MailModal;
