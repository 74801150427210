import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import axios from "axios";
// import Table from "./Table/Table";

import { useSelector } from "react-redux";
import EmptyTable from "./errorComponents/EmptyTable";
import { DatePicker, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import CommentPop from "./errorComponents/CommentPop";
import { environment } from "./../env";
import swal from "sweetalert";
import { CalendarTick, Refresh } from "iconsax-react";
import Table from "../Table/Table";
import { useDispatch } from "react-redux";
import { getBreakLogData } from './../Redux/actions/index';
import ExportTable from "../Table/ExcelTable";

interface DataRow {
    id: number;
    date: any;
    day: string;
    break_type: string;
    break_in_time: string;
    break_out_time: any;
    // comment: string;
    remark: any;
    Name: string;
    Emp_Id: string;
    Date: string;
    Day: string;
    Comment: string;
    Remark: string;
    Ip_Address: string;
    Total_Time_Worked: string;
    Time_In: string;
    Time_Out: string;
    Break_Out: string;
    Break_In: string;
    BreakType: string;
}

const UserBreakLog = () => {
    const useratt = useSelector(
        (state: any) => state?.root?.reducers?.userAttendance
    );

    let breaklog = useSelector((state: any) => state?.root?.reducers?.breaklog);
    let breaks = breaklog.filter((data: { BreakType: string; }) => data.BreakType != "null")
    const [att, setAtt] = useState([]);
    const [comments, setComments] = useState();
    const { RangePicker } = DatePicker;
    const [modal, setModal] = useState(false);
    var comment = "";
    const dispatch = useDispatch()
    const current = (data: any) => {
        setModal(true);
        comment = data;
        setComments(data);
    };

    useMemo(() => {
        setComments(comments);
    }, [comments]);

    const columns: TableColumn<DataRow>[] = [
        {
            name: "EmpID",
            selector: (row) => row?.Emp_Id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            },
        },
        {
            name: "Name",
            selector: (row) => row?.Name,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            },
        },
        {
            name: "Date",
            selector: (row) => moment(row?.Date).format('DD-MM-YYYY'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            },
        },
        {
            name: "Day",
            selector: (row) => moment(row?.Date).format("dddd"),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            },
        },
        {
            name: "Break In Time",
            selector: (row) =>moment (row?.Break_In, "HH:mm:ss").format("hh:mm A"),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            },  
        },
        {
            name: "Break Out Time",
            selector: (row) =>moment (row?.Break_Out, "HH:mm:ss").format("hh:mm A"),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            },
        },
        {
            name: "BreakType",
            selector: (row) => row?.BreakType,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            },
        },
        // {
        //     name: "Break Type",
        //     selector: (row) => (
        //         <div
        //             className={`${row?.BreakType == "Lunch"
        //                 ? "status-bg-green status-color-green "
        //                 : row.BreakType == "Tea"s
        //                     ? "status-bg-orange status-color-orange"
        //                     : row.BreakType == "Loo"
        //                         ? "status-bg-blue status-color-blue"
        //                         : row.BreakType == "Other"
        //                         && "status-bg-yellow status-color-yellow"
        //                 } w-100 padding-box rounded-5 d-flex justify-content-center align-items-center`}
        //         >
        //             <span className="base-font-inter fw-bold fs-6 text-capitalize">
        //                 {row?.BreakType}
        //             </span>
        //         </div>
        //     ),
        //     sortable: true,
        //     style: {
        //         fontSize: "2.3rem",
        //     },
        // },
        {
            name: "Remark",
            selector: (row) => row?.Remark == 'null' ? '---' : row?.Remark,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            },
        },
    ];
    const getbreaklog = async () => {
        await axios
            .get(`${environment.baseUrl}admin-attendance`)
            .then((res) => {
                console.log('res.data', res.data);
                dispatch(getBreakLogData(res.data));
            })
            .catch((res) => console.log("error", res));
    };

    const onSubmit = (data: any) => {
        if (data) {
            let SearchUrl;
            SearchUrl = `${environment.baseUrl}attendance-filter?employee_id=${useratt[0].employee_id
                }&date_after=${data[0].format("YYYY-MM-DD")}&date_before=${data[1].format(
                    "YYYY-MM-DD"
                )}`;
            axios(SearchUrl).then((response) => {
                if (response?.data) {
                    setAtt(response?.data);
                } else {
                    swal({
                        title: "Warning",
                        text: "Data is Not Available for search 🤷🏻‍♂️",
                        icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
                        dangerMode: true,
                    });
                }
            });
        } else {
            setAtt([]);
        }
    };

    // useEffect(()=>{
    //     let tableData: {
    //         id: number;
    //         date: any;
    //         day: string;
    //         break_type: string;
    //         break_in_time: string;
    //         break_out_time: any;
    //         // comment: string;
    //         remark: any;
    //         Name: string;
    //         Emp_Id: string;
    //         Date: string;
    //         Day: string;
    //         Comment: string;
    //         Remark: string;
    //         Ip_Address: string;
    //         Total_Time_Worked: string;
    //         Time_In: string;
    //         Time_Out: string;
    //         Break_Out: string;
    //         Break_In: string;
    //         BreakType: string;
    //     } [] = [];
    //     if(breaklog){
    //         breaklog.map((row : any, i: number ) => {
    //             tableData.push({
    //             })
    //         })
    //     }
    // },[breaklog])

    useEffect(() => { getbreaklog() }, [])

    return (
        <>
            <section className="main-wrapper-section px-4 py-4 ">
                <div className="dashboard-heading mb-5 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <span className="base-text-color base-font-700 fs-4">
                            <CalendarTick size="35" variant="Bulk" />
                        </span>
                        <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                            Break Log
                        </h4>
                    </div>
                    <div onClick={() => { getbreaklog() }}
                        className="py-2 col-1 bg-white rounded-3 shadow d-flex justify-content-center align-items-center" title='Refresh'
                        // className={rotate ? "rotateBox " : ""}
                        style={{ cursor: "pointer" }}>
                        <Refresh
                            size="35" className='text-base-color' />
                    </div>
                </div>
                <div className="bg-white py-3 px-5 d-flex gap-5 flex-column rounded-tb shadow">
                    <div className="">
                        {breaks[0] ? (
                            <Table columns={columns} data={breaks != 0 && breaks} />
                        ) : (
                            <EmptyTable />
                        )}
                    </div>
                </div>
            </section >
        </>
    );
};

export default UserBreakLog;
