import React, { useEffect, useState } from "react";
import "./AddEmployee.css";
import { useForm } from "react-hook-form";
import userImg from "../../image/user-demo.jpeg";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import AddEmployeeModal from "./addEmployeeModal";
import axios from "axios";
import moment from "moment";
import { environment } from "../../env";
import { Refresh, UserTick } from "iconsax-react";
import EmployeeCard from "./EmployeeCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllEmployees } from "./../../Redux/actions/index";
import MailModal from "./MailModal";
import UploadDoc from "./UploadDoc";
import AddTeam from "../Models/AddTeamLeader";
interface UserData {
  employee_id: any;
  firstname: string;
  lastname: string;
  profile_pic: any;
}

function RemovedEmployees(props: any) {
  const allUsers = useSelector(
    (state: any) => state?.root?.reducers?.allEmployees
  );
  const dispatch = useDispatch();

  const getAllUsers = async () => {
    try {
      const res = await axios.get(`${environment.baseUrl}all-users-details/`);
      if (res.data) {
        dispatch(
          getAllEmployees(
            res.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
          )
        );
      }
    } catch (err) {
      console.log("err");
    }
  };

  useEffect(() => { }, [allUsers]);

  return (
    <>
      <div className="dashboard_main px-4 mt-3">
        <nav>
          <div className=" ">
            <div className="d-flex justify-content-between align-items-center p-0">
              <div className="dashboard-heading  px-0 d-flex align-items-center">
                <span className="base-text-color base-font-700 fs-4">
                  <UserTick size="35" variant="Bulk" />
                </span>
                <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                  Removed Employees
                </h4>
              </div>
              <div className="d-flex justify-content-between align-items-center gap-4">
                {/* <div>
                  <UploadDoc />
                </div> */}
                <div
                  className="py-2 px-3 bg-white rounded-3 shadow d-flex justify-content-center align-items-center"
                  title="Refresh"
                >
                </div>
                  {/* <AddTeam/> */}
                <div className="">
                  {/* <MailModal /> */}
                </div>
                  <div
                    onClick={() => {
                      getAllUsers();
                    }}
                    // className={rotate ? "rotateBox " : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <Refresh size="35" className="text-base-color" />
                  </div>
                <div className="">
                  {/* {allUsers[0].id && (
                    <AddEmployeeModal
                      id={allUsers[allUsers?.length - 1]?.id + 1}
                      emp={`E000${allUsers.length + 1}`}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </nav>
        <section className="mt-5">
          <div className="row row-cols-4 gap-5 text-center justify-content-center">
            {allUsers &&
              allUsers.map((curElm: {
                status: number; profile_pic: any
              }, i: any) => {
                if (curElm.status == 0) {
                  return <EmployeeCard curElms={curElm} />;
                }
              })}
          </div>
        </section>
      </div>
    </>
  );
}

export default RemovedEmployees;
