import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { FilterSearch, People, SearchNormal } from 'iconsax-react';
import Table from '../../../Table/Table';

interface DataRow {
    id: any;
    payslip_month: string;
}
const Meeting = () => {

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'ID',
            selector: row => row?.id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Month',
            selector: row => row?.payslip_month,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
    ];

    const data = [{
        id: 1,
        payslip_month: "Example",
    }]

    return (
        <>
            <div className="dashboard_main px-4 mt-3">
                <nav>
                    <div className=" d-flex align-items-center">
                        <div className="col-12 d-flex align-items-center p-0">
                            <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
                                <span className='base-text-color base-font-700 fs-4'><People size="35" variant="Bulk" /></span>
                                <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                                    Employee Meeting</h4>
                            </div>
                            <div className="px-3 py-2 col-4 me-3 bg-white rounded-3 shadow">
                                <div className='d-flex align-items-center bg-transparent border-base-bottom-2'>
                                    <SearchNormal size="25" className='text-base-color' />
                                    <input
                                        className='p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop'
                                        type='text'
                                        autoComplete='off'
                                        placeholder='Search Employee Name Here...'
                                        style={{ fontSize: "1.1rem" }}
                                    />
                                </div>
                            </div>
                            <div className="px-3 py-2 col-2 bg-white rounded-3 shadow">
                                <div className="d-flex align-items-center bg-transparent border-base-bottom-2">
                                    <FilterSearch size="25" className='text-base-color' />
                                    <select id="leave_type" name="leave_type" className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                                        style={{ fontSize: "1.1rem" }}>
                                        <option value="">Options...</option>
                                        <option value="CompOff">Comp Off</option>
                                        <option value="Sick Leave">Sick Leave</option>
                                        <option value="Privilage Leave">Privilage Leave</option>
                                        <option value="UnPaid Leave">Un-Paid Leave</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <section className='mt-5'>
                    <div className="bg-white pt-3 pb-3 px-4 d-flex gap-5 flex-column rounded-5 shadow">
                        <Table columns={columns} data={data} />
                    </div>
                </section>
            </div>
        </>


    )
}

export default Meeting
