import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

const SidebarMenu = (props: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleBlur = (event: any) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            if (isOpen) {
                setIsOpen(false);
            }
        }
    }
    return (
        <div onBlur={handleBlur} key={props.i}>

            <NavLink
                to={props.curElm.link}
                className={`sidebar-link ${isOpen ? "subMenu-active" : ""}`}
                onClick={() => setIsOpen(!isOpen)}>
                <span className="d-flex gap-2 align-items-center">
                    <span className='text-base-color'>
                        {props.curElm.icon}
                    </span>
                    <h1 className="mb-0 title-font-700 fs-5 base-font-muli fw-bold">{props.curElm.title}
                    </h1>
                </span>
                <div className="ps-2">
                    {
                        !isOpen ? <ArrowDown2
                            size="17"
                            className='base-animation'
                        /> : <ArrowUp2
                            size="17"
                            className='base-animation'
                        />
                    }
                </div>
            </NavLink>
            <div className={`${isOpen ? "subMenu-card" : "subMenu-card-close"}`}>
                {
                    isOpen &&
                    props.curElm.childs.map((index: any, i: any) => {
                        return (
                            <>
                                <NavLink to={index.link}
                                    key={i}
                                    className="sidebar-link">
                                    <span className="d-flex gap-2 align-items-center">
                                        <span className='text-base-color'>
                                            {index.icon}
                                        </span>
                                        <h5 className="mb-0 title-font-700 fs-5 base-font-muli fw-bold submenu-title">
                                            {index.title}
                                        </h5>
                                    </span>
                                </NavLink>
                            </>)
                    })}

            </div>
        </div>
    )
}

export default SidebarMenu