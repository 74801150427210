import React from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios';

import { environment } from '../../../env';
import '../sidebar/SideBar.css'

import SidebarMenu from './SidebarMenu'

import { LogoutCurve, Clock } from 'iconsax-react'
import { useDispatch } from 'react-redux';
import { getAllEmployees, getBreakLogData, getProjectData, getTasksData, getTeamMembersData, getTeamsData, loginAction } from '../../../Redux/actions'
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import SidebarApi from './SidebarApi'
import logo from '../../../image/favicon-insta-courier-png.png'
interface UserData {
    firstname: string;
    lastname: string;
    profile_pic: string;
    rewards: number;
    comp_off: number;
    previlage_leave: number;
    sick_leave: number;
}
const SideBar = ({ children }: any) => {

    var LoggedUser = useSelector((state: any) => state?.root?.reducers?.loggeduser);

    if (LoggedUser[0] == undefined) {
        LoggedUser[0] = {
            firstname: "string",
            lastname: "string",
            profile_pic: "string",
            rewards: "number",
            comp_off: "number",
            previlage_leave: "number",
            sick_leave: "number",
        }
    }

    const dispatch = useDispatch()

    const logoutTime = async () => {

        localStorage.removeItem("employee_id");
        dispatch(loginAction(false))
        window.location.href = "/";
    };

    const getProjects = async () => {
        axios.get(`${environment.baseUrl}create-project`)
            .then(res => {
                dispatch(getProjectData(res.data))
            })
            .catch(res => console.log("error"))
    }

    const getTeamMembers = () => {
        axios.get(`${environment.baseUrl}create-team-member`)
            .then(res => {
                dispatch(getTeamMembersData(res.data))
            })
            .catch(res => console.log("error"))
    }

    const getTasks = () => {
        axios.get(`${environment.baseUrl}create-task`)
            .then(res => {
                if (res.data.code != 5000) {
                    dispatch(getTasksData(res.data))
                }
            })
            .catch(res => console.log("error"))
    }

    const getTeams = () => {
        axios.get(`${environment.baseUrl}create-team`)
            .then(res => {
                dispatch(getTeamsData(res.data))
            })
            .catch(res => console.log("error"))
    }

    const getbreaklog = async () => {
        await axios
          .get(`${environment.baseUrl}admin-attendance`)
          .then((res) => {
            console.log('res.data', res.data)
            dispatch(getBreakLogData(res.data));
          })
          .catch((res) => console.log("error", res));
      };

    const getAllUsers = async () => {
        try {
          const res = await axios.get(`${environment.baseUrl}all-users-details/`);
          if (res.data) {
            dispatch(getAllEmployees(res.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id)));
          }
        } catch (err) {
          console.log("err");
        }
      };

    const RefreshData = () => {
        getbreaklog();
        getAllUsers()
        getProjects();
        getTeamMembers();
        getTasks();
        getTeams()
    }

    const CronDuty = async () => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancel", "Yes"],
        }).then((willDelete: any) => {
            if (willDelete) {
                axios.get(`${environment.baseUrl}cron/`)
                    .then(res => {
                        if (res.status == 200) {

                            swal("Cron ran successfully", {
                                icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                            });
                        }
                    })
            }
        })
    }

    return (
        <>
            <div className="" >
                <div className="main d-flex align-items-center " >
                    <div className='menu-sidebar' >
                        <div className="dashboard-box d-flex align-items-center justify-content-center py-4 border-bottom" >
                            <div className="w-75" onClick={() => { RefreshData() }}>
                                <img className='logo-name img-fit'alt='' src={logo} />
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-2 overflow-scroll  sidebar-tabs pt-3 pb-5'>
                            {
                                SidebarApi.map((curElm, i) => {
                                    if (curElm.childs) {
                                        return (
                                            <SidebarMenu key={i} curElm={curElm} />
                                        )
                                    }
                                    return (
                                        <NavLink to={curElm.link}
                                            key={i}
                                            className="sidebar-link">
                                            <span className="d-flex gap-2 align-items-center">
                                                <span className='text-base-color'>
                                                    {curElm.icon}
                                                </span>
                                                <h5 className="mb-0 title-font-700 fs-5 base-font-muli fw-bold">
                                                    {curElm.title}
                                                </h5>
                                            </span>
                                        </NavLink>
                                    )
                                })
                            }
                        </div>
                        <div className="user-box d-flex justify-content-center align-items-center gap-3 py-3 rounded-end flex-column bg-white">
                            <div className="user-image ">
                                <div className="userBox">
                                    <img src={LoggedUser[0]?.profile_pic} alt="user-img" className='user-image-fits' />
                                </div>

                            </div>
                            <div className="text-center">
                                <h5 className='base-font-muli fw-bold text-nowrap text-capitalize fs-5 mb-0 text-base-color'>{LoggedUser[0]?.firstname} {LoggedUser[0]?.lastname}</h5>
                                <label className='base-font-500 base-font-pop  text-nowrap text-capitalize mb-0'>{LoggedUser[0]?.designation == 'null' ? "Not Yet Assigned" : LoggedUser[0]?.designation}</label>
                                <div className="py-2">
                                    <button onClick={() => { CronDuty() }} className='base-font-500 base-font-pop text-warning  text-nowrap text-capitalize mb-0 fs-5 mb-0 border-0 bg-transparent'>CRON
                                        <Clock size="26" className='ps-1' />
                                    </button>
                                </div>
                                <button onClick={() => { logoutTime() }} className='base-font-500 base-font-pop text-danger  text-nowrap text-capitalize mb-0 fs-5 mb-0 border-0 bg-transparent'>Logout
                                    <LogoutCurve size="26" className='ps-1' />
                                </button>
                            </div>
                        </div>
                    </div>
                    <section className="main-section" >
                        <main className="wrapper-main" >
                            {children}
                        </main>
                    </section>
                </div>
            </div>
        </>
    )
}

export default SideBar