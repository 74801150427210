import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import SalarySlips from "./salaryslips";
import { Button } from "reactstrap";
import { environment } from "../../env";
// import AddSalarySlip from './salaryslipModal';
// import SalarySlipModal from './salaryslipModal';
import SalarySlipModal from "./addsalaryslipModal";
import { ReceiptAdd, ReceiptItem } from "iconsax-react";
import Table from "../../Table/Table";
import DataTable, { TableColumn } from "react-data-table-component";
import EmptyTable from "../errorComponents/EmptyTable";

interface SalaryData2 {
  id: any;
  user: any;
}

interface DataRow {
  id: any;
  salary: any;
  user: any;
  payslip_month: string;
}
const SalarySlip = () => {
  const [salarydata, setSalaryData] = useState<SalaryData2[]>([]);
  console.log(
    "🚀 ~ file: salaryslips.tsx:30 ~ SalarySlip ~ salarydata:",
    salarydata
  );

  const SalarySlip = async () => {
    const userUrl = `${environment.baseUrl}get-all-salaryslip`;
    const result = await axios.get(userUrl);
    if (result.data.Data === "Data Not Found") {
      alert("Data Not Found");
      return;
    }
    console.log("data----v", result.data);
    setSalaryData(result.data);
  };

  useEffect(() => {
    SalarySlip();
  }, []);

  const columns: TableColumn<DataRow>[] = [
    {
      name: "ID",
      selector: (row) => row?.user.employee_id,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Name",
      selector: (row) => row?.user.firstname + " " + row?.user.lastname,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Month",
      selector: (row) => row?.payslip_month,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <Link to="#">
            <SalarySlipModal />
          </Link>
        </div>
      ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  const data = [
    {
      id: 1,
      payslip_month: "Example",
    },
  ];

  return (
    <>
      <div className="dashboard_main px-4 mt-3">
        <nav>
          <div className=" d-flex align-items-center">
            <div className="col-12 d-flex align-items-center justify-content-between p-0">
              <div className="dashboard-heading  px-0 d-flex align-items-center">
                <span className="base-text-color base-font-700 fs-4">
                  <ReceiptItem size="35" variant="Bulk" />
                </span>
                <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                  Salary Slips
                </h4>
              </div>
              <Link to="addsalaryslip">
                <Button className="tb-btn base-font-muli fw-bold py-2 px-3 ms-3">
                  Add Salary Slip
                  <ReceiptAdd size="35" variant="Bulk" className="ps-2" />
                </Button>
              </Link>
            </div>
          </div>
        </nav>
        <section className="mt-5">
          <div className="bg-white pt-3 pb-3 px-4 d-flex gap-3 flex-column rounded-5 shadow">
            {salarydata ? (
              <Table columns={columns} data={salarydata} />
            ) : (
              <EmptyTable tabletext="Salary Slips" />
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default SalarySlip;
