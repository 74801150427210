import "font-awesome/css/font-awesome.min.css";
import React, { useState, useRef, useEffect } from "react";
import swal from "sweetalert";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { environment } from "../../env";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../Redux/actions";

const AddEmployeeModal = (props: any) => {
  const allUsers = useSelector(
    (state: any) => state?.root?.reducers?.allEmployees
  );
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    reset();
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const password = useRef({});
  const dispatch = useDispatch()
  password.current = watch("password", "");

  const getAllUsers = async () => {
    try {
      const res = await axios.get(`${environment.baseUrl}all-users-details/`);
      if (res.data) {
        dispatch(getAllEmployees(res.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id)));
      }
    } catch (err) {
      console.log("err");
    }
  };

  const submitform = async (registerform: any) => {
    try {
      if (allUsers.length < 142) {
        await axios
          .post(`${environment.baseUrl}user-registration/`, registerform)
          .then(async (form) => {
            if (form.data.status === "success") {
              // await Logindata();
              getAllUsers();
              toggle();
              swal({
                title: "Success",
                text: "Registration Sucessfull Done",
                icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
              });
              props.update(true);
            }
          });
        reset();
      } else {
        swal({
          title: "You have reached the limit of User's Creation!",
          icon: "error",
        })
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllUsers()
  }, [])

  return (
    <>
      <button className="submit-btn " onClick={toggle}>
        {" "}
        Add Employee
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        className="modalstyle"
        centered
      >
        <ModalHeader toggle={toggle}>
          <span className="base-font-muli fs-4 text-base-color fw-bold ">
            Add New Employee
          </span>
        </ModalHeader>

        <ModalBody className="py-3 px-4">
          <form onSubmit={handleSubmit(submitform)}>
            <div>
              <div className="col_half">
                <div
                  className="input_field"
                  style={{
                    position: "relative",
                    marginBottom: "20px",
                    animation: "bounce 0.6s ease-out",
                  }}
                >
                  {" "}
                  <span>
                    <i aria-hidden="true" className="fa fa-user text-dark" />
                  </span>
                  <input
                    className="py-1 fs-6 shadow-none input-border ms-4 w-25"
                    type="text"
                    value={props.emp}
                    id="employee_id"
                    {...register("employee_id", { required: true })}
                    placeholder="Employee ID"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between g-3 flex-row">
              <div className="">
                <div className="input_field text-dark">
                  {" "}
                  <span>
                    <i aria-hidden="true" className="fa fa-user" />
                  </span>
                  <input
                    className="py-1 fs-6 shadow-none input-border ps-2 ms-4 w-75"
                    type="text"
                    {...register("firstname", { required: true })}
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="">
                <div className="input_field">
                  {" "}
                  <span>
                    <i aria-hidden="true" className="fa fa-user" />
                  </span>
                  <input
                    className="py-1 fs-6 shadow-none input-border ps-2 ms-3 w-75"
                    type="text"
                    {...register("lastname", { required: true })}
                    placeholder="Last Name"
                  />
                </div>
                {errors.lastname && (
                  <span className="error"> * lastname is required</span>
                )}
              </div>
            </div>
            <div className="input_field mt-3">
              {" "}
              <span>
                <i aria-hidden="true" className="fa fa-envelope" />
              </span>
              <input
                className="py-1 fs-6 shadow-none input-border ps-2 ms-3 w-75"
                type="email"
                {...register("email_id", { required: true })}
                placeholder="Email"
              />
            </div>
            {errors.email && (
              <span className="error"> * Email Id is required</span>
            )}
            <div className="input_field mt-3">
              {" "}
              <span>
                <i aria-hidden="true" className="fa fa-lock" />
              </span>
              <input
                className="py-1 fs-6 shadow-none input-border ps-1 ms-4 w-75"
                type="password"
                {...register("password", { required: true })}
                placeholder="Password"
              />
            </div>
            <div className="input_field mt-3">
              {" "}
              <span>
                <i aria-hidden="true" className="fa fa-lock" />
              </span>
              <input
                className="py-1 fs-6 shadow-none input-border ps-1 ms-4 w-75"
                type="password"
                {...register("confirm_password", { required: true })}
                placeholder="Re-type Password"
              />
            </div>
            <ModalFooter className="border-0">
              <button className="submit-btn">Submit</button>
              <button
                type={"button"}
                onClick={() => {
                  toggle();
                }}
                className="close-btn"
              >
                Cancel
              </button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddEmployeeModal;
