import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import swal from 'sweetalert';
import { environment } from '../../env';

interface User {
    id: number;
    firstname: string;
    lastname: string;
}

interface SelectedUser {
    id: number;
}

export default function AddTeam() {
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([0]);
    const [teamMembers, setTeamMembers] = useState<SelectedUser[]>([]);
    const [initialSelectedUsers, setInitialSelectedUsers] = useState<number[]>([]);
    let EmpId = localStorage.getItem("employee_id");

    const toggle = () => {
        if (isOpen) {
            setSelectedUsers([...initialSelectedUsers]);
        } else {
            setInitialSelectedUsers([...selectedUsers]);
        }
        setIsOpen(!isOpen);
    };

    const getAllUsers = async () => {
        try {
            const res = await axios.get<User[]>(`${environment.baseUrl}get-add-team-users`);
            if (res.data) {
                setAllUsers(res.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    const handleUserSelect = (selectedOption: User | null, index: number) => {
        const newSelectedUsers = [...selectedUsers];
        newSelectedUsers[index] = selectedOption?.id || 0;
        setSelectedUsers(newSelectedUsers);
    };

    const handleAddMore = () => {
        setSelectedUsers((prevUsers) => [...prevUsers, 0]);
    };

    const handleSubmit = () => {
        const filteredUsers = selectedUsers.filter((userId) => userId !== 0);
        const selectedIds = filteredUsers.map((userId) => ({ id: userId }));
        setTeamMembers(selectedIds);
        axios
            .put(`${environment.baseUrl}add-team-leader/`, { "team": JSON.stringify(selectedIds) })
            .then((res) => {
                if (res.status == 200) {
                    swal({
                        title: "Success",
                        text: "Team Leaders added  Successfully !",
                        icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                    });
                }
                getAllUsers();
                setIsOpen(false);
            })
            .catch((res) => console.log("error"));
    };

    return (
        <>
            <Button
                className="tb-btn base-font-muli fw-bold d-flex align-items-center"
                onClick={toggle}
            >
                Add Team Leaders
            </Button>
            <Modal size="md" centered className="modalstyle" isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <span className="base-font-muli fs-4 text-base-color fw-bold">Add Team Leaders</span>
                </ModalHeader>
                <ModalBody className="py-3 px-5">
                    <div key={0} className="mb-2">
                        <Select
                            className="border-primary p-2 rounded-2 base-font-pop fw-normal mb-3 w-100"
                            options={allUsers}
                            value={allUsers.find((user) => user.id === selectedUsers[0])}
                            onChange={(selectedOption: User | null) => handleUserSelect(selectedOption, 0)}
                            getOptionLabel={(user) => `${user?.firstname || 'Select'} ${user?.lastname || 'User'}`}
                            getOptionValue={(user) => (user ? user.id.toString() : '0')}
                            isSearchable
                        />
                    </div>
                    {selectedUsers.slice(1).map((userId, index) => (
                        <div key={index + 1} className="mb-2">
                            <Select
                                className="border-primary p-2 rounded-2 base-font-pop fw-normal mb-3 w-100"
                                options={allUsers}
                                value={allUsers.find((user) => user.id === userId)}
                                onChange={(selectedOption: User | null) => handleUserSelect(selectedOption, index + 1)}
                                getOptionLabel={(user) => `${user?.firstname || 'Select'} ${user?.lastname || 'User'}`}
                                getOptionValue={(user) => (user ? user.id.toString() : '0')}
                                isSearchable
                            />
                        </div>
                    ))}

                    <Button onClick={handleAddMore}>Add More</Button>
                </ModalBody>
                <div className="modal-footer">
                    <Button className="submit-btn" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                    <Button className="close-btn" color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </div>
            </Modal>
        </>
    );
}