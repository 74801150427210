import React from 'react'
import { Card, CardBody, Label } from 'reactstrap'
import './basicdetails.css'
import { useSelector } from 'react-redux';

const Address = (props: any) => {
    const basicdetails = useSelector(
        (state: any) => state?.root?.reducers?.basicDetails
    );
    return (
        <>
            <Card className='mt-3 mx-3 mb-2 px-3 border-0 position-relative'>
                <div className=''>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h4 className='fs-2 mb-0 text-base-color fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                            Employee Address</h4>
                    </div>
                </div>
                <CardBody className='p-0 py-2'>
                    <div className='border-bottom'>
                        <div className="p-2">
                            <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1" >Address</Label>
                            <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop " > {basicdetails?.address} </Label>
                        </div>
                    </div>
                    <div className='d-flex row pt-3' >
                        <div className=" col-2">
                            <div className="p-2">
                                <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1"   >City</Label>
                                <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop"  > {basicdetails.city} </Label>
                            </div>
                        </div>

                        <div className=" col-2">
                            <div className="p-2">
                                <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1"   >District</Label>
                                <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop"  > {basicdetails.pincode} </Label>
                            </div>
                        </div>

                        <div className=" col-3">
                            <div className="p-2">
                                <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1"   >State</Label>
                                <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop BD-labels-font shadow-none"  > {basicdetails.state} </Label>
                            </div>
                        </div>
                        <br />
                        <div className=" col-2">
                            <div className="p-2">
                                <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1"   >Pin Code</Label>
                                <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop"  > {basicdetails.pincode} </Label>
                            </div>
                        </div>
                        <div className=" col-3">
                            <div className="p-2">
                                <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1"   >Country</Label>
                                <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop"  > {basicdetails.country} </Label>
                            </div>
                        </div>
                    </div>

                </CardBody>
            </Card>


        </>
    )
}
export default Address