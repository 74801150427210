
import './registration.css'
import 'font-awesome/css/font-awesome.min.css';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import signIn from '../../image/favicon-insta-courier-png.png'
import axios from 'axios';
import swal from "sweetalert";
import { environment } from '../../env';
import { ArrowLeft, Eye, EyeSlash, Key, SmsNotification } from 'iconsax-react';

const UserRegistration = () => {
    const [post, setPost] = useState([])
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const [empid, setEmpId] = useState('E0001')
    const password = useRef({});
    const [passwordShown, setPasswordShown] = useState(false);
    const [btndis, setBtndis] = useState(false);
    password.current = watch("password", "");

    // const EmployeeDetails = async () => {
    //     await axios.get(`${environment.baseUrl}all-users-details/`)
    //         .then(res => {
    //             let emp = `E000${res.data.length+1}`
    //             setEmpId(emp)
    //             setPost(res.data);
    //         })
    //         .catch(res => console.log("error"))
    // }

    const submitform = (registerform: any) => {
        setBtndis(true)
        const verifyEmail = `${environment.baseUrl}email/`;
        registerform.is_admin = true;
        axios.post(verifyEmail, registerform)
            .then((response) => {
                if (response.data.Message === 'Email already Exists') {
                    setBtndis(false)
                    swal({
                        title: "Email already exist!",
                        icon: "error",
                    })
                        .then(() => {
                            reset();
                        });
                } else {
                    const createUserUrl = `${environment.baseUrl}admin-registration/`;
                    registerform.is_admin = "True"
                    registerform.employee_id = empid
                    if(post.length < 142){
                        const result = axios.post(createUserUrl, registerform)
                        .then((response) => {
                            if (response.data.status === 'success') {
                                setBtndis(false)
                                swal({
                                    title: "Registration Sucessfull Done",
                                    icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                                })
                                    .then(() => {
                                        navigate("/");
                                        reset();
                                    })
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    }else{
                        swal({
                            title: "You have reached the limit of User's Creation!",
                            icon: "error",
                        })
                    }
                }
            })
    };

    useEffect(() => {
        // EmployeeDetails()
    }, [])


    return (
        <>
            <div className="signin-page">
                <div className="card border-0 login-card shadow-lg p-3 rounded-5">
                    <div className="row m-0 p-0 w-100 h-100">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-7 px-5 pb-md-5">
                            <div className="d-flex justify-content-center flex-column ">
                                <h1 className="base-font-pop fw-bold pt-2  text-base-color" style={{ fontSize: "3.2rem" }}>Sign Up</h1>
                            </div>
                            <div className="">
                                <form className="d-flex flex-column px-5" onSubmit={handleSubmit(submitform)}>
                                    <div className="d-flex gap-3 mb-2">
                                        <div className="d-flex  flex-column align-items-start w-100 ">
                                            <label htmlFor="username" className="base-font-600 fs-5 text-start text-base-color">
                                                FirstName
                                            </label>
                                            <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                                <SmsNotification size="32" className='text-base-color' />
                                                <input
                                                    className="border-0 rounded-pill  base-font-500 py-2 px-2 fs-4 w-100"
                                                    type="text"
                                                    placeholder="Firstname"
                                                    {...register("firstname", { required: true })}
                                                />
                                            </div>

                                            {errors.firstname && <span className='fs-6 pt-2 text-danger base-font-600'>Firstname is required *</span>}
                                        </div>
                                        <div className="d-flex  flex-column align-items-start w-100">
                                            <label htmlFor="username" className="base-font-600 fs-5 text-start text-base-color">
                                                LastName
                                            </label>
                                            <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                                <SmsNotification size="32" className='text-base-color' />
                                                <input
                                                    className="border-0 rounded-pill  base-font-500 py-2 px-2 fs-4 w-100"
                                                    type="text"
                                                    placeholder="Lastname"
                                                    {...register("lastname", { required: true })}
                                                />
                                            </div>

                                            {errors.lastname && <span className='fs-6 pt-2 text-danger base-font-600'>Lastname is required *</span>}
                                        </div>
                                    </div>
                                    <div className="d-flex  flex-column align-items-start w-100 margin-bottom-0">
                                        <label htmlFor="username" className="base-font-600 fs-5 text-start text-base-color">
                                            Email
                                        </label>
                                        <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                            <SmsNotification size="32" className='text-base-color' />
                                            <input
                                                className="border-0 rounded-pill  base-font-500 py-2 px-2 fs-4 w-100"
                                                type="email"
                                                placeholder="Email"
                                                {...register("email_id", { required: true })}
                                            />
                                        </div>

                                        {errors.email_id && <span className='fs-6 pt-2 text-danger base-font-600'>Email Id is required *</span>}
                                    </div>
                                    <div className="d-flex  flex-column align-items-start w-100 margin-bottom-0">
                                        <label htmlFor="examplePassword" className=" base-font-600 fs-5 text-start text-base-color">
                                            Password *
                                        </label>
                                        <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                            <Key size="32" className='text-base-color' />
                                            <input
                                                className="border-0 rounded-pill base-font-500 py-2 px-2 fs-4 w-100"
                                                placeholder="Password"
                                                type={passwordShown ? "text" : "password"} id='password'
                                                {...register("password", {
                                                    required: "You must specify a password",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password must have at least 8 characters"
                                                    }
                                                })}
                                            />
                                            {passwordShown ?
                                                <Eye size="32" onClick={() => setPasswordShown(!passwordShown)} className='text-base-color' />
                                                :
                                                <EyeSlash size="32" onClick={() => setPasswordShown(!passwordShown)} className='text-base-color' />
                                            }
                                        </div>
                                        {errors.password && <span className='fs-6 pt-2 text-danger base-font-600'>Password is required *</span>}
                                    </div>
                                    <div className="d-flex  flex-column align-items-start w-100 margin-bottom-0">
                                        <label htmlFor="examplePassword" className=" base-font-600 fs-5 text-start text-base-color">
                                            Confirm Password *
                                        </label>
                                        <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                            <Key size="32" className='text-base-color' />
                                            <input
                                                className="border-0 rounded-pill base-font-500 py-2 px-2 fs-4 w-100"
                                                type="password"
                                                {...register("confirm_password", {
                                                    required: true,
                                                    validate: value => value === password.current || "The password do not match"
                                                })}
                                                placeholder="Re-Type New Password "
                                            />
                                        </div>
                                        {errors.confirm_password && <span className='fs-6 pt-2 text-danger base-font-600'>Confirm Password is required *</span>}
                                    </div>
                                    <div className="mt-2">
                                        <input className="border-0 rounded-pill  base-font-pop py-2 px-2 fs-4 w-100 bg-base-color text-white" type="submit" defaultValue="Submit" disabled={btndis} />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                        <Link className='d-flex justify-content-center base-font-700 mt-3 text-decoration-underline' to='/'><span className="">
                                            <ArrowLeft /> Go Back</span></Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col col-md-6 col-lg-6 col-xl-5 p-0 d-flex justify-content-center align-items-center border-left-side">
                            <div className="img-card p-2">
                                <img src={signIn} alt="" className="img-fit" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserRegistration
