import {
  Category2,
  Note,
  ReceiptEdit,
  CalendarTick,
  MenuBoard,
  DocumentCopy,
  User,
  People,
  Element3,
  Personalcard,
  Trade,
  Clock,
} from "iconsax-react";

const SidebarApi = [
  {
    title: "Dashboard",
    icon: <Element3 variant="Bulk" size="28" />,
    link: "/dashboard",
  },
  {
    title: "Projects",
    icon: <Trade variant="Bulk" size="28" />,
    link: "/taskTab",
  },
  {
    title: "Attendance",
    icon: <CalendarTick variant="Bulk" size="28" />,
    link: "/admin-dashboard",
    childs: [
      {
        title: "Attendance",
        icon: <Category2 variant="Bulk" size="28" />,
        link: "/admin-dashboard",
      },
      {
        title: "Leaves",
        icon: <ReceiptEdit variant="Bulk" size="28" />,
        link: "leaves",
      },
      {
        title: "Mispunch",
        icon: <Note variant="Bulk" size="28" />,
        link: "/mispunch",
      },
      {
        title: "Break Log",
        icon: <Clock variant="Bulk" size="24" />,
        link: "/breaklog",
      },
    ],
  },
  {
    title: "Employee",
    icon: <User variant="Bulk" size="28" />,
    link: "/addemployee",
    childs: [
      {
        title: "Employee List",
        icon: <Category2 variant="Bulk" size="28" />,
        link: "/addemployee",
      },
      // {
      //   title: "Removed Employees",
      //   icon: <Category2 variant="Bulk" size="28" />,
      //   link: "/removeemployee",
      // },
      {
        title: "Basic Details",
        icon: <Personalcard variant="Bulk" size="28" />,
        link: "/basicdetails",
      },
    ],
  },
  {
    title: "Documents",
    icon: <DocumentCopy variant="Bulk" size="28" />,
    link: "/document",
    childs: [
      {
        title: "Documents",
        icon: <Category2 variant="Bulk" size="28" />,
        link: "/document",
      },
      {
        title: "Joining Letters",
        icon: <ReceiptEdit variant="Bulk" size="28" />,
        link: "/joining",
      },
      {
        title: "Salary Slips",
        icon: <ReceiptEdit variant="Bulk" size="28" />,
        link: "/salaryslip",
      },
    ],
  },
  {
    title: "Holidays",
    icon: <MenuBoard variant="Bulk" size="28" />,
    link: "/holidays",
  },
  {
    title: "Meetings",
    icon: <People variant="Bulk" size="28" />,
    link: "/meeting",
  },
];

export default SidebarApi;
