import React, { useState, useRef, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Card, Select } from "@mui/material";
import Favicon from "../../image/Favicon.png";
import { DocumentCloud, DocumentUpload, Trash } from "iconsax-react";
interface UserData {
  firstname: any;
  lastname: any;
}

const AddSalarySlip = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState();

  const toggle = () => {
    setModal(!modal);
  };

  const handleOnChange = (e: any) => {
    // const newdata: any = { ...data };
    const newdata: any = { data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };
  return (
    <>
      <Button
        className="tb-btn base-font-muli fw-bold py-2 px-3 my-2 mx-3"
        onClick={toggle}
      >
        Add Salary Slip
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg" centered>
        <div className="d-flex justify-content-between align-items-center gap-3 border-bottom p-4">
          <div className="img-box-fit-pro shadow-lg rounded-circle">
            <img className="img-fit" src={Favicon} />
          </div>
          <div>
            <span className="fs-2 mb-0 text-base-color fw-bold  base-font-pop text-capitalize">
              TECHIEBEARS PRIVATE LIMITED
            </span>
            <p className="fs-6 mb-0  base-font-500 text-capitalize">
              1458/0,R.No.103, 1st Floor, Shivram Nagar, NR Achal Guru Temple,
              Kamatghar, Bhiwandi, Thane, Maharashtra - 421302
            </p>
          </div>
        </div>

        <ModalBody>
          <form>
            <div className="row px-5">
              <div className="col-6">
                <Label className="BDLabels base-font-muli fw-bold fs-4  ">
                  Payslip for the month
                </Label>
                <Input
                  className="py-1 fs-5 shadow-none border"
                  type="number"
                  id="month"
                  placeholder="10000 ₹"
                  name="month"
                  onChange={handleOnChange}
                ></Input>
              </div>
              <div className="col-6">
                <Label className="BDLabels base-font-muli fw-bold fs-4  ">
                  Basic
                </Label>
                <Input
                  className="py-1 fs-5 shadow-none border"
                  type="text"
                  id="user"
                  name="user"
                  onChange={handleOnChange}
                  placeholder="Basic"
                ></Input>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="border-0 bg-transparent">
          <div className="d-flex justify-content-end gap-3">
            <button className="update-btn" onClick={toggle}>
              Send
              <DocumentUpload size="28" className="ps-1 pb-1" variant="Bulk" />
            </button>
            <button
              className="close-btn"
              onClick={() => {
                toggle();
              }}
            >
              Cancel
              <Trash size="24" className="ps-1 pb-1" variant="Bulk" />
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddSalarySlip;
