import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const HolidaysInfoView = ({ modalBox, setModalBox, selectedinfo }: any) => {
    const toggle = () => setModalBox(!modalBox);

    return (
        <div>
            <Modal isOpen={modalBox} toggle={toggle} centered className='modalstyle' size='md' >
                <ModalHeader className='border-0'></ModalHeader>
                <ModalBody className='d-flex justify-content-center align-items-center'>
                    <div className=''>
                        <h2 className="fs-2 text-base-color base-font-pop fw-semibold text-center pb-3 ">{selectedinfo}</h2>
                    </div>


                </ModalBody>
                <ModalFooter className='border-0 justify-content-center'>
                    <button onClick={toggle} className="close-btn">
                        Close
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default HolidaysInfoView;