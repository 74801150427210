export const LOGIN = "LOGIN";
export const LOGGEDUSER = "LOGGEDUSER";
export const EMPLOYEES = "EMPLOYEES";
export const FULLATTENDANCE = "FULLATTENDANCE";
export const ALLLEAVES = "ALLLEAVES";
export const ATTREMARK = "ATTREMARK";
export const EDUCATIONAL_DETAILS = "EDUCATIONAL_DETAILS";
export const MISPUNCH = "MISPUNCH";
export const EXPERIENCE_DETAILS = "EXPERIENCE_DETAILS";
export const BASIC_DETAILS = "BASIC_DETAILS"
export const DETAILS = "DETAILS"
export const HOLIDAYS = "HOLIDAYS"
export const PROJECT = "PROJECT"
export const TEAMS = "TEAMS"
export const TEAMMEMBER = "TEAMMEMBER"
export const TASKS = "TASKS"
export const BREAKLOG = "BREAKLOG"