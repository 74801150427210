import DataTable, { TableColumn, } from 'react-data-table-component';
import React, { useState, useEffect, useMemo } from 'react'
import './Table.css'
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
// import CustomLoader from '../errorComponents/CustomLoader';
interface DataRow {
    status: any;
    title: string;
    director: string;
    year: string;
    buildingNo: any;
    galaNo: any;
    contactNo: any;
    area: any;
    tenant: any;
}
const customStyles = {
    rows: {
        style: {
            minHeight: '68px',
        },
    },
    headCells: {
        style: {
            fontSize: '5rem',

        },
    },
    cells: {
        style: {
            paddingLeft: '20px',
            paddingRight: '20px',
            color: 'gray'
        },
    },
    contextMenu: {
        style: {
            fontSize: '18px',
            fontWeight: 400,
            paddingLeft: '16px',
            paddingRight: '8px',
            transform: 'translate3d(0, -100%, 0)',
            transitionDuration: '125ms',
            transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
            willChange: 'transform',
            backgroundColor: 'red'
        },
        activeStyle: {
            transform: 'translate3d(0, 0, 0)',
        },
    },
};

const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    noRowsPerPage: false,
    paginationPerPage: 5,
};

const Table = ({ columns, data, from }: any) => {
    let breaklog = useSelector((state: any) => state?.root?.reducers?.breaklog);

    const [pending, setPending] = useState(true);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);

    function convertArrayOfObjectsToCSV(array: any) {
        let result: any;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item: any) => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result
    }

    function downloadCSV(array: any) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    // const Export = ({ onExport: any }) => <Button onClick={() => onExport(e.target.value)} className="fs-3 py-3 px-5  bg-base-color text-base-white border-0 btn btn-secondary">Export CSV</Button>;
    const Export = ({ onExport }: { onExport: () => void }) => (
        (
            <button className='btn btn-outline-info btn-lg' onClick={() => onExport()} >Export</button>
        )
    )
    const actionsMemo = React.useMemo(() => <Export onExport={() => { if (from == "attendance") { console.log("12222222222222",breaklog);downloadCSV(breaklog) } else { downloadCSV(data) } }} />, []);

    return (
        <>
            {data != false &&
                <DataTable
                    columns={columns} data={data}
                    customStyles={customStyles}
                    highlightOnHover
                    pagination
                    paginationPerPage={10}
                    progressPending={pending}
                    // progressComponent={<CustomLoader />}
                    paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Records per page:',
                        rangeSeparatorText: 'out of',
                    }}
                    actions={actionsMemo}
                />
            }
        </>
    );
}

export default Table;