import axios from "axios";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import swal from "sweetalert";
import { Edit2 } from "iconsax-react";
import { useSelector } from "react-redux";
import { environment } from "../../env";
import { useDispatch } from "react-redux";
import { getTeamMembersData } from "../../Redux/actions";
import ErrorComponents from "../errorComponents/ErrorComponents";

const TeamMembar = (props: any) => {

  const allUsers = useSelector((state: any) => state?.root?.reducers?.allEmployees);
  const teams = useSelector((state: any) => state?.root?.reducers?.teams);

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [editt, setEditt] = useState(false);
  const [ID, setID] = useState();
  const toggle = () => {
    setModal(!modal);
    reset();
    setEditt(false)
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const getTeamMembers = () => {
    axios.get(`${environment.baseUrl}create-team-member`)
      .then(res => {
        dispatch(getTeamMembersData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const onSubmit = async (value: any) => {
    if (value.team_lead == true) { value.team_lead = 1 } else { value.team_lead = 0 }
    try {
      await axios
        .post(`${environment.baseUrl}create-team-member`, value)
        .then(async (form) => {
          if (form.data.code === 2000) {
            swal({
              title: "Success",
              text: "Team Member Added Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            toggle();
            getTeamMembers();
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const Edit = async (value: any) => {
    if (value.team_lead == true) { value.team_lead = 1 } else { value.team_lead = 0 }
    try {
      await axios
        .put(`${environment.baseUrl}edit-team-member/${ID}`, value)
        .then(async (form) => {
          if (form.data.code === 2000) {
            swal({
              title: "Success",
              text: "Team Member Edited Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            toggle();
            getTeamMembers();
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useMemo(() => {
    setID(props?.data?.id)
    reset({
      "team": props?.data?.team?.id,
      "team_member_name": props?.data?.team_member_name?.id,
      "role": props?.data?.role
    })
    if (props?.data?.team_lead == 0) {
      setValue("team_lead", false)
    } else {
      setValue("team_lead", true)
    }
  }, [editt])

  return (
    <>
      {props.button != 'edit' ?
        <button
          onClick={() => {
            toggle();
          }}
          className="submit-btn px-3"
        >
          Add Members
        </button>
        :
        <Edit2 className=" cursor text-warning" onClick={() => {
          toggle();
          setEditt(true)
        }} />
      }
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="md"
        className="mispunch-modal"
      >
        <ModalHeader toggle={toggle} className="py-3 px-3">
          <span className="base-font-muli fs-4 text-base-color fw-bold px-3 ">
            {props.button != "edit" ? "Add New Team Member" : "Edit Team Member"}
          </span>
        </ModalHeader>

        <ModalBody className="p-4">
          <form
            onSubmit={editt == false ? handleSubmit(onSubmit) : handleSubmit(Edit)}
            className="inner-login"
            encType="multipart/form-data"
          >
            <div className="row d-flex">
              <div className=" col-12">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Team Title
                  </Label>
                  <select className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    style={{ fontSize: "1rem" }}
                    {...register("team", { required: true })}
                  >
                    <option value="" className='attoptions'>Team Name...</option>
                    {teams.map((user: any, i: any) => {
                      return <option className='attoptions' value={user.id} key={i}>{user.team_name}  </option>;
                    })}
                  </select>
                  {errors.team && <ErrorComponents error={"Team Name is required *"} />}
                </div>
              </div>
              <div className=" col-12">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Member Name
                  </Label>
                  <select className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    style={{ fontSize: "1rem" }}
                    {...register("team_member_name", { required: true })}
                  >
                    <option value="" className='attoptions'>Employee Name...</option>
                    {allUsers.map((user: any, i: any) => {
                      return <option className='attoptions' value={user.id} key={i}> {user.firstname} {user.lastname}  </option>;
                    })}
                  </select>
                  {errors.team_member_name && <ErrorComponents error={"Team Member Name is required *"} />}
                </div>
              </div>
              <div className=" col-12">
                <div className="p-2 d-flex justify-content-between align-items-center">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Team Leader
                  </Label>
                  <label className="switchbtn m-0 pt-3">
                    <input type="checkbox" {...register("team_lead")} />
                    <span className="sliderBox"></span>
                  </label>
                </div>
              </div>
              <div className=" col-12">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Role (Developer)
                  </Label>
                  <select className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    style={{ fontSize: "1rem" }}
                    {...register("role", { required: true })}
                  >
                    <option value="">Role</option>
                    <option value="Tester">Tester</option>
                    <option value="Trainee">Trainee</option>
                    <option value="Frontend developer">Frontend developer</option>
                    <option value="Backend developer">Backend developer</option>
                    <option value="Fullstack Developer">Fullstack Developer</option>
                  </select>
                  {errors.role && <ErrorComponents error={"Role is required *"} />}
                </div>
              </div>
            </div>
            <ModalFooter className="border-0 pb-0">
              <button type="submit" className="submit-btn">
                submit
              </button>
              <button onClick={toggle} type="button" className="close-btn">
                Close
              </button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TeamMembar;
