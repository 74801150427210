import userImg from '../../image/user-demo.jpeg'
import { environment } from '../../env';
import EmpDetailsModal from './EmpDetailsModal';
import { Star1 } from 'iconsax-react';

const EmployeeCard = ({ curElms }: any) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-3 pt-5 pb-2 flex-column cards bg-white  rounded-3" key={curElms?.employee_id}>
      <div className="userProfile-images ">
        <div className="userBoxs">
          <img src={curElms?.profile_pic == "null" ? 'https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/default.jpg' : curElms?.profile_pic} alt="user-img" className='user-image-fits' />
        </div>
      </div>
      <div className="text-center d-flex flex-column align-items-center">
        <label className='base-font-600 base-font-pop fs-6  text-capitalize mb-0 card-buttons'>{curElms?.employee_id}</label>
        <h5 className='base-font-muli fw-bold text-nowrap text-capitalize fs-4  text-dark'>{curElms?.firstname} {curElms?.lastname} {curElms?.team_lead == '-1' && <Star1 size="20" color="#0047AB" variant='Bold' />}</h5>
        <label className='base-font-500 base-font-pop  text-nowrap text-capitalize mb-0'>Employee</label>
        <EmpDetailsModal {...curElms} />
      </div>
    </div>
  )
}

export default EmployeeCard