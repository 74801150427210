import { LOGIN, EDUCATIONAL_DETAILS, EXPERIENCE_DETAILS, BASIC_DETAILS, DETAILS, LOGGEDUSER, EMPLOYEES, FULLATTENDANCE, ATTREMARK, ALLLEAVES, MISPUNCH, HOLIDAYS,PROJECT,TASKS,TEAMS, TEAMMEMBER, BREAKLOG } from "./types"
export const loginAction = (data: any) => {
    return {
        data: data,
        type: LOGIN
    }
};

export const educationalDetailsAction = (data: any) => {
    return {
        data: data,
        type: EDUCATIONAL_DETAILS
    }
};

export const experiencelDetailsAction = (data: any) => {
    return {
        data: data,
        type: EXPERIENCE_DETAILS
    }
}

export const basicDetailsAction = (data: any) => {
    return {
        data: data,
        type: BASIC_DETAILS
    }
}

export const DetailsAction = (data: boolean) => {
    return {
        data: data,
        type: DETAILS
    }
}
export const getLoggedUserAction = (data: object) => {
    return {
        data: data,
        type: LOGGEDUSER
    }
}
export const getAllEmployees = (data: object) => {
    return {
        data: data,
        type: EMPLOYEES
    }
}
export const getFullAttData = (data: object) => {
    return {
        data: data,
        type: FULLATTENDANCE
    }
}
export const getRemarks = (data: object) => {
    return {
        data: data,
        type: ATTREMARK
    }
}
export const getLeaves = (data: object) => {
    return {
        data: data,
        type: ALLLEAVES
    }
}
export const getMispunchAction = (data: object) => {
    return {
        data: data,
        type: MISPUNCH
    }
}
export const getHolidaysData = (data: object) => {
    return {
        data: data,
        type: HOLIDAYS
    }
}
export const getProjectData = (data: object) => {
    return {
        data: data,
        type: PROJECT
    }
}
export const getTeamsData = (data: object) => {
    return {
        data: data,
        type: TEAMS
    }
}
export const getTasksData = (data: object) => {
    return {
        data: data,
        type: TASKS
    }
}
export const getTeamMembersData = (data: object) => {
    return {
        data: data,
        type: TEAMMEMBER
    }
}
export const getBreakLogData = (data: object) => {
    return {
        data: data,
        type: BREAKLOG
    }
}