import axios from 'axios';
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
} from 'reactstrap';
import { environment } from '../../env';
import swal from "sweetalert";
import moment from 'moment';
import { getFullAttData, getRemarks } from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Edit2 } from 'iconsax-react';

const EditAttendanceModal = (props: any) => {

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    var today = new Date();
    const month = moment().format('MM');
    const year = moment().format('YYYY');
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const remarks = useSelector((state: any) => state?.root?.reducers?.remark);

    const [modal, setModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const toggle = () => { setModal(!modal) };

    const AllEmployeeAttendance = async () => {
        var URL = ""
        if (moment(today).format("DD") == "01") {
            var monthe = Number(month) - 1
            URL = `${environment.baseUrl}attendance-filter?employee_id=&date_after=${year}-${monthe}-01&date_before=${year}-${month}-${moment(lastDayOfMonth).format('DD')}`
        } else {
            URL = `${environment.baseUrl}attendance-filter?employee_id=&date_after=${year}-${month}-01&date_before=${year}-${month}-${moment(lastDayOfMonth).format('DD')}`
        } const result = await axios.get(URL)
        dispatch(getFullAttData(result.data))
        const unique = Array.from(new Set(result.data.map((item: any) => item.remark)));
        dispatch(getRemarks(unique))
    }

    const ProfilePostMethod = async (profileData: any) => {
        let form_data = new FormData();
        if (profileData.login_time != '') {
            let login_time = moment(profileData.login_time, 'HH:mm').format().replace("T", " ");
            form_data.append('login_time', login_time)
        }
        if (profileData.logout_time != '') {
            let logout_time = moment(profileData.logout_time, 'HH:mm').format().replace("T", " ");
            form_data.append('logout_time', logout_time)
        }
        if (profileData.remark != '') {
            form_data.append('remark', profileData.remark)
        }
        if (profileData.comment != '') {
            form_data.append('comment', profileData.comment)
        }
        form_data.append('employee_id', props.details.employee_id)

        try {
            const res = await axios.put(`${environment.baseUrl}update-employee-attendance/${props.details.id}`, form_data, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            return res.data
        }
        catch (err) {
            console.log("err");
            return err;

        }
    };

    const onSubmit = async (profileData: any,) => {
        ProfilePostMethod(profileData).then((profileResponse) => {
            if (profileResponse.status === 'success') {
                setEdit(false)
                AllEmployeeAttendance()
                setModal(!modal)
                swal({
                    title: "Success",
                    text: 'Profile is updated successfully',
                    icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                })
                    .then(() => {

                        props.close();
                    })
            }
        });

    }

    useMemo(() => {
        reset({
            'comment': props.details.comment,
            "remark": props.details.remark,
            "logout_time": moment(props.details.logout_time).format('hh:mm'),
            "login_time": moment(props.details.login_time).format('hh:mm')
        })
    }, [edit])

    return (

        <>
            <Form encType="multipart/form-data">
                <div className='edit-attendance'>
                    <Link className='text-dark text-decoration-none' to='' onClick={() => { toggle(); setEdit(true) }}><Edit2 size="32" className='text-base-color' /> </Link>
                </div>
            </Form>
            <Modal isOpen={modal} toggle={toggle} centered className='modalstyle' size='lg'>
                <ModalHeader className="py-3 px-4"><span className='base-font-muli fs-4 text-base-color fw-bold '>Edit Attendance</span></ModalHeader>
                <form onSubmit={handleSubmit(onSubmit)} className="inner-login" encType="multipart/form-data" >

                    <ModalBody className='py-3 px-4'>
                        <div className="d-flex justify-content gap-1 flex-column">
                            <div className='row mb-3'>
                                <div className="col-6">
                                    <Label for='timein' className="text-black base-font-muli fw-bold fs-5">
                                        Time In
                                    </Label>
                                    <Input
                                        className='py-1 fs-6 shadow-none input-border w-100'
                                        id="timein"
                                        type="time"
                                        {...register("login_time")}
                                    />
                                </div>
                                <div className="col-6">
                                    <Label for='timeout' className="text-black base-font-muli fw-bold fs-5">
                                        Time Out
                                    </Label>
                                    <Input
                                        className='py-1 fs-6 shadow-none input-border w-100'
                                        id="timeout"
                                        type="time"
                                        {...register("logout_time")}
                                    /></div>
                                <div className='col-6 pt-4'>
                                    <Label for='comment' className="text-black base-font-muli fw-bold fs-5">
                                        Comment
                                    </Label>

                                    <textarea
                                        className='py-1 px-1  base-font-pop w-100  input-textarea shadow-none border-primary rounded-2'
                                        style={{ outline: "none" }}
                                        id="comment"
                                        placeholder="Comment"
                                        {...register("comment")}
                                    />
                                </div>
                                <div className="col-6 pt-4">
                                    <div className="">
                                        <Label htmlFor="doc_type" className="text-black base-font-muli fw-bold fs-5">
                                            Remark :
                                        </Label>
                                        {props.mispunch != true ? <select id="leave_type" className="border-primary p-2 rounded-2 base-font-pop fw-normal w-100"
                                            {...register("remark", { onChange: (e) => { onSubmit(e.target.value) } })}
                                        >
                                            <option value="" className='attoptions'>Remark ...</option>
                                            {remarks?.map((user: any, i: any) => {
                                                return <option className='attoptions' value={user} key={i}>{user}</option>;
                                            })}
                                        </select> :
                                            <select id="leave_type" className="border-primary p-2 rounded-2 base-font-pop fw-normal w-100"
                                                {...register("remark", { onChange: (e) => { onSubmit(e.target.value) } })}
                                            >
                                                <option value="pending" disabled className='attoptions'>Pending</option>
                                                <option value="Approved" className='attoptions' >Approve</option>
                                                <option value="Denied" className='attoptions' >Deny</option>
                                            </select>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0'>
                        <button className="submit-btn">
                            Submit
                        </button>
                        <button type={'button'} onClick={() => { toggle() }} className="close-btn">
                            Cancel
                        </button>
                    </ModalFooter>
                </form>
            </Modal >
        </>
    )
}

export default EditAttendanceModal

