
import React from 'react'
import { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux"
import EmptyTable from '../../errorComponents/EmptyTable';
import Table from '../../../Table/Table';

interface UploadDocumentData {
    employee_id: any,
    doc_info: any,
    created_date: any,
    file: any
}

const Education = (props: any) => {

    const educationdetails = useSelector((state: any) => state?.root?.reducers?.educationalDetails);

    const ExamplePDFViewer = (file: any) => {
        window.open(`${file}`);
    };

    const columns: TableColumn<UploadDocumentData>[] = [
        {
            name: 'EmpId',
            selector: row => row.employee_id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Document Name',
            selector: row => row.doc_info,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Upload Date',
            selector: row => moment(row.created_date).format('DD/MM/YYYY'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'View File',
            selector: row => <Link onClick={() => ExamplePDFViewer(row.file)} to=''>View</Link>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }
    ];

    return (
        < >
            <section className="main-wrapper-section ">
                <div className=" py-3 px-5 d-flex gap-5 flex-column">
                    <div className="">
                        <div className='d-flex align-items-center justify-content-between pb-4'>
                            <h4 className='fs-2 mb-0 text-base-color fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                                Employee Documents</h4>
                        </div>
                        {educationdetails ? <Table columns={columns} data={educationdetails} /> : <EmptyTable tabletext="Employees Documents" />}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Education
