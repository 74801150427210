import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
} from 'reactstrap';
import { environment } from '../env';
import swal from "sweetalert";
import moment from 'moment';
import Select from 'react-select';

const EditMispunch = (props: any) => {
    var option = [
        { value: "Approved", label: "Approve" },
        { value: "Denied", label: "Deny" },
    ]
    const [modal, setModal] = useState(false);
    const [status, setStatus] = useState(option);
    const [userDetail, setUserDetail] = useState(
        {
            login_time: props.details.login_time,
            logout_time: props.details.logout_time,
            remark: props.details.remark,
            comment: props.details.comment,
            employee_id: props.details.employee_id
        }

    );

    const toggle = () => { setModal(!modal) };

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const handleOnChange = (event: any) => {

        setUserDetail({
            ...userDetail,
            [event.target.id]: event.target.value
        });
    }

    const handle = (e: any) => {
        setStatus(e.value)
    }

    const onSubmit = async (profileData: any,) => {
        profileData.remark = status
        profileData.id = props.details.id
        profileData.date = props.details.date
        console.log(profileData,"dafasdfasdfsadf")
        await axios.post(`${environment.baseUrl}update-remark/`,profileData).then((profileResponse) => {
            if (profileResponse.data.msg === "Done") {
                swal({
                    title: "Success",
                    text: 'Profile is updated successfully',
                    icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                })
                    .then(() => {
                        toggle(); 
                        props.update()
                    })
            }
        });

    }
    return (
        <>
            <button
                onClick={() => {
                    toggle();
                }}
                className="icon-bg-yellow border-0 p-3 rounded width-50"
            >
                <i className="fa-solid fa-pen icon-color-yellow fs-4"></i>
            </button>
            <Modal isOpen={modal}
                toggle={toggle}
                centered
                size='md'
                className='mispunch-modal'>
                <ModalHeader toggle={toggle} className="py-3 px-3"><span className='base-font-muli fs-4 text-base-color fw-bold px-3 '>Edit Mispunch</span></ModalHeader>


                <ModalBody
                    className='p-4'
                >
                    <form onSubmit={handleSubmit(onSubmit)} className="inner-login" encType="multipart/form-data" >
                        <div className=" col-12">
                            <div className="p-2">
                                <Select
                                    onChange={handle}
                                    options={option}
                                    className='fs-5 text-black fw-bold' />
                            </div>
                        </div>
                        <ModalFooter className='border-0 pb-0'>
                            <button type="submit"
                                className="submit-btn">
                                submit
                            </button>
                            <button onClick={toggle} className="close-btn">
                                Close
                            </button>
                        </ModalFooter>
                    </form>
                </ModalBody >
            </Modal >
        </>
    )
}

export default EditMispunch
