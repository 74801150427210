import React from "react";
import "./basicdetails.css";
import {
  Card,
  CardBody,
  Form,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";

const FamilyDetails = (props: any) => {

  const basicdetails = useSelector(
    (state: any) => state?.root?.reducers?.basicDetails
  );


  return (
    <div>
      <Card className='mt-3 mx-3 mb-2 px-3 border-0 position-relative'>
        <div className=''>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 className='fs-2 mb-0 text-base-color fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
              Employee Family Details</h4>
          </div>
        </div>
        <CardBody className='p-0 pt-4 pb-2'>

          <Form
            id="details" >
            <div className='row px-3'
            >
              <div className="col-4">
                <Label className='BDLabels base-font-muli fs-5 '>
                  Father's Name
                </Label>
                <Label className='BDLabelInput'>
                  {basicdetails.father_name == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.father_name}
                </Label>
              </div>
              <div className="col-4">
                <Label className='BDLabels base-font-muli fs-5 '>
                  Father's Occupation
                </Label>

                <Label className='BDLabelInput'>
                  {basicdetails.father_occupation == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.father_occupation}
                </Label>
              </div>
              <div className="col-4">
                <Label className='BDLabels base-font-muli fs-5 '>

                  Father's Contact Number
                </Label>

                <Label className='BDLabelInput'>
                  {basicdetails.father_contact == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.father_contact}
                </Label>
              </div>
              <div className="col-4" >
                <Label className='BDLabels base-font-muli fs-5 '>
                  Mother's Name
                </Label>

                <Label className='BDLabelInput'>
                  {basicdetails.mother_name == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.mother_name}
                </Label>
              </div>
              <div className="col-4">
                <Label className='BDLabels base-font-muli fs-5 '>
                  Mother's Occupation
                </Label>

                <Label className='BDLabelInput'>
                  {basicdetails.mother_occupation == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.mother_occupation}
                </Label>
              </div>
              <div className="col-4">
                <Label className='BDLabels base-font-muli fs-5 '>
                  Mother's Contact
                </Label>

                <Label className='BDLabelInput'>
                  {basicdetails.mother_contact == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.mother_contact}
                </Label>
              </div>
            </div>
            <div className='card mt-3 border-0 '>
              <Label className='BDLabels pl-3 base-font-muli fs-5'>
                Your Maritial Status
              </Label>
              <Label className='BDLabelInput border-bottom pl-3'>
                {basicdetails.maritial_status == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.maritial_status}
              </Label>
              {basicdetails.maritial_status === "UnMarried" || basicdetails.maritial_status === undefined || basicdetails.maritial_status === "" ?
                <>
                </> :
                <>
                  <div className='row px-3'>
                    <div className="col-4" >
                      <Label className='BDLabels base-font-muli  fs-5 '>
                        Spouse Name
                      </Label>

                      <Label className='BDLabelInput'>
                        {basicdetails.spouse_name == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.spouse_name}
                      </Label>
                    </div>
                    <div className="col-4">
                      <Label className='BDLabels base-font-muli  fs-5 '>
                        Spouse Occupation
                      </Label>

                      <Label className='BDLabelInput'>
                        {basicdetails.spouse_ocupation == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.spouse_ocupation}
                      </Label>
                    </div>
                    <div className="col-4">
                      <Label className='BDLabels base-font-muli  fs-5 '>
                        Number Of Children
                      </Label>

                      <Label className='BDLabelInput'>
                        {basicdetails.number_of_children == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.number_of_children}
                      </Label>
                    </div>
                    <div className="col-4" >
                      <Label className='BDLabels base-font-muli  fs-5 '>
                        Spouse DOB
                      </Label>

                      <Label className='BDLabelInput'>
                        {basicdetails.spouse_dob == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.spouse_dob}
                      </Label>
                    </div>
                    <div className="col-4">
                      <Label className='BDLabels base-font-muli  fs-5 '>
                        Spouse Contact Number
                      </Label>

                      <Label className='BDLabelInput'>
                        {basicdetails.spouse_contact_no == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.spouse_contact_no}
                      </Label>
                    </div>
                  </div>
                </>
              }
            </div>
          </Form>
        </CardBody>
      </Card >
    </div>
  );
};

export default FamilyDetails;
