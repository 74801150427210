// import '../AddEmployee/EmpDetailsModal.css'
import "font-awesome/css/font-awesome.min.css";
import React, { useState, useRef, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import defaultimage from "../../image/DefaultProfileLogo.png";
import Select from 'react-select'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { UserAdd } from "iconsax-react";
import { environment } from "../../env";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../Redux/actions";
import { BsExclamationSquareFill } from "react-icons/bs";



const EmpDetailsModal = (props: any) => {
  const allUsers = useSelector((state: any) => state?.root?.reducers?.allEmployees);
  const [modal, setModal] = useState(false);
  var option = [
    { value: "Approved", label: "Approve" },
    { value: "Denied", label: "Deny" },
    { value: "Cancelled", label: "Cancel" }
  ]
  const handle = (e: any) => {
    console.log("Hello");

  }
  const toggle = () => {
    setModal(!modal);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const dispatch = useDispatch();

  const getAllUsers = async () => {
    try {
      const res = await axios.get(`${environment.baseUrl}all-users-details/`);
      if (res.data) {
        console.log("sdfsadfsadfsadf", res.data.length)
        dispatch(
          getAllEmployees(
            res.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
          )
        );
      }
    } catch (err) {
      console.log("err");
    }
  };

  const Remove = () => {
    // axios.post('url', {
    axios
      .put(`${environment.baseUrl}update-basic-details/${props.employee_id}`, { "status": 0 })
      .then((res) => {
        if (res.status == 200) {
          swal({
            title: "Success",
            text: "Removed User From Active!",
            icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
          });
          getAllUsers()
        }
      })
      .catch((res) => console.log("error"));
  }

  const ProfilePostMethod = async (e: any) => {
    console.log(e);
    
    let form_data = new FormData();
    if (e == 'tl') {
      form_data.append('team_lead', "-1")
      form_data.append('team', "1")
    }
    else if (e == 'rm') {
      form_data.append('team_lead', "0"),
      form_data.append('team', '0')
    }
    else {
      form_data.append('team_lead', e)
      form_data.append('team', '1')
    }

    try {
      const res = await axios.put(`${environment.baseUrl}update-user-details/${props.employee_id}`, form_data);
      return res.data
    }
    catch (err) {
      console.log("err");
      return err;
    }
    return
  };

  return (
    <>
      {
        props.buttons == 'DashBoard' ? <button className="btns" onClick={toggle}><span></span><p data-start="good luck!" data-text="Profile" data-title="View" className='fs-6 base-font-700'></p></button> :
          <button className="card-buttons-box rounded-2 base-font-inter mb-1 mt-3 border-0 fs-6" onClick={toggle}>More info</button>
      }

      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size='xl'
        className='mispunch-modal'
      >
        <ModalHeader toggle={toggle} className="py-3 px-3 display-modal">
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className='base-font-muli fs-4 text-base-color fw-bold px-3 '> {props.firstname} {props.lastname} Basic Details</span>
            {
              props.buttons != 'DashBoard' && <div className='edit-attendance'>
                <button className=' close-btn px-4 py-3 ' onClick={() => { Remove() }} >Remove Employee</button>

              </div>
            }

          </div>

        </ModalHeader>

        <ModalBody
          className='p-4'
        >
          <div className="row ">
            <div className="col-lg-3">
              <div className="row g-3">
                <div className=" col-12 d-flex justify-content-center align-content-center pb-3">
                  <div className="BD-profile-img d-flex justify-content-center">
                    <img
                      className="BD-profile-img img"
                      src={props.buttons !== 'DashBoard' ? `${props?.profile_pic ? props?.profile_pic : `https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/default.jpg`}`
                        :
                        `${props?.profile_pic ? props?.profile_pic : defaultimage}`
                      }
                      alt="profile image"
                    />
                  </div>
                </div>
                <div className="mt-lg-0 d-lg-block d-sm-flex mt-md-0 mb-md-3 p-md-0">
                  <div className=" col-lg-12  col-md-4  mb-lg-4">
                    <div className="d-flex flex-column">
                      <label className="BD-labels mb-2 base-font-600 fs-6 base-font-600">
                        Name
                      </label>
                      <label className="fs-6 label-inputs">
                        {props.firstname} {props.lastname}
                      </label>
                    </div>
                  </div>
                  <div className=" col-lg-12 col-md-4  mb-lg-4">
                    <div className="d-flex flex-column">
                      <label className="BD-labels mb-2 base-font-600 fs-6 base-font-600">
                        Email
                      </label>
                      <label className="fs-6 label-inputs ">
                        {props.email_id}
                      </label>
                    </div>
                  </div>
                  <div className=" col-lg-12 col-md-4  mb-lg-4">
                    <div className="d-flex flex-column">
                      <label className="BD-labels mb-2 base-font-600 fs-6 base-font-600">
                        Password
                      </label>
                      <label className="fs-6 label-inputs ">
                        {props.vpass == '0' ? 'Insta@1234' : props.vpass}
                      </label>
                    </div>
                  </div>
                  <div className=" col-lg-12  col-md-4 ">
                    <div className="d-flex flex-column">
                      <label className="BD-labels mb-2 base-font-600 fs-6 base-font-600">
                        Contact Number
                      </label>
                      <label className="fs-6 label-inputs fs-5">
                        {props.phone_no}
                      </label>
                    </div>
                  </div>
                  {/* <div className="Designation col-12">
                                <div className="d-flex flex-column">
                                    <label className='BD-labels mb-2 base-font-600 fs-6 base-font-600' >Designation</label>
                                    <label className='fs-6 label-inputs fs-5' >null</label>
                                </div>
                            </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row g-3">
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      First Name
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.firstname}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Last Name{" "}
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.lastname}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600 pb-1">
                      Contact Number
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.phone_no}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600 pb-1">
                      Pan Number
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.pan}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600 pb-1">
                      Aadhar Number
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.aadhar}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600 pb-1">
                      Blood Group
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.blood_group}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Gender
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.gender}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Date Of Birth
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.date_of_birth}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Emergency Contact Name
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.emg_contact_name}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Relation with Emergency Contact
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.relationship_with_emg_contact}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Emergency Contact Number
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.emg_contact_number}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Email
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.personal_email}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Date of Joining
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.date_of_joining}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      {" "}
                      Reporting Manager
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.reporting_manager}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      {" "}
                      Department
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.department}
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Designation
                    </label>
                    <label className="fs-6 label-inputs ">
                      {props.designation}
                    </label>
                  </div>
                </div>
                {props.team_lead != '-1' && <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="d-flex flex-column">
                    <label className="BD-labels mb-2 base-font-600">
                      Team Lead
                    </label>
                    <select className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                      style={{ fontSize: "1rem" }}
                      {...register("employee_id", {
                        onChange: (e) => {
                          ProfilePostMethod(e.target.value)
                        }
                      })}
                    >
                      <option value=" " className='attoptions'>Emp Name...</option>
                      <option value="tl" className='attoptions'>Make TeamLeader</option>
                      <option value="rm" className='attoptions'>Remove From Team</option>
                      {allUsers.reverse().map((user: any, i: any) => {
                        if (user.team_lead == "-1") {
                          return <option className='attoptions' value={user.employee_id} selected={user.employee_id == props.team_lead } key={i}>{user.firstname} {user.lastname}  </option>;
                        }
                      })}
                    </select>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='border-0  px-5 pb-3'>
          <button onClick={toggle} className="close-btn">
            Close
          </button>
        </ModalFooter>
      </Modal >
    </>
  );
};

export default EmpDetailsModal;
