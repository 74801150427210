import {
  Convertshape,
  Cup,
  Eye,
  FlashCircle,
  HeartCircle,
  InfoCircle,
  NoteRemove,
  Signpost,
  TickCircle,
} from "iconsax-react";
import React, { useEffect, useState } from "react";
import userImages from "../../../image/user-demo.jpeg";
import { TableColumn } from "react-data-table-component";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Table from "../../../Table/Table";
import AddTaskModal from "../../Models/AddTaskModal";
import TeamMembar from "../../Models/TeamMembar";
import { useSelector } from "react-redux";
import moment from 'moment';
import axios from "axios";
import { environment } from "../../../env";
import swal from 'sweetalert';
import EmptyTable from "../../errorComponents/EmptyTable";
interface DataRow {
  status: string;
  due_date: any;
  priority: any;
  assigned_by: any;
  type: any;
  assigned_to: any;
  task_name: string;
  id: any;
}
const AddTaskPage = () => {
  const navigator = useNavigate();
  const { id } = useParams()
  const [teamMember, setTeamMembers] = useState([])
  const [taskss, setTasks] = useState([])

  const teams = useSelector((state: any) => state?.root?.reducers?.teams);
  const team = teams?.filter((team: any) => team.project.id == id)
  const tasks = useSelector((state: any) => state?.root?.reducers?.tasks);
  const teamMembers = useSelector((state: any) => state?.root?.reducers?.teamMembers);

  const filter = () => {
    let a = teamMembers.filter((member: any) => member.team.project.id == id)
    setTeamMembers(a)
  }

  const FilterTask = () => {
    axios.get(`${environment.baseUrl}project-tasks/${id}`)
      .then(res => {
        if (res.data.code == 5000) {
          setTasks([])
        } else {
          setTasks(res.data);
        }
      })
      .catch(res => console.log("error"))
  }

  const editStatus = async (data: any, ID: any) => {
    let a = { "status": data }
    try {
      await axios
        .put(`${environment.baseUrl}edit-task/${ID}`, a)
        .then(async (form) => {
          if (form?.data?.code === 2000) {
            swal({
              title: "Success",
              text: "Status Updated Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            FilterTask();
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  const columns: TableColumn<DataRow>[] = [
    {
      name: "ID",
      selector: (row) => `#${row?.assigned_to?.team?.project?.project_key}-${row?.id}`,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Task Type",
      selector: (row) => <h5 className="base-font-inter mb-0 py-2 px-4 rounded-4  fw-bold fs-6 text-capitalize" title={row?.type}
      >{row?.type == "Task" ? <TickCircle variant="Bulk" className="text-info" size="32" /> : row?.type == "Bug" ? <InfoCircle variant="Bulk" className="text-danger" size="32" /> : row?.type == "Epic" && <FlashCircle variant="Bulk" className="status-color-purple" size="32" />}</h5>,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Task Name",
      selector: (row) => row?.task_name,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Assigned To",
      selector: (row) => row?.assigned_to?.team_member_name?.firstname,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Assigned By",
      selector: (row) => row?.assigned_by?.firstname,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Priority",
      selector: row =>
        <h5 className={`${row?.priority == "Low" ? "status-color-yellow status-bg-yellow " : row?.priority == "Normal" ? " status-color-green status-bg-green" : row?.priority == "High" && "status-bg-red status-color-red"} base-font-inter mb-0 py-2 px-4 rounded-4  fw-bold fs-6 text-capitalize`}
        >{row?.priority}</h5>
      ,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Due Date",
      selector: (row) => moment(row?.due_date.split('T')[0]).format('DD/MM/YYYY'),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Status",
      selector: row =>
        <select defaultValue={row?.status}
          className={`${row?.status == "inprogress" ? "status-color-blue status-bg-blue " : row?.status == "completed" ? " status-color-green status-bg-green" : row?.status == "pending" ? " status-color-yellow status-bg-yellow" : row?.status == "denied" && "status-bg-red status-color-red"} form-select border-0 rounded-2 text-capitalize base-font-500 custom-option`} aria-label="Default select example" onChange={(e: any) => { editStatus(e.target.value, row.id) }}>
          <option value="pending">Pending</option>
          <option value="inprogress">In Progress</option>
          <option value="completed">Completed</option>
          <option value="denied">Denied</option>
        </select>,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "View",
      selector: (row) => <NavLink to="/TaskDetails" state={{ from: row }} className="">
        <div className="icon-bg-green border-0 px-2 py-2 rounded">
          <Eye size="28" className='icon-color-green ' />
        </div>
      </NavLink>,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  useEffect(() => {
    filter();
    FilterTask();
  }, [teamMembers, tasks])
  return (
    <section className="main-wrapper-section ps-4 pb-5 ">
      <div className="align-items-center row">
        <div className="py-3 col-9 ps-0 d-flex justify-content-between align-items-center">
          <h5
            className="d-flex align-items-center gap-3 cursor"
            onClick={() => navigator(-1)}

          >
            <i className="bx bx-arrow-back bx-fade-right fs-4 mb-0 base-font-700 light-icon"></i>
            <span className="fs-5 base-font-700 light-icon mb-0">Back</span>
          </h5>
          <AddTaskModal projectid={team} />
        </div>
        <div className="col-3 d-flex ps-0 justify-content-between align-items-center pb-3">
          <h5 className="fs-5 mb-0 pt-1  fw-semibold light-icon base-font-pop">
            Team Members
          </h5>
          <TeamMembar button='create' />
        </div>
      </div>
      <div className="row m-0">
        <div className={teamMember.length != 0 ? "col-9  d-flex flex-column gap-3 ps-0 " : "col-12  d-flex flex-column gap-3 ps-0"}>
          <div className="light-blue-bg py-4 px-3 px-md-4  rounded-4 mb-4">
            <div className="row align-items-center">
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 ">
                <div className="card border-0  rounded-4 flex-row justify-content-between align-items-center py-4 px-4 shadow-lg bg-white">
                  <div className="d-flex flex-column gap-3">
                    <div className="dashboardBox-card-2 box-green-bg">
                      <Signpost size="32" color="#ffffff" />
                    </div>
                    <h4 className="fs-4 mb-0 fw-semibold light-icon base-font-pop">
                      In Progress
                    </h4>
                    <h2 className="  fw-semibold   base-font-inter box-green fs-2">
                      {taskss.filter((task: any) => task.status == 'inprogress')?.length}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 ">
                <div className="card border-0  rounded-4 flex-row justify-content-between align-items-center py-4 px-4 shadow-lg bg-white">
                  <div className="d-flex flex-column gap-3">
                    <div className="dashboardBox-card-2 box-blue-bg">
                      <Cup size="32" color="#ffffff" />
                    </div>
                    <h4 className="fs-4 mb-0 fw-semibold light-icon base-font-pop">
                      Completed
                    </h4>
                    <h2 className="  fw-semibold   base-font-inter box-blue fs-2">
                      {taskss.filter((task: any) => task.status == 'completed')?.length}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 ">
                <div className="card border-0  rounded-4 flex-row justify-content-between align-items-center py-4 px-4 shadow-lg bg-white">
                  <div className="d-flex flex-column gap-3">
                    <div className="dashboardBox-card-2 box-pink-bg">
                      <Convertshape size="32" color="#ffffff" />
                    </div>
                    <h4 className="fs-4 mb-0 fw-semibold light-icon base-font-pop">
                      Pending
                    </h4>
                    <h2 className="  fw-semibold   base-font-inter box-pink fs-2">
                      {taskss.filter((task: any) => task.status == 'pending')?.length}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 ">
                <div className="card border-0  rounded-4 flex-row justify-content-between align-items-center py-4 px-4 shadow-lg bg-white">
                  <div className="d-flex flex-column gap-3">
                    <div className="dashboardBox-card-2 bg-danger">
                      <NoteRemove size="32" color="#ffffff" />
                    </div>
                    <h4 className="fs-4 mb-0 fw-semibold light-icon base-font-pop">
                      Canceled
                    </h4>
                    <h2 className="  fw-semibold   base-font-inter text-danger fs-2">
                      {taskss.filter((task: any) => task.status == 'denied')?.length}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          teamMember.length != 0 &&
          <div className="col-3 px-0">
            <div className="d-flex flex-column gap-3 pt-3">
              <div
                className="overflow-scroll d-flex flex-column gap-3 px-3"
                style={{ height: "17rem" }}
              >
                {<>
                  {teamMember.length == 0 ?
                    <>
                      No Team Members Yet
                    </>
                    :
                    teamMember.map((data: any, i: any) => {
                      if (data.team.project.id == id) {
                        return (
                          <div className="bg-white py-3 border  px-2  rounded-3 shadow align-items-center d-flex gap-2 project_task">
                            <div className="wh-40 ">
                              <img
                                src={data?.team_member_name?.profile_pic != null && data?.team_member_name?.profile_pic != undefined && !data?.team_member_name?.profile_pic.includes("images/") ? data?.team_member_name?.profile_pic : userImages}
                                alt={data.team_member_name.firstname}
                                className="user-image-fit"
                              />
                            </div>
                            <div className="">
                              <div className="row align-items-center">
                                <h6 className="base-font-600 fs-6 col-10 text-black">
                                  EMPID :- {data.team_member_name.employee_id}
                                  {data?.team_lead == 1 &&
                                    <HeartCircle variant="Bulk" size="25" className="text-danger ms-1" />}
                                </h6>
                                <h6 className="col-2">
                                  <TeamMembar button='edit' data={data} />
                                </h6>
                              </div>
                              <h4 className="text-capitalize fs-5 mb-0 text-base-color base-font-600 text-nowrap">
                                {data.team_member_name.firstname} {data.team_member_name.lastname.length > 5 ? `${data.team_member_name.lastname.slice(0, 5)}...` : data.team_member_name.lastname}
                              </h4>
                              <label
                                className={`${data?.role == "Frontend developer" ? "status-color-blue status-bg-blue " : data?.role == "Backend developer" ? " text-white bg-dark" : data?.role == "Fullstack Developer" && "status-bg-yellow status-color-yellow"} base-font-pop text-capitalize mb-0 fs-6 mt-2 px-3 rounded-1`}
                                style={{ fontWeight: "500" }}
                              >
                                {data?.role}
                              </label>
                            </div>
                          </div>
                        )
                      }
                    })
                  }
                </>}
              </div>
            </div>
          </div>
        }

        <div className="">
          <div className="d-flex justify-content-between align-items-center px-2">
            <h3 className="fs-3 fw-semibold light-icon base-font-pop">
              <NavLink to="/taskTab" className="fs-3 fw-semibold text-base-color hover-underline  base-font-pop text-capitalize ">{team[0]?.project?.project_name}</NavLink>  Task List
            </h3>
          </div>
          <div className="mt-3">
            <div className="">
              <div className="">
                <div className="d-flex align-items-center justify-content-end pb-3">
                </div>
                {taskss?.length == 0 ? <EmptyTable /> : <Table columns={columns} data={taskss} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddTaskPage;
