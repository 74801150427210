import { LOGIN, EDUCATIONAL_DETAILS, EXPERIENCE_DETAILS, BASIC_DETAILS, DETAILS, LOGGEDUSER, EMPLOYEES, FULLATTENDANCE, ATTREMARK, ALLLEAVES, MISPUNCH, HOLIDAYS, PROJECT, TEAMS, TASKS, TEAMMEMBER, BREAKLOG } from "../actions/types";

const initialState = {
  loggedIn: false,
  educationalDetails: undefined,
  basicDetails: undefined,
  experienceDetails: undefined,
  loggeduser: {},
  allEmployees: [],
  attendance: [],
  remark: undefined,
  leaves: undefined,
  holidays: undefined,
  projects: undefined,
  teams: undefined,
  tasks: [],
  mispunch: undefined,
  teamMembers: undefined,
  breaklog: []
};
const reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: action.data
      }
    case EDUCATIONAL_DETAILS:
      return {
        ...state,
        educationalDetails: action.data
      }
    case EXPERIENCE_DETAILS:
      return {
        ...state,
        experienceDetails: action.data
      }
    case BASIC_DETAILS:
      return {
        ...state,
        basicDetails: action.data
      }
    case DETAILS:
      return {
        ...state,
        Details: action.data
      }
    case LOGGEDUSER:
      return {
        ...state,
        loggeduser: action.data
      }
    case EMPLOYEES:
      return {
        ...state,
        allEmployees: action.data
      }
    case FULLATTENDANCE:
      return {
        ...state,
        attendance: action.data
      }
    case ATTREMARK:
      return {
        ...state,
        remark: action.data
      }
    case ALLLEAVES:
      return {
        ...state,
        leaves: action.data
      }
    case MISPUNCH:
      return {
        ...state,
        mispunch: action.data
      }
    case HOLIDAYS:
      return {
        ...state,
        holidays: action.data
      }
    case PROJECT:
      return {
        ...state,
        projects: action.data
      }
    case TEAMS:
      return {
        ...state,
        teams: action.data
      }
    case TASKS:
      return {
        ...state,
        tasks: action.data
      }
    case TEAMMEMBER:
      return {
        ...state,
        teamMembers: action.data
      }
      case BREAKLOG:
      return {
        ...state,
        breaklog: action.data
      }
    default:
      return state;
  }

}

export default reducers






