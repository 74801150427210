import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from "react-hook-form";
import './multifilter.css'
import { environment } from '../env';
import { TableColumn } from 'react-data-table-component';
import axios from 'axios';
import moment from 'moment';
import { DatePicker, Space } from 'antd';
import EditAttendanceModal from './pages/EditAttendanceModal';
import { FilterSearch, Profile2User, SearchNormal, RefreshSquare, Refresh } from 'iconsax-react';
import Table from '../Table/Table';
import EmptyTable from './errorComponents/EmptyTable';
import { useSelector } from 'react-redux';
import { getFullAttData, getRemarks } from '../Redux/actions';
import { useDispatch } from 'react-redux';
import CommentPop from './errorComponents/CommentPop';

interface DataRow {
    Id: number;
    Name: string;
    Date: any;
    Day: string;
    Emp_Id: string;
    In_Time: string;
    Out_Time: string;
    Comment: string;
    Remark: string;
    IP_Address: string;
    WorkedFor: string;
}

const MultiFilter = () => {

    const att = useSelector((state: any) => state?.root?.reducers?.attendance);
    const allUsers = useSelector((state: any) => state?.root?.reducers?.allEmployees);
    const remarks = useSelector((state: any) => state?.root?.reducers?.remark);
    var today = new Date();
    const month = moment().format('MM');
    const year = moment().format('YYYY');
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const [attendancedata, setAttendanceData] = useState<any>([]);
    const [showRemarks, setShowRemarks] = useState(false);
    const [dates, setDates] = useState<any>([]);
    const [modal, setModal] = useState(false);
    const [lateMark, setLatemark] = useState(0);
    const [halfDay, setHafDay] = useState(0);
    const [absent, setAbsent] = useState(0);
    const [early, setEarly] = useState(0);
    const [comments, setComments] = useState()

    const { RangePicker } = DatePicker;

    const dispatch = useDispatch()
    var comment = ""
    const current = (data: any) => {
        setModal(true)
        comment = data
        setComments(data);
    }
    const onSubmit = async (data: any) => {
        let remarked = watch('remark')
        let empid = watch('employee_id')
        if (dates[0] == undefined || dates[0] == null) {
            dates[0] = '';
            dates[1] = '';
        }
        if (typeof data == 'object') {
            let arr = []
            try {
                if (dates[0] != data[0]) {
                    dates[0] = data[0].format("YYYY-MM-DD")
                    dates[1] = data[1].format("YYYY-MM-DD")
                    arr.push(data[0].format("YYYY-MM-DD"))
                    arr.push(data[1].format("YYYY-MM-DD"))
                    setDates(arr);
                }
            } catch {
                dates[0] = ''
                dates[1] = ''
            }
        }
        if (empid == '' && remarked == '' && dates[1] == '' && dates[0] == '') {
            setAttendanceData([])
            return
        }
        let SearchUrl;
        SearchUrl = `${environment.baseUrl}attendance-filter?employee_id=${empid}&firstname=&date_after=${dates[0]}&date_before=${dates[1]}&remark=${remarked}`
        setAttendanceData([]);
        await axios(SearchUrl).then((response) => {
            if (response?.data[0] != undefined) {
                let tabledata: { Id: number; Emp_Id: any; Name: string; Date: any; Day: string; In_Time: string | JSX.Element; Out_Time: string | JSX.Element; Remark: any; Comment: any; IP_Address: any; WorkedFor: any; }[] = []
                response?.data.map((row: any, i: number) => {
                    tabledata.push({
                        Id: i,
                        Emp_Id: row.employee_id,
                        Name: `${row.user?.firstname} ${row.user?.lastname}`,
                        Date: row.date,
                        Day: moment(row.day).format('dddd'),
                        In_Time: row?.mispunch == "True" ?
                            moment(row.login_time.split("T")[1], "HH:mm:ss").format('hh:mm A') :
                            row?.login_time == "null" || row?.login_time == 'Registered' ?
                                "---" : row?.login_time == "Invalid date" ? "---" :
                                    moment(row.login_time.split(" ")[1].split(".")[0], "HH:mm:ss").format('hh:mm A'),
                        Out_Time: row?.mispunch == "True" ? row?.logout_time === "null" ? "---" :
                            moment(row.logout_time.split("T")[1], "HH:mm:ss").format('hh:mm A') : row?.logout_time === "null" || row?.logout_time == 'Registered' ? "---" :
                            row.logout_time === "Invalid date" ? "---" :
                                moment(row.logout_time.split(" ")[1].split(".")[0], "HH:mm:ss").format('hh:mm A'),
                        Remark: row?.remark,
                        Comment: row.comment,
                        IP_Address: row.ip_address,
                        WorkedFor: row?.worked_hours == "0.0" ? "Not Recorded" : `${row?.worked_hours?.split(",")[0]?.split("hours")[0]}:${row?.worked_hours?.split(",")[1]?.split("minutes")[0]}`
                    })
                })
                setShowRemarks(true);
                setAttendanceData(tabledata);
                let filterLateMark = tabledata.filter(emp => emp.Remark === "Late");
                let filterHalfDay = tabledata.filter(emp => emp.Remark === "HalfDay");
                let filterAbsent = tabledata.filter(emp => emp.Remark === "Absent");
                let filterEarly = tabledata.filter(emp => (emp.Remark === "early" || emp.Remark === "Late EarlyLeave"));
                setLatemark(filterLateMark.length);
                setHafDay(filterHalfDay.length);
                setAbsent(filterAbsent.length);
                setEarly(filterEarly.length);
            } else {
                alert("data is not available for search")
            }
        });
    }

    const AllEmployeeAttendance = async () => {
        var URL = ""
        if (moment(today).format("DD") == "01") {
            var monthe = Number(month) - 1
            URL = `${environment.baseUrl}attendance-filter?employee_id=&date_after=${year}-${monthe}-01&date_before=${year}-${month}-${moment(lastDayOfMonth).format('DD')}`
        } else {
            URL = `${environment.baseUrl}attendance-filter?employee_id=&date_after=${year}-${month}-01&date_before=${year}-${month}-${moment(lastDayOfMonth).format('DD')}`
        }
        const result = await axios.get(URL)
        dispatch(getFullAttData(result.data))
        const unique = Array.from(new Set(result.data.map((item: any) => item.remark)));
        dispatch(getRemarks(unique))
    }

    useEffect(() => {
        let tabledata: { Id: number; Emp_Id: any; Name: string; Date: any; Day: string; In_Time: string | JSX.Element; Out_Time: string | JSX.Element; Remark: any; Comment: any; IP_Address: any; WorkedFor: any; }[] = []
        if (att) {
            att.map((row: any, i: number) => {
                tabledata.push({
                    Id: i,
                    Emp_Id: row.employee_id,
                    Name: `${row.user?.firstname} ${row.user?.lastname}`,
                    Date: row.date,
                    Day: moment(row.day).format('dddd'),
                    In_Time: row?.mispunch == "True" ?
                        moment(row.login_time.split("T")[1], "HH:mm:ss").format('hh:mm A') :
                        row?.login_time == "null" || row?.login_time == 'Registered' ?
                            "---" : row?.login_time == "Invalid date" ? "---" :
                                moment(row.login_time.split(" ")[1].split(".")[0], "HH:mm:ss").format('hh:mm A'),
                    Out_Time: row?.mispunch == "True" ? row?.logout_time === "null" ? "---" :
                        moment(row.logout_time.split("T")[1], "HH:mm:ss").format('hh:mm A') : row?.logout_time === "null" || row?.logout_time == 'Registered' ? "---" :
                        row.logout_time === "Invalid date" ? "---" :
                            moment(row.logout_time.split(" ")[1].split(".")[0], "HH:mm:ss").format('hh:mm A'),
                    Remark: row?.remark,
                    Comment: row.comment,
                    IP_Address: row.ip_address,
                    WorkedFor: row.worked_hours == '0' ? "Not Recorded" : (row.worked_hours == "0.0" ? "Not Recorded" : `${row.worked_hours.split(",")[0].split("hours")[0]}:${row.worked_hours.split(",")[1].split("minutes")[0]}`)

                })
            })
            // WorkedFor: any;
            setAttendanceData(tabledata)
        }
    }, [att])

    useMemo(() => {
    }, [attendancedata])


    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Emp ID',
            selector: row => row.Emp_Id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Name',
            selector: row => row.Name,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Date',
            selector: row => row.Date,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Day',
            selector: row => row.Day,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Time-In',
            selector: row => row.In_Time,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Time-out',
            selector: row => row.Out_Time,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Worked',
            selector: row => row.WorkedFor,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Remark',
            selector: row => <div
                className={`${row.Remark == "Late" ? "status-bg-green status-color-green " :
                    row.Remark == "Late EarlyLeave" ? "status-bg-orange status-color-orange" :
                        row.Remark == "Working..." ? "status-bg-blue status-color-blue" :
                            row.Remark == "pending" ? "status-bg-yellow status-color-yellow" :
                                row.Remark == "Absent" ? "status-bg-red status-color-red" :
                                    row.Remark == "Denied" ? "status-bg-red status-color-red" :
                                        row.Remark == "EarlyLeave" ? "status-bg-lime status-color-lime" :
                                            row.Remark == "Week-Off" ? "status-bg-emerald status-color-emerald" :
                                                row.Remark == "FullDay" ? "status-bg-cyan status-color-cyan" :
                                                    row.Remark == "Late HalfDay" ? "status-bg-sky status-color-sky" :
                                                        row.Remark == "HalfDay" ? "status-bg-purple status-color-purple" : "status-bg-green status-color-green"
                    } w-100 padding-box rounded-5 d-flex justify-content-center align-items-center`}>
                <span className="base-font-inter fw-bold fs-6 text-capitalize"
                    style={{ margin: '0 5rem' }}>{row?.Remark}</span>
            </div>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Comments',
            selector: row => row.Comment == 'null' ? '-------' : <div onClick={() => { current(row.Comment) }}>{row.Comment}</div>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Edit',
            selector: row => <EditAttendanceModal details={row} />,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
    ];

    return (
        <>
            {modal && <CommentPop modaldata={modal} modal={setModal} data={comments} />}
            <div className="dashboard_main px-4 mt-3">
                <nav>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <span className='base-text-color base-font-700 fs-4'><Profile2User size="35" variant="Bulk" /></span>
                            <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                                Employee Attendance</h4>
                        </div>
                        <div className="py-2 col-1 bg-white rounded-3 shadow d-flex justify-content-center align-items-center" title='Refresh'>
                            <div onClick={() => { AllEmployeeAttendance() }}
                                // className={rotate ? "rotateBox " : ""}
                                style={{ cursor: "pointer" }}>
                                <Refresh
                                    size="35" className='text-base-color' />
                            </div>
                        </div>
                    </div>
                </nav>
                <section className='mt-5'>
                    <div className="bg-white py-3 px-5 d-flex gap-5 flex-column rounded-5 shadow">
                        <form >
                            <div className='row align-items-center pt-4 pb-2 justify-content-between'>
                                <div className="px-2 py-2 col-3 bg-white rounded-3 shadow">
                                    <div className='d-flex align-items-center bg-transparent border-base-bottom-2'>
                                        <SearchNormal size="25" className='text-base-color' />
                                        <select className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                                            style={{ fontSize: "1rem" }}
                                            {...register("employee_id", { onChange: (e) => { onSubmit(e.target.value) } })}
                                        >
                                            <option value="" className='attoptions'>Emp Name...</option>
                                            {allUsers.reverse().map((user: any, i: any) => {
                                                return <option className='attoptions' value={user.employee_id} key={i}>{user.employee_id} - {user.firstname} {user.lastname}  </option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="px-2 py-2 col-2 bg-white rounded-3 shadow">
                                    <div className="d-flex align-items-center bg-transparent border-base-bottom-2">
                                        <FilterSearch size="25" className='text-base-color' />
                                        <select id="leave_type" className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                                            style={{ fontSize: "1rem" }}
                                            {...register("remark", { onChange: (e) => { onSubmit(e.target.value) } })}
                                        >
                                            <option value="" className='attoptions'>Remark ...</option>
                                            {remarks?.map((user: any, i: any) => {
                                                return <option className='attoptions' value={user} key={i}>{user}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="p-0 col-4 d-flex">
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            onChange={(e) => { ; onSubmit(e) }}
                                        />
                                    </Space>
                                </div>
                            </div>
                        </form>
                        {showRemarks &&
                            <div className="row">
                                <div className="col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 ">
                                    <div className="d-flex flex-row gap-3">
                                        <h4 className="fs-5 mb-0 fw-semibold box-green base-font-pop">
                                            Late Marks
                                        </h4>
                                        <h2 className="  fw-semibold   base-font-inter light-icon fs-4">
                                            {lateMark}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                    <div className="d-flex flex-row gap-3">
                                        <h4 className="fs-5 mb-0 box-blue base-font-pop fw-semibold text-nowrap">
                                            Half Day
                                        </h4>
                                        <h2 className="  fw-semibold   base-font-inter light-icon fs-4">
                                            {halfDay}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                    <div className="d-flex flex-row gap-3">
                                        <h4 className="fs-5 mb-0  box-gray base-font-pop fw-semibold">
                                            Absent
                                        </h4>
                                        <h2 className="  fw-semibold   base-font-inter light-icon fs-4 ">
                                            {absent}
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                                    <div className="d-flex flex-row gap-3">
                                        <h4 className="fs-5 mb-0 base-font-pop fw-semibold box-pink">
                                            Early Leave
                                        </h4>
                                        <h2 className="fw-semibold light-icon fs-4">
                                            {early}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        }
                        {attendancedata?.length > 0 ? <Table columns={columns} data={attendancedata} from={"attendance"} />
                            : <EmptyTable tabletext="Employee Attendance" />}
                    </div>
                </section>
            </div>
        </>

    )
}

export default MultiFilter