import React from 'react'
import error from '../../../src/image/empty.gif';


const EmptyDoc = ({texts}:any) => {
    return (
        <> 
        <div className="d-flex justify-content-center align-items-center  py-5 flex-column border rounded-5 ">
        <div className="w-25">
        <img src={error} alt="" className="img-fit"/>
        </div>
            <h3 className="text-base-color base-font-bold fs-3 text-capitalize">{texts ? `${texts}` : "No Data available..."}</h3>
        </div>
        </>
    )
}

export default EmptyDoc