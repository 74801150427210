import React, { useEffect, useState } from "react";

import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { environment } from "../../env";
import { useForm } from "react-hook-form";
import Table from "../../Table/Table";
import { DocumentText1, SearchNormal } from "iconsax-react";
import DataTable, { TableColumn } from "react-data-table-component";
import EmptyTable from "../errorComponents/EmptyTable";
import { useSelector } from "react-redux";

interface UploadDocumentData {
  doc_type: any;
  employee_id: any;
  doc_info: any;
  created_date: any;
  file: any;
}

const UserDocuments = () => {
  const [uploadDoc, setUploadDoc] = useState<UploadDocumentData[]>([]);
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);

  const limit = JSON.parse(
    localStorage.getItem("AttendanceListCurrentpageLimit")!
  );
  const allUsers = useSelector(
    (state: any) => state?.root?.reducers?.allEmployees
  );

  const GetUploadDocument = async (page: any, limit: any) => {
    const userUrl = `${environment.baseUrl}get-user-documents`;
    const result = await axios(userUrl);
    if (result.data.Data === "Data Not Found") {
      alert("Data Not Found");
      return;
    }
    setUploadDoc(result.data);
    setpageCount(Math.ceil(result.data.count / limit));
  };

  async function handleLimitChange(e: any) {
    localStorage.setItem("AttendanceListCurrentpageLimit", e.target.value);
    let currentPage = JSON.parse(
      localStorage.getItem("AttendanceListCurrentPage")!
    );
    setInitialPage(0);
    await GetUploadDocument(1, parseInt(e.target.value));
  }

  const ViewPdf = (file: any) => {
    window.open(file);
  };
  useEffect(() => {
    setInitialPage(initialPage);
  }, [initialPage]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {

    let SearchUrl;
    SearchUrl = `${environment.baseUrl}get-user-documents-filter?employee_id=${data}`;
    const result = axios(SearchUrl).then((response) => {
      if (response?.data) {
        setUploadDoc(response?.data);
      } else {
        alert(data + " " + "data is not available for search");
      }
    });
  };

  useEffect(() => {
  }, []);

  const ExamplePDFViewer = (file: any) => {
    window.open(`${file}`);
  };

  const columns: TableColumn<UploadDocumentData>[] = [
    {
      name: "EmpId",
      selector: (row) => row.employee_id,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Document Type",
      selector: (row) => row.doc_type,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Document Name",
      selector: (row) => row.doc_info,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Upload Date",
      selector: (row) => moment(row.created_date).format("DD/MM/YYYY"),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "View File",
      selector: (row) => (
        <Link onClick={() => ExamplePDFViewer(row.file)} to="">
          View
        </Link>
      ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  return (
    <>
      <div className="dashboard_main px-4 mt-3">
        <nav>
          <div className=" d-flex align-items-center">
            <div className="col-12 d-flex align-items-center p-0">
              <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
                <span className="base-text-color base-font-700 fs-4">
                  <DocumentText1 size="35" variant="Bulk" />
                </span>
                <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                  Employee Documents
                </h4>
              </div>
              <div className="px-2 py-2 col-6 bg-white rounded-3 shadow">
                <div className="d-flex align-items-center bg-transparent border-base-bottom-2">
                  <SearchNormal size="25" className="text-base-color" />
                  <select
                    className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                    style={{ fontSize: "1rem" }}
                    {...register("employee_id", {
                      onChange: (e) => {
                        onSubmit(e.target.value);
                      },
                    })}
                  >
                    <option value="" className="attoptions">
                      Emp Name...
                    </option>
                    {allUsers.reverse().map((user: any, i: any) => {
                      return (
                        <option
                          className="attoptions"
                          value={user.employee_id}
                          key={i}
                        >
                          {user.employee_id} - {user.firstname} {user.lastname}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <section className="mt-5">
          <div className="bg-white pt-3 pb-3 px-4 d-flex gap-5 flex-column rounded-5 shadow">
            {uploadDoc.length !== 0 ? (
              <Table columns={columns} data={uploadDoc} />
            ) : (
              <EmptyTable tabletext="Employee Documents" />
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default UserDocuments;
