import React, { useEffect, useState, useCallback } from "react";
import "./SalarySlip.css";
// import Favicon from "../../image/Favicon.png";
import Favicon from '../../image/favicon-insta-courier-png.png';
import { environment } from "../../env";
import axios from "axios";
import {
  Col,
  Button,
  Row,
  Label,
  FormGroup,
  Form,
  Card,
  CardBody,
  Spinner,
  Progress,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import _ from "lodash";

interface BasicDetailsData {
  employee_id: any;
  date_of_joining: any;
  firstname: any;
  lastname: any;
  department: any;
  designation: any;
  pan: any;
  sick_leave: any;
  previlage_leave: any;
  id: any;
}
const AddSalarySlipPage = (data: any) => {
  // const [search , setSearch] = useState<string | null>(null);
  const [employeedetails, setEmployeeDetails] = useState<BasicDetailsData>({
    employee_id: "",
    date_of_joining: "",
    firstname: "",
    lastname: "",
    department: "",
    designation: "",
    pan: "",
    sick_leave: "",
    previlage_leave: "",
    id: "",
  });

  const onSubmit = (data: any) => {
    debounce(data);
  };

  const debounce = useCallback(
    _.debounce((_searchVal) => {
      console.log("_searchVal", _searchVal);
      GetSalarySlip(_searchVal);
    }, 900),
    []
  );

  const GetSalarySlip = (employeeId: any) => {
    const SearchUrl = `${environment.baseUrl}get-all-employee-basic-details?employee_id=${employeeId}`;
    const result = axios(SearchUrl).then((response) => {
      if (response?.data?.length > 0) {
        setEmployeeDetails(response?.data?.[0]);
        console.log("response?.data?.[0]", response?.data?.[0]);
      } else {
        console.log("data is not available for search");
      }
    });
  };

  const submitform = async (salarydata: any) => {
    // alert("submit")
    salarydata.user = employeedetails.id;
    // console.log("basic value--------------------:", salarydata.basic)
    await axios
      .post(`${environment.baseUrl}salaryslip`, salarydata)
      .then((res) => {
        if (res.data.status === "success") {
          swal({
            title: "Successfull",
            icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
          }).then(() => {
            reset();
          });
        }
      })
      .catch((res) => console.log("error"));
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  return (
    <>
      <div className="main d-flex py-5">
        <div className="imgdiv mr-5" >
          <img src={Favicon} alt="Favicon" />
        </div>
        <div className="fs-6 ml-5">
          <div className="h4" style={{ color: '#000' }}><text>InstCourier</text></div>
          <text className="SS-border"> 1458/0,R.No.103, 1st Floor, Shivram Nagar, NR Achal Guru Temple,
            Kamatghar, Bhiwandi, Thane, Maharashtra - 421302</text>
        </div>
      </div>
      <h4 style={{ textAlign: "center" }} className="SS-secondeTitle">Salary Slip</h4>
      <div className="container-fluid">
        <form onSubmit={handleSubmit(submitform)}>
          <div className="">
            <div className="row h-auto">
              <div className="d-flex">
                <div className="p-2">
                  <Label className="Lable1 text-black">
                    Payslip for the month
                  </Label>
                </div>
                <div>
                  <input
                    className="form-control"
                    id="date"
                    type="month"
                    {...register("payslip_month", { required: true })}
                  // value={}
                  // onChange={}
                  />
                </div>
              </div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4 d-flex">
                <div className="p-2">
                  <label className="Lable1 text-black">Employee Code :</label>
                </div>
                <div>
                  <input
                    type="text"
                    className="filter-code form-control"
                    placeholder="Employee Id"
                    style={{ fontSize: 14 }}
                    {...register("employee_id", { required: true })}
                    onChange={(e) => onSubmit(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4 d-flex">
                <div className="p-2">
                  <Label className="Lable1 text-black">Branch :</Label>
                </div>
                <div>
                  <Label className="labelinput">Mumbai</Label>
                </div>
              </div>
              <div className="col-4 d-flex">
                <div className="p-2">
                  <Label className="Lable1 text-black">Joining Date :</Label>
                </div>
                <div>
                  <Label className="labelinput">
                    {employeedetails.date_of_joining}
                  </Label>
                </div>
              </div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4 d-flex">
                <div className="p-2">
                  <Label className="Lable1 text-black">Employee Name :</Label>
                </div>
                <div>
                  <Label className="labelinput">
                    {employeedetails.firstname} {employeedetails.lastname}
                  </Label>
                </div>
              </div>
              <div className="col-4 d-flex">
                <div className="p-2">
                  <Label className="Lable1 text-black">Department :</Label>
                </div>
                <div>
                  <Label className="labelinput">
                    {employeedetails.department}
                  </Label>
                </div>
              </div>
              <div className="col-4 d-flex">
                <div className="p-2">
                  <Label className="Lable1 text-black">Designation :</Label>
                </div>
                <div>
                  <Label className="labelinput">
                    {employeedetails.designation}
                  </Label>
                </div>
              </div>
              <hr />
            </div>
            <div className="row">
              <div className="col-4 d-flex">
                <div className="p-2">
                  <Label className="Lable1 text-black">Employee Pan :</Label>
                </div>
                <div>
                  <Label className="labelinput">{employeedetails.pan}</Label>
                </div>
              </div>
              <div className="col-4 d-flex ">
                <label className="Lable1 text-black py-2">Day Paid :</label>
                <input
                  type="text"
                  className="form-control w-50 ml-2"
                  {...register("days_paid", { required: true })}
                />
              </div>
              <div className="col-4 d-flex ">
                <label className="Lable1 text-black py-2">Day Present :</label>
                {"  "}
                <input
                  className="form-control w-50 ml-2"
                  type="text"
                  {...register("days_present", { required: true })}
                />
              </div>
              <hr />
            </div>
          </div>
          <div className="">
            <div className="mb-4 d-flex justify-content-between">

            </div>

            <table className="table">
              <thead>
                <tr className="">
                  <th scope="col" className=" earnings border text-black">
                    Earnings Components
                  </th>
                  <th scope="col" className="border text-black">
                    Fixtures
                  </th>

                  <th scope="col" className="border text-black">
                    Deductions
                  </th>
                  <th scope="col" className="border text-black">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border text-black">Basic</td>
                  <td>
                    {" "}
                    <input
                      className="form-control"
                      type="text"
                      {...register("basic", { required: true })}
                    />
                  </td>
                  {/* <td className="border">
                                    {" "}
                                    <Input
                                        type="text"
                                        {...register("email_id", )}
                                    />
                                </td> */}
                  <td className="border text-black">PF</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("pf", { required: true })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border text-black">H.R.A</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("hra", { required: true })}
                    />
                  </td>
                  {/* <td className="border">
                                    <Input
                                        type="text"
                                        {...register("email_id", { required: true })}
                                    />
                                </td> */}
                  <td className="border text-black">ESI</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("esi", { required: true })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="other border text-black">Conveyance</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("conveyance", { required: true })}
                    />
                  </td>
                  {/* <td className="border">
                                    <Input
                                        type="text"
                                        {...register("email_id", { required: true })}
                                    />
                                </td> */}
                  <td className="border text-black">Income Tax</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("income_tax", { required: true })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border text-black">Special Allowance</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("special_allowance", { required: true })}
                    />
                  </td>
                  {/* <td className="border">
                                    <Input
                                        type="text"
                                        {...register("email_id", { required: true })}
                                    />
                                </td> */}
                  <td className="border text-black">Advance</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("advance", { required: true })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border text-black">Medical Allowance</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("medical_allowance", { required: true })}
                    />
                  </td>
                  {/* <td className="border">
                                    <Input
                                        type="text"
                                        {...register("email_id", { required: true })}
                                    />
                                </td> */}
                  <td className="border text-black">Prof Tax</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("Prof_tax", { required: true })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border fw-bold text-black text-black">
                    Total Earnings
                  </td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("total_earnings", { required: true })}
                    />
                  </td>
                  {/* <td className="border">
                                    <Input
                                        type="text"
                                        {...register("email_id", { required: true })}
                                    />
                                </td> */}
                  <td className="border fw-bold text-black">
                    Total Deductions
                  </td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("total_deductions", { required: true })}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border fw-bold text-black">Net Pay</td>
                  <td className="border">
                    <input
                      className="form-control"
                      type="text"
                      {...register("net_pay", { required: true })}
                    />
                  </td>
                  {/* <td>
                                    <Input
                                        type="text"
                                        {...register("email_id", { required: true })}
                                    />
                                </td> */}
                  <td className="border fw-bold" />
                  <td className="border"></td>
                </tr>
              </tbody>
            </table>
            <input className="button btn btn-outline-secondary" type="submit" defaultValue="Register" />

            <div className="d-flex justify-content-between ">
              <h6>This Is System Generated Payslip, No Signature Required</h6>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddSalarySlipPage;
