import axios from "axios";
import React, { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import swal from "sweetalert";
import { S3_BUCKET, environment, link, myBucket } from "../../env";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProjectData } from "../../Redux/actions";
import { Edit2 } from "iconsax-react";
import ErrorComponents from "../errorComponents/ErrorComponents";

const AddProjectModal = (props: any) => {

  const allUsers = useSelector((state: any) => state?.root?.reducers?.allEmployees);

  const [modal, setModal] = useState(false);
  const [ID, setID] = useState(false);
  const [editt, setEdit] = useState(false);
  const toggle = () => {
    setModal(!modal);
    reset()
    setEdit(false)
  };

  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const Image = async (data: any) => {
    var date = Math.round(+new Date() / 1000);
    const params = {
      ACL: "public-read",
      Body: data,
      Bucket: S3_BUCKET,
      Key: `projectlogo_${date}_${data.name}`,
    };
    myBucket.upload(params, function (err: any, uploadData: any) {
      if (uploadData) {
        data = uploadData.Location;
        return (data);
      } else {
        console.log("error", err);
      }
    });
  }

  const getProjects = async () => {
    axios.get(`${environment.baseUrl}create-project`)
      .then(res => {
        dispatch(getProjectData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const onSubmit = async (value: any) => {
    var date = Math.round(+new Date() / 1000);
    if (value.image.length !== 0) {
      Image(value.image[0])
      value.project_logo = `${link}projectlogo_${date}_${value.image[0].name}`
    }
    try {
      await axios
        .post(`${environment.baseUrl}create-project`, value)
        .then(async (form) => {
          if (form.data.code === 2000) {
            swal({
              title: "Success",
              text: "Project Added Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            toggle();
            getProjects()
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const Edit = async (value: any) => {
    var date = Math.round(+new Date() / 1000);
    if (value.image.length != 0) {
      Image(value.image[0])
      value.project_logo = `${link}projectlogo_${date}_${value.image[0].name}`
    }
    try {
      await axios
        .put(`${environment.baseUrl}edit-project/${ID}`, value)
        .then(async (form) => {
          if (form.data.code === 2000) {
            swal({
              title: "Success",
              text: "Project was Successfully Edited",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            toggle();
            getProjects()
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useMemo(() => {
    setID(props.data?.id)
    reset({
      "project_name": props.data?.project_name,
      "project_key": props.data?.project_key,
      "project_desc": props.data?.project_desc,
      "project_type": props.data?.project_type,
      "project_lead": props.data?.project_lead?.id
    })
  }, [editt])


  return (
    <>
      {
        props.button == 'edit' ?
          <button
            className="col-1 fs-5  base-font-pop border-0  text-base-color  bg-transparent"
            style={{ fontWeight: "400" }}
            onClick={() => {
              toggle();
              setEdit(true)
            }}
          >
            <Edit2 />
          </button>
          :
          <button
            onClick={() => {
              toggle();
            }}
            className="submit-btn"
          >
            Add Projects
          </button>
      }
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="lg"
        className="mispunch-modal"
      >
        <ModalHeader toggle={toggle} className="py-3 px-3">
          <span className="base-font-muli fs-4 text-base-color fw-bold px-3 ">
            {props.button != 'edit' ? "Add Project " : "Edit Project"}
          </span>
        </ModalHeader>

        <ModalBody className="p-4">
          <form
            onSubmit={editt == false ? handleSubmit(onSubmit) : handleSubmit(Edit)}
            className="inner-login"
          >
            <div className="row d-flex">
              <div className=" col-6">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Project Name
                  </Label>
                  <input
                    className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    placeholder="Name of the Project"
                    {...register("project_name", { required: true })}
                  />
                  {errors.project_name && <ErrorComponents error={"Project Name is required *"} />}
                </div>
              </div>
              <div className=" col-6">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Project Key (abbrivation)
                  </Label>
                  <input
                    className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none text-uppercase"
                    type="text"
                    min={2}
                    maxLength={2}
                    id="project_key"
                    placeholder="eg. JS,SP,CS..."
                    {...register("project_key", { required: true, maxLength: 2, minLength: 2, onChange: (e) => { e.target.value = e.target.value.toUpperCase() } })}
                  />
                  {errors.project_key && <ErrorComponents error={"Project Key is required *"} />}
                </div>
              </div>
              <div className=" col-6">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Project Type
                  </Label>
                  <input
                    className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    type="text"
                    id="project_type"
                    placeholder="Type of the Project"
                    {...register("project_type", { required: true })}
                  />
                  {errors.project_type && <ErrorComponents error={"Project Type is required *"} />}
                </div>
              </div>
              <div className=" col-6">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Project lead
                  </Label>
                  <select className="BDLabelInput input-border w-100 pl-2 base-font-400 shadow-none"
                    style={{ fontSize: "1rem" }}
                    {...register("project_lead", { required: true })}
                  >
                    <option value="" className='attoptions'>Employee Name...</option>
                    {allUsers.map((user: any, i: any) => {
                      return <option className='attoptions' value={user.id} key={i}>{user.firstname} {user.lastname}  </option>;
                    })}
                  </select>
                  {errors.project_lead && <ErrorComponents error={"Project Lead is required *"} />}
                </div>
              </div>
              <div className=" col-6">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Project Logo
                  </Label>
                  <input
                    type="file"
                    id="file"
                    className="my-3"
                    {...register("image")}
                  />
                </div>
              </div>
              <div className=" col-6">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Description
                  </Label>
                  <textarea
                    className="py-1 px-1 fs-5 base-font-pop w-100  input-textarea shadow-none border-primary rounded-2"
                    style={{ outline: "none" }}
                    id="project_desc"
                    {...register("project_desc", { required: true })}
                    placeholder="Enter Description..."
                  />
                  {errors.project_desc && <ErrorComponents error={"Project Description is required *"} />}
                </div>
              </div>
            </div>
            <ModalFooter className="border-0 pb-0">
              <button type="submit" className="submit-btn">
                submit
              </button>
              <button onClick={toggle} type='button' className="close-btn">
                Close
              </button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddProjectModal;
