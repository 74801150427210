import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { environment } from "../../env";
import { getTeamsData } from "../../Redux/actions";
import { useDispatch } from "react-redux";
import ErrorComponents from "../errorComponents/ErrorComponents";

const AddTeamModal = (props: any) => {
  const projects = useSelector((state: any) => state?.root?.reducers?.projects);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    reset();
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const getTeams = () => {
    axios.get(`${environment.baseUrl}create-team`)
      .then(res => {
        dispatch(getTeamsData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const onSubmit = async (value: any) => {
    try {
      await axios
        .post(`${environment.baseUrl}create-team`, value)
        .then(async (form) => {
          if (form.data.code === 2000) {
            swal({
              title: "Success",
              text: "Team Added Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            toggle();
            getTeams();
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        onClick={() => {
          toggle();
        }}
        className="submit-btn"
      >
        Add Team
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="md"
        className="mispunch-modal"
      >
        <ModalHeader toggle={toggle} className="py-3 px-3">
          <span className="base-font-muli fs-4 text-base-color fw-bold px-3 ">
            Add Team
          </span>
        </ModalHeader>

        <ModalBody className="p-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="inner-login"
            encType="multipart/form-data"
          >
            <div className="row d-flex">
              <div className=" col-12">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Project Name
                  </Label>
                  <select className="BDLabelInput input-border w-100 pl-2 base-font-400 shadow-none"
                    style={{ fontSize: "1rem" }}
                    {...register("project", { required: true })}
                  >
                    <option value="" className='attoptions'>Project Names ... </option>
                    {projects?.map((user: any, i: any) => {
                      return <option className='attoptions' value={user.id} key={i}>{user.project_key} - {user.project_name}</option>;
                    })}
                  </select>
                  {errors.project && <ErrorComponents error={"Project is required *"} />}
                </div>
              </div>
              <div className=" col-12">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Team Title
                  </Label>
                  <input
                    className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    type="text"
                    placeholder="Team Title"
                    {...register("team_name", { required: true })}
                  />
                  {errors.team_name && <ErrorComponents error={"Team Name is required *"} />}
                </div>
              </div>
            </div>
            <ModalFooter className="border-0 pb-0">
              <button type="submit" className="submit-btn">
                submit
              </button>
              <button onClick={toggle} className="close-btn">
                Close
              </button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddTeamModal;
