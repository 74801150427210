import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Card, CardBody, CardFooter, Form } from "reactstrap";
import { Button } from "reactstrap";
import defaultimage from "../../../image/DefaultProfileLogo.png";
import "react-phone-number-input/style.css";
import { environment } from "../../../env";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Edit, Trash, UserEdit } from "iconsax-react";

function BasicDetails(props: any) {

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  // const basicdetails = useSelector(
  //   (state: any) => state?.root?.reducers?.basicDetails
  // );
  const basicdetails = props.basicdetails;
  const userPass = basicdetails?.vpass;

  const isIdZero = () => userPass === '0';

  const [show, setShow] = useState(true);

  const onSubmit = async (data: any) => {
    let form_data = new FormData();
    form_data.append('firstname', data.firstname)
    form_data.append('lastname', data.lastname)
    form_data.append('email_id', data.email_id)
    form_data.append('phone_no', data.phone_no)
    form_data.append('gender', data.gender)
    form_data.append('personal_email', data.personal_email)
    form_data.append('pan', data.pan)
    form_data.append('aadhar', data.aadhar)
    form_data.append('blood_group', data.blood_group)
    form_data.append('date_of_birth', data.date_of_birth)
    form_data.append('emg_contact_name', data.emg_contact_name)
    form_data.append('relationship_with_emg_contact', data.relationship_with_emg_contact)
    form_data.append('reporting_manager', data.reporting_manager)
    form_data.append('emg_contact_number', data.emg_contact_number)
    form_data.append('check_out_time', data.check_out_time)
    form_data.append('check_in_time', data.check_in_time)
    form_data.append('department', data.department)
    form_data.append('designation', data.designation)
    form_data.append('date_of_joining', data.date_of_joining)
    form_data.append('probation_period', data.probation_period)
    form_data.append('notice_period', data.notice_period)
    try {
      await axios.put(`${environment.baseUrl}update-user-details/${basicdetails?.employee_id}`, form_data).then(
        res => {
          if (res.status == 200) {
            swal({
              title: "Success",
              text: 'Profile Updated Successfully',
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            })
            setShow(!show)
            props.set(props.number + 1)
            props.emp({ "employee_id": basicdetails?.employee_id, "firstname": basicdetails?.firstname })
          }
        }
      ).catch(res => console.log("error"))
    }
    catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    reset({
      "firstname": basicdetails?.firstname,
      "lastname": basicdetails?.lastname,
      "gender": basicdetails?.gender,
      "email_id": basicdetails?.email_id,
      "phone_no": basicdetails?.phone_no,
      "profile_pic": basicdetails?.profile_pic,
      "personal_email": basicdetails?.personal_email,
      "pan": basicdetails?.pan,
      "aadhar": basicdetails?.aadhar,
      "emg_contact_name": basicdetails?.emg_contact_name,
      "relationship_with_emg_contact": basicdetails?.relationship_with_emg_contact,
      "emg_contact_number": basicdetails?.emg_contact_number,
      "reporting_manager": basicdetails?.reporting_manager,
      "check_in_time": basicdetails?.check_in_time,
      "check_out_time": basicdetails?.check_out_time,
      "blood_group": basicdetails?.blood_group,
      "date_of_birth": basicdetails?.date_of_birth,
      "department": basicdetails?.department,
      "designation": basicdetails?.designation,
      "date_of_joining": basicdetails?.date_of_joining,
      "probation_period": basicdetails?.probation_period,
      "notice_period": basicdetails?.notice_period
    })
  }, [props.number]);

  useEffect(() => {

  }, [props.basicdetails])

  return (
    <>
      <Card className='rounded-tb border-0 position-relative'>
        {show ?
          <>
            <CardBody className='p-0'>
              <div className="basic-details-back-main">
                <div className="basic-details-back">
                  <div className="basic-details-user-img  d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <div className="basic-details-imgBox">
                        <img src={
                          basicdetails?.profile_pic != 'null'
                            ? `${basicdetails?.profile_pic}`
                            : `https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/default.jpg`
                        } alt="user-img" className='user-image-fit' />
                      </div>
                      <div className="nameBox d-flex flex-column pt-3 px-3">
                        <h1 className='base-font-Dancing fw-bold fs-1 text-capitalize text-base-color'>{basicdetails?.firstname} {basicdetails?.lastname}.</h1>
                        <h6 className='base-font-pop fw-bold status-color-blue fs-6  text-capitalize '>EMP ID :- {basicdetails?.employee_id}</h6>
                        <h6 className='base-font-pop fw-600 fs-6  text-capitalize text-dark'>{basicdetails?.email_id}</h6>
                        <p className='base-font-pop fw-600 mb-0 text-capitalize '>{basicdetails?.phone_no}</p>
                      </div>
                    </div>
                    <Button className='tb-btn base-font-muli fw-bold d-flex align-items-center ' onClick={() => setShow(!show)}>
                      <span>Edit</span>
                      <UserEdit size="32" variant="Bulk" className='ps-2' />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="userBoxs px-5">
                <div className="d-flex justify-content-center align-items-center ">
                  <div className="row g-3 ">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >First Name</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.firstname == "null" ? <>---</> : basicdetails?.firstname}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Last Name </label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.lastname == "null" ? <>---</> : basicdetails?.lastname}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Personal Email</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.personal_email == "null" ? <>---</> : basicdetails?.personal_email}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800  pb-1' >Contact Number</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.phone_no == "null" ? <>---</> : basicdetails?.phone_no}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800  pb-1' >Pan Number</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.pan == "null" ? <>---</> : basicdetails?.pan}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800  pb-1' >Aadhar Number</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.aadhar == "null" ? <>---</> : basicdetails?.aadhar}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800  pb-1' >Blood Group</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.blood_group == "null" ? <>---</> : basicdetails?.blood_group}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Gender</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.gender == "null" ? <>---</> : basicdetails?.gender}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Date Of Birth</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.date_of_birth == "null" ? <>---</> : basicdetails?.date_of_birth}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Emergency Contact Name</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.emg_contact_name == "null" ? <>---</> : basicdetails?.emg_contact_name}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Relation with Employee</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.relationship_with_emg_contact == "null" ? <>---</> : basicdetails?.relationship_with_emg_contact}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Emergency Contact Number</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.emg_contact_number == "null" ? <>---</> : basicdetails?.emg_contact_number}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Office Email</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.email_id == "null" ? <>---</> : basicdetails?.email_id}</label>
                      </div>
                    </div>
                    {
                      isIdZero()
                        ?
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                          <div className="d-flex flex-column">
                            <label className='BD-labels mb-2 base-font-muli fw-800 ' >Password</label>
                            <label className='fs-6 label-inputs base-font-pop' >Insta@1234</label>
                          </div>
                        </div>
                        :
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                          <div className="d-flex flex-column">
                            <label className='BD-labels mb-2 base-font-muli fw-800 ' >Password</label>
                            <label className='fs-6 label-inputs base-font-pop' >{userPass == "null" ? <>---</> : userPass}</label>
                          </div>
                        </div>
                    }
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Date of Joining</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.date_of_joining == "null" ? <> --- </> : basicdetails?.date_of_joining}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' > Reporting Manager</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.reporting_manager == "null" ? <> --- </> : basicdetails?.reporting_manager}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' > Check-In Time</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.check_in_time == "null" ? <> --- </> : basicdetails?.check_in_time}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Check-Out Time</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.check_out_time ? <>---</> : basicdetails?.check_out_time}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' > Department</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.department ? <>---</> : basicdetails?.department}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Designation</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.designation ? <>---</> : basicdetails?.designation}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' > Probation Period</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.probation_period == "null" ? <>---</> : basicdetails?.probation_period}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Notice Period</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.notice_period == "null" ? <>---</> : basicdetails?.notice_period}</label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </CardBody>
          </> :
          <>
            <Form id="details" onSubmit={handleSubmit(onSubmit)}>
              <CardBody className='p-0'>
                <div className="basic-details-back-main">
                  <div className="basic-details-back">
                    <div className="basic-details-user-img  d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center ">
                        <div className="basic-details-imgBox">
                          <img src={
                            basicdetails?.profile_pic != 'null'
                              ? `${basicdetails?.profile_pic}`
                              : `https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/default.jpg`
                          } alt="user-img" className='user-image-fit' />
                        </div>
                        <div className="nameBox d-flex flex-column pt-3 ps-4">
                          <h1 className='base-font-Dancing fw-bold fs-1 text-capitalize text-base-color'>{basicdetails?.firstname} {basicdetails?.lastname}.</h1>
                          <h6 className='base-font-pop fw-bold status-color-blue fs-6  text-capitalize '>EMP ID :- {basicdetails?.employee_id}</h6>
                          <h6 className='base-font-pop fw-600 fs-6  text-capitalize text-dark'>{basicdetails?.personal_email}</h6>
                          <p className='base-font-pop fw-600 mb-0 text-capitalize '>{basicdetails?.phone_no}</p>
                        </div>
                      </div>
                      {
                        show &&
                        <Button className='tb-btn base-font-muli fw-bold d-flex align-items-center ' onClick={() => setShow(!show)}>
                          <span>Edit</span>
                          <UserEdit size="32" variant="Bulk" className='ps-2' />
                        </Button>
                      }

                    </div>
                  </div>
                </div>
                <div className="userBoxs px-5">
                  <div className="row " id="main">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >First Name</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.firstname == "null" ? <>---</> : basicdetails?.firstname}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Last Name </label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.lastname == "null" ? <>---</> : basicdetails?.lastname}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Personal Email</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.personal_email == "null" ? <>---</> : basicdetails?.personal_email}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800  pb-1' >Contact Number</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.phone_no == "null" ? <>---</> : basicdetails?.phone_no}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800  pb-1' >Pan Number</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.pan == "null" ? <>---</> : basicdetails?.pan}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800  pb-1' >Aadhar Number</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.aadhar == "null" ? <>---</> : basicdetails?.aadhar}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800  pb-1' >Blood Group</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.blood_group == "null" ? <>---</> : basicdetails?.blood_group}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Gender</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.gender == "null" ? <>---</> : basicdetails?.gender}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Date Of Birth</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.date_of_birth == "null" ? <>---</> : basicdetails?.date_of_birth}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Emergency Contact Name</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.emg_contact_name == "null" ? <>---</> : basicdetails?.emg_contact_name}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Relation with Employee</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.relationship_with_emg_contact == "null" ? <>---</> : basicdetails?.relationship_with_emg_contact}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Emergency Contact Number</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.emg_contact_number == "null" ? <>---</> : basicdetails?.emg_contact_number}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className='BD-labels mb-2 base-font-muli fw-800 ' >Office Email</label>
                        <label className='fs-6 label-inputs base-font-pop' >{basicdetails?.email_id == "null" ? <>---</> : basicdetails?.email_id}</label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800" >Date of Joining</p>
                        <input
                          className="py-1 fs-6 shadow-none input-border"
                          type="date"
                          placeholder="Emg Contect's Number"
                          {...register("date_of_joining")}
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800" >Reporting Manager</p>
                        <input
                          className="py-1 fs-6 shadow-none input-border"
                          type="text"
                          placeholder="Reporting Manager"
                          {...register("reporting_manager")}
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800" >Check-In Time</p>
                        <input
                          className="py-1 fs-6 shadow-none input-border"
                          type="time"
                          placeholder="Emg Contect's Number"
                          {...register("check_in_time")}
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800" >Check-Out Time</p>
                        <input
                          className="py-1 fs-6 shadow-none input-border"
                          type="time"
                          placeholder="Emg Contect's Number"
                          {...register("check_out_time")}
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800" >Department</p>
                        <input
                          className="py-1 fs-6 shadow-none input-border"
                          type="text"
                          placeholder="Department"
                          {...register("department")}
                        />

                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800" >Designation</p>
                        <input
                          className="py-1 fs-6 shadow-none input-border"
                          type="text"
                          placeholder="Department"
                          {...register("designation")}
                        />

                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800" >Probation Period</p>
                        <select
                          className="py-1 fs-6 shadow-none input-border w-50"
                          {...register("probation_period")}
                        >
                          <option value="">Options..</option>
                          <option value="3 months">3 months</option>
                          <option value="4 months">4 months</option>
                          <option value="6 months">6 months</option>
                        </select>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800" >Notice Period</p>
                        <select
                          className="py-1 fs-6 shadow-none input-border w-50"
                          {...register("notice_period")}
                        >
                          <option className="mt-2 pt-2" value="">
                            Options..
                          </option>
                          <option value="1 months">1 months</option>
                          <option value="2 months">2 months</option>
                          <option value="3 months">3 months</option>
                        </select>
                      </div>

                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter className='border-0 bg-transparent'>
                <div className='d-flex justify-content-end gap-3'>
                  <button className='update-btn' type='submit'>
                    Update
                    <Edit size="28" className='ps-1 pb-1' variant="Bulk" />
                  </button>
                  <button className="close-btn" type='button'
                    onClick={() => setShow(!show)}
                  >
                    Cancel
                    <Trash size="24" className='ps-1 pb-1' variant="Bulk" />
                  </button>
                </div>
              </CardFooter>
            </Form>
          </>
        }

      </Card>

    </>
  );
}

export default BasicDetails;
