import React, { useState, useEffect } from "react";
import "./basicdetails.css";
import swal from "sweetalert";
import axios from "axios";
import {
  Card,
  CardBody,
  Label,
} from "reactstrap";
import { environment } from "../../../env";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const BankDetails = (props: any) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const basicdetails = useSelector(
    (state: any) => state?.root?.reducers?.basicDetails
  );

  const [show, setShow] = useState(true);

  const onSubmit = async (data: any) => {
    let form_data = new FormData();
    form_data.append("bank_name", data.bank_name);
    form_data.append("acc_holder_name", data.acc_holder_name);
    form_data.append("acc_number", data.acc_number);
    form_data.append("acc_ifsc_number", data.acc_ifsc_number);
    form_data.append("acc_type", data.acc_type);
    form_data.append("acc_branch", data.acc_branch);
    debugger
    try {
      await axios
        .put(
          `${environment.baseUrl}update-user-details/${basicdetails.employee_id}`,
          form_data
        )
        .then((res) => {
          if (res.status == 200) {
            swal({
              title: "Success",
              text: "Bank Details Updated Successfully",
              icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
            });
            setShow(!show);
            props.set(props.number + 1);
          }
        })
        .catch((res) => console.log("error"));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    reset({
      "bank_name": basicdetails.bank_name,
      "acc_holder_name": basicdetails.acc_holder_name,
      "acc_number": basicdetails.acc_number,
      "acc_ifsc_number": basicdetails.acc_ifsc_number,
      "acc_type": basicdetails.acc_type,
      "acc_branch": basicdetails.acc_branch,
    });
  }, [props.number]);

  return (
    <div>
      <Card className='mt-3 mx-3 mb-2 px-3 border-0 position-relative'
      >
        <div className=''>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 className='fs-2 mb-0 text-base-color fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
              Bank Account Details</h4>
          </div>
        </div>
        <CardBody className='p-0 py-5'>
          <div className=' row px-3'
          >
            <div className="col-4"
            >
              <Label className='BDLabels base-font-muli fw-bold fs-5  '>
                Bank Name
              </Label>
              <Label className='BDLabelInput'>
                {basicdetails.bank_name == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.bank_name}

              </Label>
            </div>
            <div className="col-4">
              <Label className='BDLabels base-font-muli fw-bold fs-5  '>
                Account Number
              </Label>
              <Label className='BDLabelInput'>
                {basicdetails.acc_number == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.acc_number}

              </Label>
            </div>
            <div className="col-4">
              <Label className='BDLabels base-font-muli fw-bold fs-5  '>
                Account Type
              </Label>

              <Label className='BDLabelInput'>
                {basicdetails.acc_type == 'null' ? <span className="d-flex justify-content-center w-25"> - </span> : basicdetails.acc_type}

              </Label>
            </div>
            <div
              className='col-4'
            >
              <Label className='BDLabels base-font-muli fw-bold fs-5  '>

                Account Holder's Name
              </Label>
              <Label className='BDLabelInput'>
                {basicdetails.acc_holder_name == 'null' ? <span className="d-flex justify-content-center w-100"> - </span> : basicdetails.acc_holder_name}

              </Label>
            </div>
            <div className="col-4">
              <Label className='BDLabels base-font-muli fw-bold fs-5  '>

                IFSC Code
              </Label>
              <Label className='BDLabelInput'>
                {basicdetails.acc_ifsc_number == 'null' ? <span className="d-flex justify-content-center w-75"> - </span> : basicdetails.acc_ifsc_number}

              </Label>
            </div>
            <div className="col-4">
              <Label className='BDLabels base-font-muli fw-bold fs-5  '>

                Branch Name
              </Label>
              <Label className='BDLabelInput'>
                {basicdetails.acc_branch == 'null' ? <span className="d-flex justify-content-center w-75"> - </span> : basicdetails.acc_branch}

              </Label>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BankDetails;
