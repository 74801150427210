import React, { useCallback, useEffect, useState } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import DataTable, { TableColumn } from 'react-data-table-component';
import { environment } from '../env';
import { Note, Refresh, SearchNormal } from 'iconsax-react';
import Table from '../Table/Table';
import EmptyTable from './errorComponents/EmptyTable';
import EditAttendanceModal from './pages/EditAttendanceModal';
import { useDispatch, useSelector } from 'react-redux';
import { getMispunchAction } from '../Redux/actions';
import _ from 'lodash';
import CommentPop from './errorComponents/CommentPop';
import EditMispunch from './EditMispunch';

interface UserData {
    firstname: string;
    lastname: string
}
interface DataRow {
    mispunch: any;
    date: any;
    day: any;
    login_time: any;
    logout_time: any;
    comment: any;
    remark: any;
    employee_id: any
}

interface UserData2 {
    date: any;
    day: any;
    login_time: any;
    logout_time: any;
    comment: any;
    remark: any
}

const Mispunchdetails = () => {
    const [posts, setPost] = useState<UserData2[]>([]);
    const [rotate, setRotate] = useState(false)
    const [modal, setModal] = useState(false);
    const [comments, setComments] = useState()


    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const mispunches = useSelector((state: any) => state?.root?.reducers?.mispunch);
    const allUsers = useSelector((state: any) => state?.root?.reducers?.allEmployees);

    const dispatch = useDispatch();

    const submit = (data: any) => {
        var empID = watch("employee_id")
        var firstname = watch("firstname")
        const SearchUrl = `${environment.baseUrl}get-mispunch-filter?employee_id=${empID}&firstname=${firstname}`
        const result = axios(SearchUrl).then(res => {
            if (res?.data?.length > 0) {
                setPost(res?.data);
            } else {
                alert("data not available")
            }

        })
            .catch(res => console.log("error"));
    };

    const GetMispunch = async () => {
        setRotate(true);
        axios.get(`${environment.baseUrl}get-mispunch/`)
            .then(res => {
                dispatch(getMispunchAction(res.data))
                setRotate(false);
            })
            .catch(res => console.log("error"))
    }


    const onSubmit = (data: any) => {
        debounce(data);
    };

    const debounce = useCallback(
        _.debounce((_searchVal) => {
            submit(_searchVal);
        }, 900),
        []
    );

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'EmpID',
            selector: row => row?.employee_id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Day',
            selector: row => moment(row.day).format('dddd'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Date',
            selector: row => moment(row?.date).format('DD-MM-YYYY'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Time-In',
            selector: row => row?.mispunch == "True" ?
                moment(row.login_time.split("T")[1], "hh:mm:ss").format('hh:mm A') :
                row?.login_time == "null" ? <span style={{ paddingLeft: '30px' }}>-</span> : moment(row.login_time.split(" ")[1].split(".")[0], "hh:mm:ss").format('hh:mm A'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Time-Out',
            selector: row => row?.mispunch == "True" ? row?.logout_time === "null" ? <span style={{ paddingLeft: '30px' }}>-</span> :
                moment(row.logout_time.split("T")[1], "hh:mm:ss").format('hh:mm A') : row?.logout_time === "null" ? <span style={{ paddingLeft: '30px' }}>-</span> :
                moment(row.logout_time.split(" ")[1].split(".")[0], "hh:mm:ss").format('hh:mm A'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Comment',
            selector: row => <div onClick={() => { current(row.comment) }}>{row.comment}</div>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Remark',
            selector: row => <div
                className={`${row?.remark == "Late" ? "status-bg-green status-color-green " :
                    row?.remark == "Late EarlyLeave" ? "status-bg-orange status-color-orange" :
                        row?.remark == "Working..." ? "status-bg-blue status-color-blue" :
                            row?.remark == "pending" ? "status-bg-yellow status-color-yellow" :
                                row?.remark == "Absent" ? "status-bg-red status-color-red" :
                                    row?.remark == "Denied" ? "status-bg-red status-color-red" :
                                        row?.remark == "EarlyLeave" ? "status-bg-lime status-color-lime" :
                                            row?.remark == "Week-Off" ? "status-bg-emerald status-color-emerald" :
                                                row?.remark == "FullDay" ? "status-bg-cyan status-color-cyan" :
                                                    row?.remark == "Late HalfDay" ? "status-bg-sky status-color-sky" :
                                                        row?.remark == "HalfDay" ? "status-bg-purple status-color-purple" : "status-bg-green status-color-green"
                    } w-100 padding-box rounded-5 d-flex justify-content-center align-items-center`}>
                <span className="base-font-inter fw-bold fs-6 text-capitalize"
                    style={{ margin: '0 5rem' }}>{row?.remark}</span>
            </div>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Edit',
            selector: row => <EditMispunch details={row} mispunch={true} update={GetMispunch}/>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
    ];
    useEffect(() => {
        setTimeout(() => {
            setRotate(false)
        }, 6000);
    })
    var comment = ""
    const current = (data: any) => {
        setModal(true)
        comment = data
        setComments(data);
    }
    return (
        <>
            {modal && <CommentPop modaldata={modal} modal={setModal} data={comments} />}
            <div className="dashboard_main px-4 mt-3">
                <nav>
                    <div className=" d-flex align-items-center">
                        <div className="col-12 d-flex align-items-center p-0 gap-2">
                            <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
                                <span className='base-text-color base-font-700 fs-4'><Note size="35" variant="Bulk" /></span>
                                <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                                    Employees Mispunches</h4>
                            </div>
                            <div className="px-3 py-2 col-5 bg-white rounded-3 shadow">
                                <div className='d-flex align-items-center bg-transparent border-base-bottom-2'>
                                    <SearchNormal size="25" className='text-base-color' />
                                    <select className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                                        style={{ fontSize: "1rem" }}
                                        {...register("firstname", { onChange: (e) => { submit(e.target.value) } })}
                                    >
                                        <option value="" className='attoptions'>Employee Name...</option>
                                        {allUsers.reverse().map((user: any, i: any) => {
                                            return <option className='attoptions' value={user.firstname} key={i}>{user.employee_id} - {user.firstname} {user.lastname}  </option>;
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className=" py-2 col-1 bg-white rounded-3 shadow d-flex justify-content-center align-items-center" title='Refresh'>
                                <div className={rotate ? "rotateBox" : ""} style={{ cursor: "pointer" }} onClick={() => { GetMispunch() }}>
                                    <Refresh size="35" className='text-base-color' />
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <section className='mt-5'>
                    <div className="bg-white pt-3 pb-3 px-4 d-flex gap-5 flex-column rounded-5 shadow">
                        {mispunches ? <Table columns={columns} data={posts?.length > 0 ? posts : mispunches} />
                            : <EmptyTable tabletext="Employees Mispunch" />}
                    </div>
                </section>
            </div>
        </>

    )
}

export default Mispunchdetails