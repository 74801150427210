import 'font-awesome/css/font-awesome.min.css';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import axios from 'axios';
import './registration.css'
import signIn from '../../image/favicon-insta-courier-png.png'
import 'font-awesome/css/font-awesome.min.css';
import { useDispatch } from 'react-redux';
import { getBreakLogData, getFullAttData, getLeaves, getLoggedUserAction, loginAction } from '../../Redux/actions';
import { environment } from '../../env';
import moment from 'moment';
import { getAllEmployees, getRemarks, getMispunchAction } from './../../Redux/actions/index';
import { Eye, EyeSlash, Key, SmsNotification } from 'iconsax-react';

const UserLogin = (props: any) => {

    const [errormessage, setErrormessage] = useState("");
    const [btndis, setBtndis] = useState(false)
    const [loading, setLoading] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);
    const dispatch = useDispatch()
    var today = new Date();
    const month = moment().format('MM');
    const year = moment().format('YYYY');
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const submitform = async (logindata: object) => {
        console.log("Submitting")
        setBtndis(true)
        setLoading(true)
        try {
            const res = await axios.post(`${environment.baseUrl}login/`, logindata);
            if (res.data.status !== 'error') {
                if (res.data.is_admin == "True") {
                    setErrormessage("");
                    setLoading(false);
                    await getAllUsers(res.data.employee_id);
                    await GetMispunch();
                    await AllEmployeeAttendance();
                    await AllLeaves();
                    await getbreaklog()
                    localStorage.setItem('employee_id', res.data.employee_id);
                    localStorage.setItem('userid', res.data.userid);
                    dispatch(loginAction(true))
                    props.login()
                    setBtndis(false)
                } else {
                    setErrormessage("* Only Admins can Login,Please login using ADMIN email");
                    setBtndis(false)
                    setLoading(false)
                }
            }
            else {
                setErrormessage("* invalid credentials");
                setBtndis(false)
                setLoading(false)
            }
        } catch (err) {
            setBtndis(false)
            console.log("err");
        }
    };
    const getbreaklog = async () => {
        await axios
          .get(`${environment.baseUrl}admin-attendance`)
          .then((res) => {
            console.log('data from uselogin', res.data);
            dispatch(getBreakLogData(res.data));
          })
          .catch((res) => console.log("error", res));
      };
    const getAllUsers = async (empID: string) => {
        try {
            const res = await axios.get(`${environment.baseUrl}all-users-details/`)
            if (res.data) {
                dispatch(getAllEmployees(res.data.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id)))
                const logged = res.data.filter((user: { employee_id: string; }) => user.employee_id == empID)
                dispatch(getLoggedUserAction(logged))
            }
        }
        catch (err) {
            console.log("err");
        }
    }

    const AllEmployeeAttendance = async () => {
        var URL = ""
        console.log("moment(today).format(DD)", moment(today).format("DD"))
        if (moment(today).format("DD") == "01") {
            var monthe = Number(month) - 1
            URL = `${environment.baseUrl}attendance-filter?employee_id=&date_after=${year}-${monthe}-01&date_before=${year}-${month}-${moment(lastDayOfMonth).format('DD')}`
        } else {
            URL = `${environment.baseUrl}attendance-filter?employee_id=&date_after=${year}-${month}-01&date_before=${year}-${month}-${moment(lastDayOfMonth).format('DD')}`
        }
        const result = await axios.get(URL)
        dispatch(getFullAttData(result.data))
        const unique = Array.from(new Set(result.data.map((item: any) => item.remark)));
        dispatch(getRemarks(unique))
    }

    const AllLeaves = async () => {
        axios.get(`${environment.baseUrl}get-all-leaves`)
            .then(res => {
                dispatch(getLeaves(res.data))
            })
            .catch(res => console.log("error"))
    }
    const GetMispunch = async () => {
        axios.get(`${environment.baseUrl}get-mispunch/`)
            .then(res => {
                dispatch(getMispunchAction(res.data))
            })
            .catch(res => console.log("error"))
    }

    return (
        <>
            <div className="signin-page">
                <div className="card border-0 login-card shadow-lg p-3 rounded-5">
                    <div className="row m-0 p-0 w-100 h-100">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-7 px-5 pb-md-5">
                            <div className="d-flex justify-content-center flex-column py-4">
                                <h1 className="base-font-pop fw-bold pt-2  text-base-color" style={{ fontSize: "3.2rem" }}>SIGN IN</h1>
                            </div>
                            <div className="pt-4">
                                <form className="d-flex flex-column px-5" onSubmit={handleSubmit(submitform)}>
                                    <div className="d-flex  flex-column align-items-start w-100 margin-bottom-0">
                                        <label htmlFor="username" className="base-font-600 fs-5 text-start text-base-color">
                                            Username
                                        </label>
                                        <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                            <SmsNotification size="32" className='text-base-color' />
                                            <input
                                                className="border-0 rounded-pill  base-font-500 py-2 px-2 fs-4 w-100"
                                                type="email"
                                                placeholder="Email"
                                                {...register("email_id", { required: true })}
                                            />
                                        </div>

                                        {errors.email_id && <span className='fs-6 pt-2 text-danger base-font-600'>Email Id is required *</span>}
                                    </div>
                                    <div className="d-flex  flex-column align-items-start w-100 mb-1">
                                        <label htmlFor="examplePassword" className=" base-font-600 fs-5 text-start text-base-color">
                                            Password *
                                        </label>
                                        <div className="border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2">
                                            <Key size="32" className='text-base-color' />
                                            <input
                                                className="border-0 rounded-pill base-font-500 py-2 px-2 fs-4 w-100"
                                                type={passwordShown ? "text" : "password"}
                                                {...register("password", { required: true })}
                                                placeholder="Password"
                                            />
                                            {passwordShown ?
                                                <Eye size="32" onClick={() => setPasswordShown(!passwordShown)} className='text-base-color' />
                                                :
                                                <EyeSlash size="32" onClick={() => setPasswordShown(!passwordShown)} className='text-base-color' />
                                            }

                                        </div>
                                        {errors.password && <span className='fs-6 pt-2 text-danger base-font-600'>Password is required *</span>}
                                        <p className='fs-5 pt-2 text-danger'>{errormessage}</p>
                                    </div>
                                    <div className="">

                                        {loading ? <button className="border-0 rounded-pill  base-font-pop py-2 px-2 fs-4 w-100 bg-base-color text-white d-flex gap-2 justify-content-center align-items-center" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Loading . . .
                                        </button> : <button className='border-0 rounded-pill  base-font-pop py-2 px-2 fs-4 w-100 bg-base-color text-white' disabled={btndis} type="submit">
                                            Submit</button>}
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                        <Link className='d-flex justify-content-center base-font-700 mt-3 blockquote' to='/forgot-password'>Reset Password ?</Link>
                                        <Link className='d-flex justify-content-center base-font-700 mt-3 text-decoration-underline blockquote' to='registration'><span className="">Register Here</span></Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col col-md-6 col-lg-6 col-xl-5 p-0 d-flex justify-content-center align-items-center border-left-side">
                            <div className="img-card p-2">
                                <img src={signIn} alt="" className="img-fit" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default UserLogin
