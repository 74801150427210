import React, { useEffect, useState } from "react";

import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import moment from "moment";
import AddJoining from "./addjoiningletter";
import { environment } from "../../env";
import DataTable, { TableColumn } from "react-data-table-component";
import Table from "../../Table/Table";
import {
  ClipboardText,
  SearchNormal,
} from "iconsax-react";
import EmptyTable from "../errorComponents/EmptyTable";

interface DataRow {
  employee_id: any;
  created_date: string;
  file: string;
}
const JoiningLetter = () => {
  const [joiningletter, setJoiningLetter] = useState<any>();
  const [update, setUpdate] = useState<any>(false);

  const GetAllJoiningLetter = async () => {
    await axios
      .get(`${environment.baseUrl}all-user-joining-letter`)
      .then((res) => {
        setJoiningLetter(res.data);
      })
      .catch((res) => console.log("Upload doc error"));
  };

  const PDFViewer = (file: any) => {
    window.open(`${environment.imageUrl}${file}`);
  };

  useEffect(() => {
    GetAllJoiningLetter();
  }, [update]);

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Employee ID",
      selector: (row) => row?.employee_id,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Created on",
      selector: (row) => moment(row?.created_date.split("T")[0]).format("DD-MM-YYYY"),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "View File",
      selector: (row) => row?.file,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  const data = [
    {
      id: 1,
      payslip_month: "Example",
    },
  ];

  return (
    <>
      <div className="dashboard_main px-4 mt-3">
        <nav>
          <div className=" d-flex align-items-center">
            <div className="col-12 d-flex align-items-center p-0">
              <div className="dashboard-heading col-4  px-0 d-flex align-items-center">
                <span className="base-text-color base-font-700 fs-4">
                  <ClipboardText size="35" variant="Bulk" />
                </span>
                <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                  Joining Letters
                </h4>
              </div>
              <div className="px-3 py-2 col-6  bg-white rounded-3 shadow">
                <div className="d-flex align-items-center bg-transparent border-base-bottom-2">
                  <SearchNormal size="25" className="text-base-color" />
                  <select
                    className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                    style={{ fontSize: "1rem" }}
                  >
                    <option value="" className="attoptions">
                      Employee Name...
                    </option>
                  </select>
                </div>
              </div>
              <AddJoining update={setUpdate} upd={update} />
            </div>
          </div>
        </nav>
        <section className="mt-5">
          <div className="bg-white pt-3 pb-3 px-4 d-flex gap-5 flex-column rounded-5 shadow">
            {joiningletter ? (
              <Table columns={columns} data={joiningletter} />
            ) : (
              <EmptyTable tabletext="Joining Letters" />
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default JoiningLetter;
